import { UploadedFileDto } from "@/api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Typography from "@/elements/Typography";

export default function FileDownload({
  file,
  downloadUrl,
}: {
  file?: UploadedFileDto | null;
  downloadUrl?: string | null;
}) {
  if (!file) {
    return (
      <Typography>
        <em>Nessun file caricato</em>
      </Typography>
    );
  }

  return (
    <a
      href={downloadUrl ?? undefined}
      target="_blank"
      rel="noreferrer"
      style={{ display: "flex", alignItems: "center" }}
    >
      <FileDownloadIcon sx={{ mr: 1 }} />
      {file.fileName}
    </a>
  );
}
