/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { AlloggiamentoFiltroDto } from ".././models";

export const getAlloggiamentoFiltro = (
  token: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AlloggiamentoFiltroDto>> => {
  return axios.get(`/api/alloggiamentoFiltro/qrcode/${token}`, options);
};

export const getGetAlloggiamentoFiltroQueryKey = (token: string) =>
  [`/api/alloggiamentoFiltro/qrcode/${token}`] as const;

export const getGetAlloggiamentoFiltroQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlloggiamentoFiltro>>,
  TError = AxiosError<unknown>
>(
  token: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAlloggiamentoFiltro>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getAlloggiamentoFiltro>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAlloggiamentoFiltroQueryKey(token);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAlloggiamentoFiltro>>
  > = ({ signal }) =>
    getAlloggiamentoFiltro(token, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!token, ...queryOptions };
};

export type GetAlloggiamentoFiltroQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlloggiamentoFiltro>>
>;
export type GetAlloggiamentoFiltroQueryError = AxiosError<unknown>;

export const useGetAlloggiamentoFiltro = <
  TData = Awaited<ReturnType<typeof getAlloggiamentoFiltro>>,
  TError = AxiosError<unknown>
>(
  token: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAlloggiamentoFiltro>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAlloggiamentoFiltroQueryOptions(token, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useGetAlloggiamentoFiltro = () =>
  //@ts-ignore
  useGetAlloggiamentoFiltro(...([] as any[]));
