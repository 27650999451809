import { AnalisiDto, AziendaDto, CentraleDto, StabilimentoDto } from "@/api";
import {
  getDownloadFilePdfAnalisiQueryKey,
  getGetAziendaQueryKey,
  useGetAzienda,
} from "@/api/aziende/aziende";
import { useAuth } from "@/context/useAuth";
import Button from "@/elements/Button";
import Table from "@/elements/Table";
import { Box, Icon, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AllCentralePicker from "../AllCentralePicker";
import FileDownload from "../FileField/FileDownload";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import StabilimentoDialog from "../StabilimentoDialog";
import { useQueryClient } from "@tanstack/react-query";

type Filters = {
  centrale: CentraleDto | null;
};

const extra: any = { id: -1, nome: "ANALISI CAMPIONE" };

export function AziendaDetails({ azienda }: { azienda: AziendaDto }) {
  const { hasPermission, user } = useAuth();
  const [filters, setFilters] = useState<Filters>({
    centrale: null,
  });
  const [filterSenzaCentrali, setFilterSenzaCentrali] = useState(true);
  const [createStabilimentoDialogOptions, setCreateStabilimentoDialogOptions] =
    useState<{
      open: boolean;
      data: StabilimentoDto | null;
    }>({
      open: false,
      data: null,
    });

  const columnAnalisiHelper = createColumnHelper<AnalisiDto>();
  const columnStabilimentiHelper = createColumnHelper<StabilimentoDto>();

  const queryClient = useQueryClient();
  const onCreateStabilimentoClose = (stabilimento: StabilimentoDto | null) => {
    queryClient.invalidateQueries(getGetAziendaQueryKey(azienda.id));
    setCreateStabilimentoDialogOptions({ open: false, data: null });
  };

  const columnsAnalisi = useMemo(() => {
    const columnsAnalisi = [
      columnAnalisiHelper.accessor("centrale", {
        header: "Centrale",
        cell: ({ row }) => {
          return <p>{row.original.centrale?.nome ?? ""}</p>;
        },
      }),
      columnAnalisiHelper.accessor("data", {
        header: "Data",
        cell: ({ row }) => moment(row.original.data).format("L"),
      }),
      columnAnalisiHelper.accessor("esito", {
        header: "Esito",
        cell: ({ row }) => row.original.esito,
      }),
      columnAnalisiHelper.accessor("validaFinoA", {
        header: "Valida Fino A",
        cell: ({ row }) => moment(row.original.validaFinoA).format("L"),
      }),
      columnAnalisiHelper.accessor("filePdf", {
        header: "PDF",
        cell: ({ row }) => (
          <FileDownload
            file={row.original.filePdf}
            downloadUrl={
              getDownloadFilePdfAnalisiQueryKey(azienda.id, row.original.id)[0]
            }
          />
        ),
      }),
    ].filter((x) => x);
    return columnsAnalisi as Exclude<
      (typeof columnsAnalisi)[number],
      boolean
    >[];
  }, [hasPermission]);

  const columnsStabilimenti = useMemo(() => {
    const columnsStabilimenti = [
      columnStabilimentiHelper.accessor("nome", {
        header: "Stabilimento",
        cell: ({ row }) => {
          return <p>{row.original.nome ?? ""}</p>;
        },
      }),
      columnStabilimentiHelper.accessor("nome", {
        header: "Referente Commerciale",
        cell: ({ row }) => {
          return (
            <p>
              {row.original.referenteCommerciale?.name ?? ""}{" "}
              {row.original.referenteCommerciale?.lastName ?? ""}
            </p>
          );
        },
      }),
      columnStabilimentiHelper.display({
        header: "Azioni",
        cell: ({ row }) => {
          return (
            <Box display="flex" gap={1}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<Icon>edit</Icon>}
                onClick={() => {
                  setCreateStabilimentoDialogOptions({
                    open: true,
                    data: { ...row.original },
                  });
                }}
              >
                Modifica
              </Button>
            </Box>
          );
        },
      }),
    ].filter((x) => x);
    return columnsStabilimenti as Exclude<
      (typeof columnsStabilimenti)[number],
      boolean
    >[];
  }, [hasPermission]);

  const changeFilter =
    (key: keyof typeof filters, resetKeys?: (keyof typeof filters)[]) =>
    (event: any, value: any | undefined) => {
      if (value?.isExtra && value.id === -1) {
        setFilterSenzaCentrali(true);
      } else {
        setFilterSenzaCentrali(false);
        setFilters((f) => {
          const newFilters = {
            ...f,
            [key]: value || null,
          };
          resetKeys?.forEach((k) => {
            newFilters[k] = null;
          });
          return newFilters;
        });
      }
    };

  const analisi = useMemo(() => {
    return (azienda.analisi || []).filter((a) => {
      return matchFilter(a, filters, filterSenzaCentrali);
    });
  }, [azienda.analisi, filters, filterSenzaCentrali]);

  const stabilimenti = useMemo(() => {
    return azienda.stabilimenti || [];
  }, [azienda.stabilimenti]);

  return (
    <>
      <Box p={2} display="flex" flexDirection="column" gap={1}>
        <Typography variant="h4" mr={2}>
          {azienda.nome}
        </Typography>
        {user?.isEsterno === false && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" color="text" fontWeight="medium">
                Stabilimenti
              </Typography>
            </Box>
            <Table
              columns={columnsStabilimenti}
              data={stabilimenti}
              initialSort={[{ id: "name", desc: true }]}
              noRowsText="Nessuno stabilimento"
              mt={1}
            />
          </>
        )}

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color="text" fontWeight="medium">
            Storico analisi
          </Typography>
          <Box display="flex" alignItems="center" gap={4}>
            <AllCentralePicker
              value={filterSenzaCentrali ? extra : filters.centrale}
              onChange={changeFilter("centrale")}
              placeholder="Centrale"
              extra={extra}
              aziendaId={azienda.id}
            />
          </Box>
        </Box>
        <Table
          columns={columnsAnalisi}
          data={analisi}
          initialSort={[{ id: "data", desc: true }]}
          noRowsText="Nessuna analisi"
          mt={1}
        />
      </Box>
      <StabilimentoDialog
        create={false}
        open={createStabilimentoDialogOptions.open}
        onClose={onCreateStabilimentoClose}
        initialData={{
          ...createStabilimentoDialogOptions.data,
        }}
      />
    </>
  );
}

function AziendaDetailsPage() {
  const params = useParams();
  const id = parseInt(params.id || "");

  const { isLoading, error, data } = useGetAzienda(id);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  return <AziendaDetails azienda={data.data} />;
}

export default AziendaDetailsPage;

function matchFilter(
  a: AnalisiDto,
  filters: Filters,
  filterSenzaCentrali: boolean
): unknown {
  if (filterSenzaCentrali) {
    if (a.centrale) {
      return false;
    } else {
      return true;
    }
  }
  if (filters.centrale) {
    if (a.centrale?.id !== filters.centrale.id) {
      return false;
    }
  }
  return true;
}
