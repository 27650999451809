/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { FiltrazioneDto } from ".././models";

export const getFiltrazioni = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<FiltrazioneDto[]>> => {
  return axios.get(`/api/filtrazioni`, options);
};

export const getGetFiltrazioniQueryKey = () => [`/api/filtrazioni`] as const;

export const getGetFiltrazioniQueryOptions = <
  TData = Awaited<ReturnType<typeof getFiltrazioni>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFiltrazioni>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getFiltrazioni>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFiltrazioniQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFiltrazioni>>> = ({
    signal,
  }) => getFiltrazioni({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetFiltrazioniQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFiltrazioni>>
>;
export type GetFiltrazioniQueryError = AxiosError<unknown>;

export const useGetFiltrazioni = <
  TData = Awaited<ReturnType<typeof getFiltrazioni>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFiltrazioni>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFiltrazioniQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useGetFiltrazioni = () =>
  //@ts-ignore
  useGetFiltrazioni(...([] as any[]));
