import Card from "@mui/material/Card";
import Typography from "@/elements/Typography";
import { ProfiloUpdateRequestDto, UserDto, useUpdateProfilo } from "@/api";
import { SubmitHandler, useForm } from "react-hook-form";
import Box from "@/elements/Box";
import Input from "@/elements/Input";
import { FormHelperText } from "@mui/material";
import { useAuth } from "@/context/useAuth";
import { toast } from "react-toastify";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import Button from "@/elements/Button";

export default function DatiProfiloCard() {
  const { user, onUserUpdated } = useAuth();
  const { isLoading, mutate: doUpdateProfilo } = useUpdateProfilo();

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    reset,
  } = useForm<ProfiloUpdateRequestDto>({
    defaultValues: userDtoToDefaultValues(user),
  });

  const onSubmit: SubmitHandler<ProfiloUpdateRequestDto> = async (data) => {
    doUpdateProfilo(
      { data },
      {
        onSuccess: (response) => {
          toast.success("Profilo modificata con successo!");
          onUserUpdated(response.data);
          reset(userDtoToDefaultValues(response.data));
        },
        onError: addServerErrorsFactory(setError),
      }
    );
  };

  return (
    <Card sx={{ maxWidth: 400, minWidth: 300 }}>
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box mb={1} ml={0.5}>
            <Typography variant="body2" color="text" fontWeight="medium">
              Dati profilo
            </Typography>
          </Box>

          <Box mb={2}>
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Indirizzo Email
              </Typography>
            </Box>
            <Input
              type="text"
              placeholder="Indirizzo Email"
              {...register("email")}
              disabled={isLoading}
              error={!!errors.email?.message}
            />
            {errors.email?.message ? (
              <FormHelperText error>{errors.email?.message}</FormHelperText>
            ) : null}
          </Box>

          <Box mb={2}>
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Nome
              </Typography>
            </Box>
            <Input
              type="text"
              placeholder="Nome"
              {...register("name")}
              disabled={isLoading}
              error={!!errors.name?.message}
            />
            {errors.name?.message ? (
              <FormHelperText error>{errors.name?.message}</FormHelperText>
            ) : null}
          </Box>

          <Box mb={2}>
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Cognome
              </Typography>
            </Box>
            <Input
              type="text"
              placeholder="Cognome"
              {...register("lastName")}
              disabled={isLoading}
              error={!!errors.lastName?.message}
            />
            {errors.lastName?.message ? (
              <FormHelperText error>{errors.lastName?.message}</FormHelperText>
            ) : null}
          </Box>

          <Box mt={4} mb={1}>
            <Button
              color="secondary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || isLoading}
            >
              Salva
            </Button>
            {errors.root?.message ? (
              <FormHelperText error>{errors.root?.message}</FormHelperText>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

function userDtoToDefaultValues(user?: UserDto | null) {
  return {
    email: user?.email || "",
    name: user?.name || "",
    lastName: user?.lastName || "",
  };
}
