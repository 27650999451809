import React, { FocusEventHandler, forwardRef, useMemo } from "react";
import { useGetDescrizioniBonifica } from "@/api";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

const DescrizioneBonificaPicker = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      // canCreateDescrizioneBonifica = false,
      placeholder,
    }: {
      value?: string | null;
      onChange?: (event: any, value: string | null) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      // canCreateDescrizioneBonifica?: boolean;
      placeholder?: string;
    },
    ref
  ) => {
    // const [
    //   createDescrizioneBonificaDialogOptions,
    //   setCreateDescrizioneBonificaDialogOptions,
    // ] = useState<{ open: boolean; nome?: string; marca?: string }>({
    //   open: false,
    // });
    //
    // const onCreateDescrizioneBonificaClose = (
    //   modelloFiltro: DescrizioneBonificaDto | null
    // ) => {
    //   onChange?.(null, modelloFiltro);
    //   setCreateDescrizioneBonificaDialogOptions({ open: false });
    // };

    // function onNewOptionSelected(text: string) {
    //   const [marca, ...nome] = text.split(" ");
    //   setCreateDescrizioneBonificaDialogOptions({
    //     open: true,
    //     marca,
    //     nome: nome.join(" "),
    //   });
    // }

    const { data } = useGetDescrizioniBonifica();

    const options: string[] = useMemo(() => {
      let options: string[] = [];
      if (value) {
        options.push(value);
      }

      if (data?.data) {
        options = [...options, ...data.data.map((d) => d.descrizione)];
      }
      return options;
    }, [value, data]);

    return (
      <>
        <Autocomplete
          ref={ref}
          // onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          options={options}
          // canCreateNewOption={canCreateDescrizioneBonifica}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          renderInput={(props) => <TextField {...props} />}
        />
        {/*{canCreateDescrizioneBonifica && (*/}
        {/*  <DescrizioneBonificaDialog*/}
        {/*    open={createDescrizioneBonificaDialogOptions.open}*/}
        {/*    onClose={onCreateDescrizioneBonificaClose}*/}
        {/*    modelloFiltro={{*/}
        {/*      nome: createDescrizioneBonificaDialogOptions.nome,*/}
        {/*      marca: createDescrizioneBonificaDialogOptions.marca,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
      </>
    );
  }
);

export default DescrizioneBonificaPicker;
