// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _permissionsDescriptionMap = {
  NotSet: "NotSet",
  Home: "Visualizza home",
  DispositiviRead: "Visualizza i propri dispositivi",
  DispositiviReadAll: "Visualizza tutti i dispositivi",
  DispositiviUpdateTrasferimenti: "Modifica i trasferimenti di un dispositivo",
  DispositivoCreate: "Crea un nuovo dispositivo",
  DispositivoDelete: "Elimina un dispositivo",
  DispositiviReadManutenzioni: "Leggi le manutenzioni di un dispositivo",
  DispositiviUpdateManutenzioni: "Modifica le manutenzioni di un dispositivo",
  DispositivoRegistriRead: "Visualizza i registri di un dispositivo",
  DispositivoRegistriWrite: "Modifica i registri di un dispositivo",
  DispositiviDatiStoriciRead:
    "Visualizza i dati storici dei propri dispositivi",
  DispositiviDatiStoriciReadAll:
    "Visualizza i dati storici di tutti i dispositivi",
  DispositiviAlertValuesEdit: "Modifica dei valori min/max per gli alert",
  DispositiviAlertInternalUserOptionsEdit:
    "Modifica delle impostazioni per gli utenti interni (es: Invio email al tecnico Gatti)",
  AziendeReadAll: "Visualizza i dati di tutte le aziende",
  AziendeRead: "Visualizza i dati delle proprie aziende",
  AziendaRead: "Visualizza i dati della proprie azienda",
  AziendaCreate: "Crea una azienda",
  AziendaEdit: "Modifica una azienda",
  AziendaDelete: "Elimina una azienda",
  StabilimentiReadAll: "Visualizza i dati di tutti gli stabilimenti",
  StabilimentoCreate: "Crea uno stabilimento",
  StabilimentoUpdate: "Aggiorna uno stabilimento",
  RepartiReadAll: "Visualizza i dati di tutti i reparti",
  RepartoCreate: "Crea un reparto",
  CentraliRead: "Visualizza le proprie centrali",
  CentraliReadAll: "Visualizza i dati di tutte le centrali",
  CentraleCreate: "Crea una centrale",
  CentraleUpdate: "Modifica una centrale",
  CentraleUpdateAlloggiamentiFiltro:
    "Modifica gli alloggimenti filtro di una centrale",
  CentraleUpdateStoricoFiltri: "Modifica lo storico filtri di una centrale",
  CentraleUpdateStoricoOlio: "Modifica lo storico olio di una centrale",
  CentraleUpdateReminders: "Modifica i reminder di una centrale",
  CentraleUpdateBonifica: "Modifica lo storico delle bonifiche di una centrale",
  CentraleUpdateAnalisi: "Modifica le storico delle analisi di una centrale",
  CentraleUpdateFiltrazioni:
    "Modifica lo storico delle filtrazioni di una centrale",
  CentraleUpdateImmagini: "Modifica le immagini delle filtrazioni",
  CentraleDelete: "Elimina una centrale",
  TrasferimentiChange: "Modificare i trasferimenti di un dispositivo",
  UsersInterniRead: "Visualizzare gli utenti (interni)",
  UsersEsterniRead: "Visualizzare i clienti (account aziendali)",
  UsersInterniChange: "Modificare gli utenti (interni)",
  UsersEsterniChange: "Modificare i clienti (account aziendali)",
  ModelliFiltroChange: "Modificare i modelli di filtro",
  ModelliOlioChange: "Modificare i modelli di olio",
  DescrizioniBonificaChange: "Modificare le descrizioni di bonifica",
  DescrizioniManutenzioneChange: "Modificare le descrizioni di manutenzione",
  FileUpload: "Caricare file",
  AnalisiListaReadAll: "Visualizza tutte le analisi",
  AnalisiListaRead: "Visualizza le analisi di questo utente",
  FiltrazioniListaReadAll: "Visualizza tutte le filtrazioni",
  FiltrazioniListaRead: "Visualizza le filtrazioni di questo utente",
  BonificheListaReadAll: "Visualizza tutte le Bonifiche",
  BonificheListaRead: "Visualizza le bonifiche di questo utente",
  CalendarioRead:
    "Visualizza il calendario con le analisi, filtraizoni e bonifiche",
  AccessAll: "Accesso a tutto",
} as const;
