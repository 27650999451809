/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { AlertDto, ChangeDispositivoAlertDto } from ".././models";

export const getDispostivoAlerts = (
  dispositivoId: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AlertDto[]>> => {
  return axios.get(`/api/dispositivi/${dispositivoId}/alerts`, options);
};

export const getGetDispostivoAlertsQueryKey = (dispositivoId: number) =>
  [`/api/dispositivi/${dispositivoId}/alerts`] as const;

export const getGetDispostivoAlertsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDispostivoAlerts>>,
  TError = AxiosError<unknown>
>(
  dispositivoId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispostivoAlerts>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDispostivoAlerts>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDispostivoAlertsQueryKey(dispositivoId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDispostivoAlerts>>
  > = ({ signal }) =>
    getDispostivoAlerts(dispositivoId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!dispositivoId, ...queryOptions };
};

export type GetDispostivoAlertsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDispostivoAlerts>>
>;
export type GetDispostivoAlertsQueryError = AxiosError<unknown>;

export const useGetDispostivoAlerts = <
  TData = Awaited<ReturnType<typeof getDispostivoAlerts>>,
  TError = AxiosError<unknown>
>(
  dispositivoId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispostivoAlerts>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDispostivoAlertsQueryOptions(
    dispositivoId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const changeDispositivoAlerts = (
  dispositivoId: number,
  changeDispositivoAlertDto: ChangeDispositivoAlertDto[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/dispositivi/${dispositivoId}/alerts`,
    changeDispositivoAlertDto,
    options
  );
};

export const getChangeDispositivoAlertsMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeDispositivoAlerts>>,
    TError,
    { dispositivoId: number; data: ChangeDispositivoAlertDto[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeDispositivoAlerts>>,
  TError,
  { dispositivoId: number; data: ChangeDispositivoAlertDto[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeDispositivoAlerts>>,
    { dispositivoId: number; data: ChangeDispositivoAlertDto[] }
  > = (props) => {
    const { dispositivoId, data } = props ?? {};

    return changeDispositivoAlerts(dispositivoId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeDispositivoAlertsMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeDispositivoAlerts>>
>;
export type ChangeDispositivoAlertsMutationBody = ChangeDispositivoAlertDto[];
export type ChangeDispositivoAlertsMutationError = AxiosError<unknown>;

export const useChangeDispositivoAlerts = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeDispositivoAlerts>>,
    TError,
    { dispositivoId: number; data: ChangeDispositivoAlertDto[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getChangeDispositivoAlertsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetDispostivoAlerts = () =>
  //@ts-ignore
  useGetDispostivoAlerts(...([] as any[]));

export const __$$useChangeDispositivoAlerts = () =>
  //@ts-ignore
  useChangeDispositivoAlerts(...([] as any[]));
