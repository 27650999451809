import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import {
  UserInternoDto,
  getGetClientiQueryKey,
  getGetUsersQueryKey,
  useDeleteUser,
  useGetUsers,
} from "@/api";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@/elements/Table";
import { useAuth } from "@/context/useAuth";
import Button from "@/elements/Button";
import { Chip, Icon } from "@mui/material";
import UtenteDialog from "../UtenteDialog";
import { useCallback, useMemo, useState } from "react";
import { Role, getRoleColor } from "@/utils/rolesAndPermissions";
import { useConfirmationDialog } from "@/utils/useConfirmationDialog";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const columnHelper = createColumnHelper<UserInternoDto>();

function UtentiPage() {
  const { hasPermission } = useAuth();
  const { isLoading, error, data } = useGetUsers();

  const [userDialogState, setUserDialogState] = useState({
    open: false,
    user: null as UserInternoDto | null,
  });

  const { askConfirmation, confirmationDialog } = useConfirmationDialog();

  const { mutateAsync } = useDeleteUser({
    mutation: {
      onSuccess: () => {
        toast.success("Utente eliminato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const deleteUser = useCallback(
    async (userId: number) => {
      try {
        await mutateAsync({ id: userId });
        queryClient.invalidateQueries({ queryKey: getGetUsersQueryKey() });
        queryClient.invalidateQueries({ queryKey: getGetClientiQueryKey() });
      } catch (err) {}
    },
    [mutateAsync, queryClient]
  );

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor("name", {
        header: "Nome",
        size: undefined,
      }),
      columnHelper.accessor("lastName", {
        header: "Cognome",
        size: undefined,
      }),
      columnHelper.accessor("email", {
        header: "Email",
        size: undefined,
      }),
      columnHelper.accessor("roles", {
        header: "Ruolo",
        size: undefined,
        cell: ({ row }) => {
          const roles = row.original.roles || [];
          return (
            <Box display="flex" gap={0.5}>
              {roles.map((role) => {
                const [bg, fg] = getRoleColor(role as Role);
                return (
                  <Chip
                    key={role}
                    label={role}
                    size="small"
                    sx={{
                      backgroundColor: bg,
                      color: fg,
                    }}
                  />
                );
              })}
            </Box>
          );
        },
      }),
      hasPermission("UsersInterniChange") &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 250,
          maxSize: 250,
          cell: ({ row }) => {
            return (
              <Box display="flex" gap={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<Icon>edit</Icon>}
                  onClick={() => {
                    setUserDialogState({
                      open: true,
                      user: row.original,
                    });
                  }}
                >
                  Modifica
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  startIcon={<Icon>delete_outlined</Icon>}
                  disabled={
                    row.original.id === 1 // main user: non eliminabile
                  }
                  onClick={() => {
                    askConfirmation({
                      title: "Elimina utente",
                      message: `Sei sicuro di voler eliminare l'utente ${row.original.name} ${row.original.lastName} (${row.original.email})?`,
                      okColor: "error",
                      onConfirm: () => {
                        return deleteUser(row.original.id!);
                      },
                    });
                  }}
                >
                  Elimina
                </Button>
              </Box>
            );
          },
        }),
    ].filter((x) => x);
    return columns as unknown as Exclude<(typeof columns)[number], boolean>[];
  }, [setUserDialogState, askConfirmation, deleteUser, hasPermission]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  const utenti = data.data || [];

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Utenti
        </Typography>
        <Box display="flex" gap={1} pt={1} flexWrap="wrap">
          {hasPermission("UsersInterniChange") && (
            <Button
              size="small"
              color="light"
              onClick={() => {
                setUserDialogState({
                  open: true,
                  user: null,
                });
              }}
            >
              Aggiungi utente
            </Button>
          )}
        </Box>
      </Box>

      <Table
        columns={columns}
        data={utenti}
        sortable={true}
        initialSort={[{ id: "email", desc: false }]}
      />

      <UtenteDialog
        open={userDialogState.open}
        user={userDialogState.user}
        onClose={() => {
          setUserDialogState((x) => ({
            ...x,
            open: false,
          }));
        }}
      />
      {confirmationDialog}
    </Box>
  );
}

export default UtentiPage;
