import { forwardRef } from "react";

// Soft UI Dashboard React components
import SoftTypography from "@/elements/Typography";

// Custom styles for SoftProgress
import SoftProgressRoot from "@/elements/Progress/SoftProgressRoot";

import { LinearProgressProps as MuiLinearProgressProps } from "@mui/material";

interface ProgressProps
  extends Omit<MuiLinearProgressProps, "variant" | "color" | "value"> {
  variant?: "contained" | "gradient";
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark";
  value: number;
  label?: string | null;
}

const Progress = forwardRef<any, ProgressProps>(
  (
    { variant = "contained", color = "info", value, label = null, ...rest },
    ref
  ) => (
    <>
      {label && (
        <SoftTypography variant="button" fontWeight="medium" color="text">
          {label}
        </SoftTypography>
      )}
      <SoftProgressRoot
        {...rest}
        ref={ref}
        variant="determinate"
        value={value}
        //@ts-ignore
        ownerState={{ color, value, variant }}
      />
    </>
  )
);

export default Progress;
