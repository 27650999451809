import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import {
  AlloggiamentoFiltroDto,
  AnalisiDto,
  BonificaDto,
  CentraleDto,
  getGetCentraliQueryKey,
  useDeleteCentrale,
} from "@/api";
import { Link } from "react-router-dom";
import CentraleIcon from "@/assets/centrale-icon2.png";
import FiltroIcon from "@/assets/filtro.png";
import AnalisiIcon from "@/assets/analisi.png";
import BonificaIcon from "@/assets/bonifica.png";
import UnknownIcon from "@/assets/unknown.png";
import OkIcon from "@/assets/ok.png";
import WarningIcon from "@/assets/warning.png";
import ErrorIcon from "@/assets/error.png";
import PosizioneCentrale from "@/components/PosizioneCentrale";
import { Icon, Tooltip } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useMemo } from "react";
import moment from "moment";
import { some } from "lodash";
import {
  ScadenzaAnalisiWarningDays,
  ScadenzaFiltroWarningDays,
} from "@/utils/scadenzeUtils";
import { useAuth } from "@/context/useAuth";

function CentraleCard({
  centrale,
  showAzienda,
}: {
  centrale: CentraleDto;
  showAzienda: boolean;
}) {
  const { hasPermission } = useAuth();
  const queryClient = useQueryClient();
  const { mutate } = useDeleteCentrale();

  function handleDelete() {
    const message = `Sei sicuro di voler eliminare la centrale "${centrale.nome}"?`;
    if (!window.confirm(message)) {
      return;
    }

    mutate(
      { id: centrale.id },
      {
        onSuccess: () => {
          toast.success("Centrale eliminata!");
          queryClient.invalidateQueries(getGetCentraliQueryKey()).then();
        },
        onError: () => {
          toast.error("Errore durante l'eliminazione della centrale");
        },
      }
    );
  }

  return (
    <Card
      sx={{
        maxWidth: 700,
        minWidth: {
          xs: "100%",
          md: 550,
        },
        flex: 1,
        flexDirection: "row",
        height: "100%",
      }}
    >
      <Box p={1}>
        <img src={CentraleIcon} width={60} alt="" />
      </Box>
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
            columnGap={2}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" color="text" fontWeight="medium">
                Centrale
              </Typography>

              <Typography variant="h5" fontWeight="bold">
                {centrale.nome}
              </Typography>

              <Box sx={{ minHeight: 18, mb: 1 }}>
                <PosizioneCentrale
                  centrale={centrale}
                  showAzienda={showAzienda}
                />
              </Box>
            </Box>
            <Box display="flex" gap={1} style={{ minWidth: 45 }}>
              <StatoAnalisiIcon ultimaAnalisi={centrale.ultimaAnalisi} />
              <StatoBonificaIcon ultimaBonifica={centrale.ultimaBonifica} />
              {/* TODO: sensore */}
              <StatoFiltriIcon
                alloggiamentiFiltro={centrale.alloggiamentiFiltro}
              />
            </Box>
          </Box>

          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="textArrow"
              color="text"
              LinkComponent={Link}
              to={`/centrali/${centrale.id}`}
              sx={{ m: 0 }}
            >
              Vedi dettaglio
            </Button>

            {hasPermission("CentraleDelete") &&
              (centrale.idElaboranet ? (
                <Tooltip title="Per poter eliminare questa centrale devi prima eliminarla in Elaboranet">
                  <div>
                    <Button
                      startIcon={<Icon>delete_outlined</Icon>}
                      variant="text"
                      color="error"
                      size="small"
                    >
                      Elimina
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  startIcon={<Icon>delete_outlined</Icon>}
                  variant="text"
                  color="error"
                  size="small"
                  onClick={handleDelete}
                >
                  Elimina
                </Button>
              ))}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default CentraleCard;

function StatoFiltriIcon({
  alloggiamentiFiltro,
}: {
  alloggiamentiFiltro?: AlloggiamentoFiltroDto[] | null;
}) {
  const [status, text] = useMemo(() => {
    const scadenze =
      alloggiamentiFiltro?.map((f) => {
        if (!f.filtroDaSostituireDopoIl) {
          return null;
        }
        return moment(f.filtroDaSostituireDopoIl).diff(moment(), "days", true);
      }) ?? [];

    if (scadenze.length <= 0) {
      return ["unknown", "Nessun filtro mappato"] as const;
    } else if (some(scadenze, (s) => s === null)) {
      return [
        "unknown",
        "Data di scadenza di uno o più filtri sconosciuta",
      ] as const;
    } else if (some(scadenze, (s) => s !== null && s <= 0)) {
      return ["urgent", "Uno o più filtri sono da sostituire"] as const;
    } else if (
      some(scadenze, (s) => s !== null && s <= ScadenzaFiltroWarningDays)
    ) {
      return [
        "warning",
        "Uno o più filtri sono da sostituire entro 30 giorni",
      ] as const;
    }

    return ["ok", "Filtri ok"] as const;
  }, [alloggiamentiFiltro]);
  return <ScadenzaIcon icon={FiltroIcon} status={status} text={text} />;
}

function StatoAnalisiIcon({
  ultimaAnalisi,
}: {
  ultimaAnalisi?: AnalisiDto | null;
}) {
  const [status, text] = useMemo(() => {
    if (!ultimaAnalisi) {
      return ["unknown", "Nessuna analisi presente"] as const;
    }

    // TODO: mostrare errore se l'analisi non è valida?

    const scadenza = ultimaAnalisi.validaFinoA;
    if (!scadenza) {
      return ["unknown", "Validità delle analisi sconosciuta"] as const;
    }

    const days = moment(scadenza).diff(moment(), "days", true);
    if (days <= 0) {
      return [
        "urgent",
        `Analisi scadute il ${moment(scadenza).format("L")}`,
      ] as const;
    } else if (days <= ScadenzaAnalisiWarningDays) {
      return [
        "warning",
        `Analisi valide fino al ${moment(scadenza).format("L")}`,
      ] as const;
    }

    return [
      "ok",
      `Analisi valide fino al ${moment(scadenza).format("L")}`,
    ] as const;
  }, [ultimaAnalisi]);
  return <ScadenzaIcon icon={AnalisiIcon} status={status} text={text} />;
}

function StatoBonificaIcon({
  ultimaBonifica,
}: {
  ultimaBonifica?: BonificaDto | null;
}) {
  const [status, text] = useMemo(() => {
    if (!ultimaBonifica) {
      return ["unknown", "Nessuna bonifica presente"] as const;
    }

    const scadenza = ultimaBonifica.scadenza;
    if (!scadenza) {
      return ["unknown", "Scadenza dell'ultima bonifica sconosciuta"] as const;
    }

    const days = moment(scadenza).diff(moment(), "days", true);
    if (days <= 0) {
      return [
        "urgent",
        `Bonifica scaduta il ${moment(scadenza).format("L")}`,
      ] as const;
    } else if (days <= ScadenzaAnalisiWarningDays) {
      return [
        "warning",
        `La bonifica scade il ${moment(scadenza).format("L")}`,
      ] as const;
    }

    return [
      "ok",
      `La bonifica scade il ${moment(scadenza).format("L")}`,
    ] as const;
  }, [ultimaBonifica]);
  return <ScadenzaIcon icon={BonificaIcon} status={status} text={text} />;
}

function ScadenzaIcon({
  icon,
  status,
  text,
}: {
  icon: string;
  status: "unknown" | "ok" | "warning" | "urgent";
  text: string;
}) {
  const statusImage =
    status === "unknown"
      ? UnknownIcon
      : status === "ok"
      ? OkIcon
      : status === "warning"
      ? WarningIcon
      : status === "urgent"
      ? ErrorIcon
      : null;

  const iconSize = 28;

  return (
    <Tooltip title={text}>
      <div style={{ position: "relative", height: iconSize }}>
        <img
          src={icon}
          width={iconSize}
          style={{ opacity: status === "ok" ? 1 : 0.6 }}
          alt={text}
        />
        {statusImage && (
          <img
            style={{ position: "absolute", bottom: 0, right: 0 }}
            src={statusImage}
            width={15}
            alt={text}
          />
        )}
      </div>
    </Tooltip>
  );
}
