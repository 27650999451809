export const temp = () => (
  <svg viewBox="0 0 285 330" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M253.645 300.29L235.737 300.51C234.476 300.526 233.443 299.515 233.428 298.262L233.359 292.496L255.825 292.215L255.894 297.981C255.909 299.242 254.898 300.275 253.645 300.29Z"
      fill="#45517B"
    />
    <path
      d="M250.929 304.398L250.867 299.354L238.522 299.508L238.584 304.551L250.929 304.398Z"
      fill="#45517B"
    />
    <path
      d="M251.837 318.256L248.311 318.301L248.061 297.753L253.105 297.692L253.341 316.721C253.348 317.557 252.68 318.248 251.837 318.256Z"
      fill="#45517B"
    />
    <path
      d="M241.322 318.385L237.796 318.431C236.96 318.438 236.269 317.77 236.262 316.926L236.026 297.897L241.071 297.836L241.322 318.385Z"
      fill="#45517B"
    />
    <path
      d="M248.729 329.901L241.192 329.992C240.357 330 239.665 329.332 239.658 328.488L239.445 311.465C239.437 310.629 240.106 309.938 240.949 309.93L248.486 309.839C249.322 309.831 250.013 310.5 250.021 311.343L250.233 328.367C250.241 329.202 249.572 329.894 248.729 329.901Z"
      fill="#ABB4D1"
    />
    <path
      d="M45.479 300.29L63.3865 300.51C64.6477 300.526 65.681 299.515 65.6962 298.262L65.7645 292.496L43.2985 292.215L43.2302 297.981C43.215 299.242 44.2178 300.275 45.479 300.29Z"
      fill="#45517B"
    />
    <path
      d="M60.5632 304.533L60.6257 299.489L48.2809 299.336L48.2184 304.38L60.5632 304.533Z"
      fill="#45517B"
    />
    <path
      d="M47.2798 318.256L50.805 318.301L51.0558 297.753L46.011 297.692L45.7755 316.721C45.7679 317.557 46.444 318.248 47.2798 318.256Z"
      fill="#45517B"
    />
    <path
      d="M57.8025 318.385L61.3278 318.431C62.1635 318.438 62.8549 317.77 62.8625 316.926L63.098 297.897L58.0532 297.836L57.8025 318.385Z"
      fill="#45517B"
    />
    <path
      d="M50.3871 329.901L57.9239 329.992C58.7597 330 59.451 329.332 59.4586 328.488L59.6714 311.465C59.679 310.629 59.0104 309.938 58.167 309.93L50.6303 309.839C49.7945 309.831 49.1031 310.5 49.0955 311.343L48.8828 328.367C48.8828 329.202 49.5514 329.894 50.3871 329.901Z"
      fill="#ABB4D1"
    />
    <path
      d="M260.787 296.218L150.136 305.334L38.3374 296.218V29.9456H260.787V296.218Z"
      fill="#026DE0"
    />
    <path
      d="M265.368 11.9189H33.7561C32.4949 11.9189 31.4768 12.9369 31.4768 14.1979V21.4754C31.4768 22.7364 32.4949 23.7543 33.7561 23.7543H265.368C266.629 23.7543 267.647 22.7364 267.647 21.4754V14.1979C267.647 12.9369 266.622 11.9189 265.368 11.9189Z"
      fill="#ABB4D1"
    />
    <path
      d="M243.244 283.479L149.505 291.075L55.8725 283.479C55.0368 283.479 54.353 282.795 54.353 281.96V228.549C54.353 227.713 55.0368 227.029 55.8725 227.029H243.236C244.072 227.029 244.756 227.713 244.756 228.549V281.96C244.763 282.795 244.08 283.479 243.244 283.479Z"
      fill="#45517B"
    />
    <path d="M173.658 150.73H168.377V189.784H173.658V150.73Z" fill="#45517B" />
    <path
      d="M46.5883 25.0154V11.1138H32.8899C31.2108 11.1138 29.8508 12.4735 29.8508 14.1524V21.9768C29.8508 23.6556 31.2108 25.0154 32.8899 25.0154L37.9726 36.9419H48.6244L46.5883 25.0154Z"
      fill="#45517B"
    />
    <path
      d="M265.376 11.1138H251.677V25.0154H251.821L249.785 36.9419H260.437L265.52 25.0154H265.376C267.055 25.0154 268.415 23.6556 268.415 21.9768V14.1524C268.415 12.4735 267.055 11.1138 265.376 11.1138Z"
      fill="#45517B"
    />
    <path
      d="M152.233 0H155.598C156.859 0 157.878 1.01793 157.878 2.27895V73.4962H149.953V2.27895C149.953 1.01793 150.979 0 152.233 0Z"
      fill="#45517B"
    />
    <path
      d="M104.983 74.5978H74.7145L79.1363 58.1665L100.432 58.3716L104.983 74.5978Z"
      fill="#45517B"
    />
    <path
      d="M167.61 217.572H56.4802C55.6445 217.572 54.9607 216.888 54.9607 216.052V74.7268C54.9607 73.8912 55.6445 73.2075 56.4802 73.2075H167.617C168.453 73.2075 169.137 73.8912 169.137 74.7268V216.045C169.129 216.888 168.453 217.572 167.61 217.572Z"
      fill="#DDDFE4"
    />
    <path
      d="M135.662 165.3H86.4831C86.0653 165.3 85.7234 164.958 85.7234 164.54V125.335C85.7234 124.917 86.0653 124.575 86.4831 124.575H135.662C136.08 124.575 136.422 124.917 136.422 125.335V164.54C136.422 164.958 136.08 165.3 135.662 165.3Z"
      fill="#AAAFBA"
    />
    <path
      d="M132.076 125.282H90.0692V152.796H132.076V125.282Z"
      fill="#1F2947"
    />
    <path
      d="M129.865 129.498H92.2877V150.981H129.865V129.498Z"
      fill="#45517B"
    />
    <path
      d="M132.038 152.796H90.1147V159.952H132.038V152.796Z"
      fill="#82A5C1"
    />
    <path
      d="M104.375 125.965H91.7559V128.206H104.375V125.965Z"
      fill="#3EDBFF"
    />
    <path
      d="M114.412 194.25V191.66C114.412 191.242 114.07 190.9 113.652 190.9H112.148C111.73 190.9 111.388 191.242 111.388 191.66V194.25C109.39 194.889 107.946 196.757 107.946 198.968C107.946 201.178 109.39 203.047 111.388 203.685V204.756C111.388 205.174 111.73 205.516 112.148 205.516H113.652C114.07 205.516 114.412 205.174 114.412 204.756V203.685C116.41 203.047 117.854 201.178 117.854 198.968C117.854 196.757 116.41 194.889 114.412 194.25Z"
      fill="#45517B"
    />
    <path
      d="M109.094 106.222C112.429 106.222 115.134 103.518 115.134 100.183C115.134 96.8474 112.429 94.1436 109.094 94.1436C105.758 94.1436 103.053 96.8474 103.053 100.183C103.053 103.518 105.758 106.222 109.094 106.222Z"
      fill="#AAAFBA"
    />
    <path
      d="M159.838 111.631H133.687C132.851 111.631 132.167 110.947 132.167 110.111V83.9641C132.167 83.1285 132.851 82.4448 133.687 82.4448H159.838C160.673 82.4448 161.357 83.1285 161.357 83.9641V110.111C161.357 110.947 160.673 111.631 159.838 111.631Z"
      fill="#FFC700"
    />
    <path
      d="M146.762 109.048C153.396 109.048 158.774 103.671 158.774 97.0379C158.774 90.4049 153.396 85.0278 146.762 85.0278C140.128 85.0278 134.75 90.4049 134.75 97.0379C134.75 103.671 140.128 109.048 146.762 109.048Z"
      fill="#F93A5F"
    />
    <path
      d="M155.826 97.3494H152.719C152.468 98.8763 152.012 100.365 151.146 101.755C149.421 104.543 146.747 105.941 143.807 106.602C144.627 106.837 145.493 106.967 146.398 106.967C151.609 106.967 155.834 102.743 155.834 97.5317C155.834 97.4709 155.826 97.4101 155.826 97.3494Z"
      fill="#A5324E"
    />
    <path
      d="M89.6665 40.5046C83.7328 40.5046 78.9236 45.3132 78.9236 51.2461V59.5795H100.41V51.2461C100.41 45.3132 95.6002 40.5046 89.6665 40.5046Z"
      fill="#F93A5F"
    />
    <path
      d="M32.5707 204.551C29.5621 204.551 27.1233 202.113 27.1233 199.104V45.7917C27.1233 42.7834 29.5621 40.345 32.5707 40.345C35.5794 40.345 38.0182 42.7834 38.0182 45.7917V199.104C38.0182 202.113 35.5794 204.551 32.5707 204.551Z"
      fill="#455171"
    />
    <path
      d="M5.44746 204.551C2.43882 204.551 0 202.113 0 199.104V45.7917C0 42.7834 2.43882 40.345 5.44746 40.345C8.45609 40.345 10.8949 42.7834 10.8949 45.7917V199.104C10.8949 202.113 8.45609 204.551 5.44746 204.551Z"
      fill="#455171"
    />
    <path
      d="M19.0092 198.565C16.0006 198.565 13.5618 196.127 13.5618 193.119V39.8134C13.5618 36.8052 16.0006 34.3667 19.0092 34.3667C22.0179 34.3667 24.4567 36.8052 24.4567 39.8134V193.126C24.4567 196.127 22.0179 198.565 19.0092 198.565Z"
      fill="#455171"
    />
    <path
      d="M279.553 204.551C276.544 204.551 274.105 202.113 274.105 199.104V45.7917C274.105 42.7834 276.544 40.345 279.553 40.345C282.561 40.345 285 42.7834 285 45.7917V199.104C285 202.113 282.561 204.551 279.553 204.551Z"
      fill="#455171"
    />
    <path
      d="M265.991 198.565C262.982 198.565 260.543 196.127 260.543 193.119V39.8134C260.543 36.8052 262.982 34.3667 265.991 34.3667C269 34.3667 271.438 36.8052 271.438 39.8134V193.126C271.438 196.127 269 198.565 265.991 198.565Z"
      fill="#455171"
    />
    <path
      d="M241.268 244.691C241.268 244.691 265.52 237.323 266.143 190.505"
      stroke="#455171"
      strokeWidth="14"
      strokeMiterlimit="10"
    />
    <path
      d="M225.048 239.047H239.263C240.098 239.047 240.782 239.731 240.782 240.566V278.716C240.782 279.551 240.098 280.235 239.263 280.235H225.048C224.212 280.235 223.528 279.551 223.528 278.716V240.566C223.528 239.731 224.204 239.047 225.048 239.047Z"
      fill="#DDDFE4"
    />
    <path
      d="M173.749 230.205H187.964C188.8 230.205 189.483 230.888 189.483 231.724V269.874C189.483 270.709 188.8 271.393 187.964 271.393H173.749C172.913 271.393 172.229 270.709 172.229 269.874V231.724C172.229 230.881 172.913 230.205 173.749 230.205Z"
      fill="#DDDFE4"
    />
    <path
      d="M131.818 230.205H139.902C140.737 230.205 141.421 230.888 141.421 231.724V284.018C141.421 284.854 140.737 285.538 139.902 285.538H131.818C130.982 285.538 130.298 284.854 130.298 284.018V231.724C130.298 230.881 130.975 230.205 131.818 230.205Z"
      fill="#DDDFE4"
    />
    <path
      d="M88.6637 230.205H102.879C103.714 230.205 104.398 230.888 104.398 231.724V269.874C104.398 270.709 103.714 271.393 102.879 271.393H88.6637C87.8279 271.393 87.1442 270.709 87.1442 269.874V231.724C87.1442 230.881 87.8279 230.205 88.6637 230.205Z"
      fill="#DDDFE4"
    />
    <path
      d="M202.126 112.398C198.517 108.972 196.709 104.14 196.709 97.9037C196.709 93.8244 197.499 90.292 199.087 87.3218C200.675 84.3516 202.954 82.0802 205.924 80.5153C208.895 78.9504 212.443 78.1604 216.561 78.1604C221.667 78.1604 225.86 79.2619 229.143 81.4725C232.425 83.6755 234.431 86.7293 235.16 90.6339H231.004C230.313 87.3902 228.732 84.8909 226.271 83.1437C223.809 81.3965 220.626 80.5153 216.728 80.5153C211.843 80.5153 208.029 82.0346 205.294 85.0808C202.559 88.127 201.191 92.3811 201.191 97.8506C201.191 103.396 202.604 107.665 205.431 110.673C208.257 113.682 212.276 115.186 217.496 115.186C222.343 115.186 226.483 114.107 229.91 111.957V99.2711H216.402V97.0833H234.4V112.398C229.986 115.824 224.333 117.541 217.442 117.541C210.84 117.541 205.735 115.832 202.126 112.398Z"
      fill="#1F2947"
    />
    <path
      d="M183.671 136.57C182.19 135.165 181.453 133.182 181.453 130.622C181.453 128.943 181.779 127.5 182.425 126.277C183.079 125.061 184.013 124.127 185.229 123.481C186.444 122.836 187.903 122.517 189.597 122.517C191.694 122.517 193.411 122.972 194.756 123.876C196.101 124.78 196.921 126.034 197.225 127.637H195.516C195.235 126.307 194.581 125.282 193.578 124.56C192.568 123.846 191.261 123.481 189.666 123.481C187.66 123.481 186.095 124.104 184.978 125.358C183.854 126.604 183.299 128.351 183.299 130.599C183.299 132.871 183.876 134.625 185.039 135.864C186.201 137.094 187.85 137.717 189.985 137.717C191.975 137.717 193.67 137.277 195.075 136.395V131.192H189.537V130.295H196.921V136.578C195.113 137.983 192.796 138.69 189.962 138.69C187.25 138.682 185.153 137.976 183.671 136.57Z"
      fill="#1F2947"
    />
    <path
      d="M211.319 134.329H201.936L199.846 138.454H198.638L206.806 122.524H207.611L215.307 138.454H213.241L211.319 134.329ZM210.87 133.364L206.806 124.682L202.43 133.364H210.87Z"
      fill="#1F2947"
    />
    <path
      d="M220.14 123.717H213.454V122.752H228.649V123.717H221.986V138.462H220.147V123.717H220.14Z"
      fill="#1F2947"
    />
    <path
      d="M235.775 123.717H229.089V122.752H244.285V123.717H237.622V138.462H235.783V123.717H235.775Z"
      fill="#1F2947"
    />
    <path
      d="M247.156 122.752H248.995V138.454H247.156V122.752Z"
      fill="#1F2947"
    />
  </svg>
);
