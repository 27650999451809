import Box from "@/elements/Box";
import Button from "@/elements/Button";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Icon,
} from "@mui/material";
import {
  AlloggiamentoFiltroDto,
  CentraleDto,
  FiltroDto,
  getGetCentraleQueryKey,
  useUpdateStoricoFiltri,
} from "@/api";
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import Table from "@/elements/Table";
import { useCallback, useMemo } from "react";
import {
  IS_NEW_KEY,
  useDbRelationsUpdateForm,
} from "@/utils/useDbRelationsUpdate";
import { Controller } from "react-hook-form";
import { withOpeningReset } from "../../utils/withOpeningReset";
import { useAuth } from "@/context/useAuth";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { buildDateTimeCell } from "@/elements/Table/dateTimeCell";
import ModelloFiltroPicker from "@/components/ModelloFiltroPicker";
import { get } from "lodash";
import moment from "moment";

const columnHelper = createColumnHelper<FiltroDto>();

function StoricoAlloggiamentoFiltroDialog({
  open = false,
  canEdit = false,
  onClose,
  centrale,
  alloggiamentoFiltro,
}: {
  open?: boolean;
  canEdit?: boolean;
  onClose?: () => void;
  centrale: CentraleDto;
  alloggiamentoFiltro?: AlloggiamentoFiltroDto;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const editing = canEdit;

  const storicoFiltri = alloggiamentoFiltro?.storicoFiltri;
  const {
    data,
    control,
    resetField,
    watch,
    handleSubmit,
    getChangeSubmitData,
    create,
    remove,
    restore,
    getFieldName,
    getEntityState,
    setValue,
    setError,
    formState: { errors },
    getValues,
  } = useDbRelationsUpdateForm(storicoFiltri);

  const { hasPermission } = useAuth();

  const columns = useMemo<ColumnDef<FiltroDto, any>[]>(() => {
    const dataTimeCell = buildDateTimeCell(control, editing);

    const columns = [
      columnHelper.accessor("montatoIl", {
        header: "Montato il",
        size: 175,
        maxSize: 175,
        cell: ({ getValue, row }) =>
          dataTimeCell(getValue(), getFieldName(row.index, "montatoIl"), true),
      }),

      columnHelper.accessor("modelloFiltro", {
        header: "Modello filtro",
        size: undefined,
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "modelloFiltro")}
                control={control}
                rules={{ required: "Inserisci il modello filtro" }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <ModelloFiltroPicker
                        {...field}
                        onChange={(e, v) => {
                          field.onChange(v);
                          setValue(
                            getFieldName(row.index, "modelloFiltroId"),
                            v?.id
                          );

                          let daSostituireDopoIl: string | null = null;
                          if (v?.durataDiVitaInMesi) {
                            const values = getValues();
                            const montatoIl = get(
                              values,
                              getFieldName(row.index, "montatoIl")
                            );
                            if (montatoIl) {
                              daSostituireDopoIl = moment(montatoIl)
                                .add(v.durataDiVitaInMesi, "months")
                                .startOf("day")
                                .toISOString();
                            }
                          }
                          setValue(
                            getFieldName(row.index, "daSostituireDopoIl"),
                            daSostituireDopoIl
                          );
                        }}
                        canCreateModelloFiltro={hasPermission(
                          "ModelliFiltroChange"
                        )}
                      />
                      {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            )
          : ({ row: { original } }) =>
              original.modelloFiltro
                ? `${original.modelloFiltro.marca} - ${original.modelloFiltro.nome}`
                : null,
      }),

      columnHelper.accessor("daSostituireDopoIl", {
        header: "Da sostituire dopo il",
        size: 175,
        maxSize: 175,
        cell: ({ getValue, row }) =>
          dataTimeCell(
            getValue(),
            getFieldName(row.index, "daSostituireDopoIl"),
            false,
            "DD/MM/YYYY",
            true
          ),
      }),

      // columnHelper.accessor("smontatoIl", {
      //   header: "Sostituito il",
      //   size: 175,
      //   maxSize: 175,
      //   cell: ({ getValue, row }) =>
      //     dataTimeCell(
      //       getValue(),
      //       getFieldName(row.index, "smontatoIl"),
      //       false
      //     ),
      // }),

      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 125,
          maxSize: 125,
          cell: ({ row }) => {
            return (
              <>
                <Box display="flex" gap={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<Icon>delete_outlined</Icon>}
                    onClick={() => {
                      remove(row.index);
                    }}
                  >
                    Elimina
                  </Button>
                </Box>
                <Box
                  display="flex"
                  gap={1}
                  className="deleted-row-visible-content"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<Icon>restore_from_trash</Icon>}
                    onClick={() => {
                      restore(row.index);
                    }}
                  >
                    Ripristina
                  </Button>
                </Box>
              </>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [
    editing,
    control,
    getFieldName,
    hasPermission,
    resetField,
    watch,
    remove,
    restore,
  ]);

  const isRowDeleted = useCallback(
    (row: Row<FiltroDto>) => {
      return getEntityState(row.index) === "deleted";
    },
    [getEntityState]
  );

  const { mutateAsync, isLoading, error } = useUpdateStoricoFiltri({
    mutation: {
      onSuccess: () => {
        toast.success("Storico filtri salvato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const data = getValues();
    // if (data.data.filter((o) => !o.smontatoIl).length > 1) {
    //   setError("root", {
    //     message: "Al massimo una riga può avere il campo 'smontato il' vuoto",
    //   });
    //   return;
    // }

    try {
      await mutateAsync({
        id: centrale.id,
        alloggiamentoFiltroId: alloggiamentoFiltro!.id,
        data: getChangeSubmitData(),
      });
      await queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>
        Storico filtri - {centrale?.nome} - {alloggiamentoFiltro?.nome}
      </DialogTitle>
      <DialogContent>
        {storicoFiltri && (
          <Box>
            <Box>
              {editing && (
                <Button
                  size="small"
                  color="light"
                  onClick={() => {
                    create();
                  }}
                >
                  Aggiungi filtro
                </Button>
              )}
            </Box>

            {errors.root?.message && (
              <Alert sx={{ mt: 1 }} severity="warning">
                {errors.root?.message}
              </Alert>
            )}

            <Table
              columns={columns}
              data={data}
              sortable={false}
              initialSort={[
                { id: IS_NEW_KEY, desc: false },
                { id: "montatoIl", desc: true },
              ]}
              isRowDeleted={isRowDeleted}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        {editing && (
          <Button
            size="small"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Salva
          </Button>
        )}
        {onClose && (
          <Button size="small" color="light" onClick={handleClose}>
            Chiudi
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(StoricoAlloggiamentoFiltroDialog);
