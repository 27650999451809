import {
  getGetAziendaQueryKey,
  StabilimentoCreationDto,
  StabilimentoDto,
  StabilimentoUpdateDto,
  useCreateStabilimenti,
  useUpdateStabilimenti,
} from "@/api";
import { useAuth } from "@/context/useAuth";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import { InputLabel, FormHelperText } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SmallLoading } from "../Loading";
import UserPicker from "../UserPicker";

function StabilimentoDialog({
  create = true,
  open = false,
  onClose,
  initialData,
}: {
  create?: boolean;
  open?: boolean;
  onClose?: (stabilimento: StabilimentoDto | null) => void;
  initialData?:
    | Partial<Omit<StabilimentoCreationDto, "aziendaId">>
    | Partial<Omit<StabilimentoUpdateDto, "aziendaId">>
    | null;
}) {
  const { hasPermission } = useAuth();

  const handleClose = () => {
    onClose?.(null);
  };

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<StabilimentoCreationDto>({
    defaultValues: {
      ...initialData,
    },
  });

  const { saveAsync, isLoading, error } = useMutator(create, initialData);

  const onSubmit = async (
    data: StabilimentoCreationDto | StabilimentoUpdateDto
  ) => {
    try {
      console.log(data);
      const result = await saveAsync(data);
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Crea stabilimento</DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-stabilimento-nome">
          Nome stabilimento
        </InputLabel>
        <TextField
          id="crea-stabilimento-nome"
          {...register("nome", {
            required: "Inserisci il nome dell'stabilimento",
            minLength: 1,
          })}
          error={!!errors.nome?.message}
          helperText={errors.nome?.message || null}
          fullWidth
        />

        {hasPermission("UsersInterniRead") && (
          <>
            <InputLabel htmlFor="crea-centrale-referente-commerciale">
              Referente commerciale
            </InputLabel>
            <Controller
              control={control}
              name="referenteCommerciale"
              render={({ field: { value, onChange } }) => {
                return (
                  <UserPicker
                    value={value}
                    onChange={(_, v) => {
                      onChange(v);
                      setValue("referenteCommercialeId", v?.id);
                    }}
                    role="Commerciale"
                  />
                );
              }}
            />
            {errors.referenteCommerciale && (
              <FormHelperText error>
                {errors.referenteCommerciale?.message}
              </FormHelperText>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {create ? "Crea stabilimento" : "Salva stabilimento"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function useMutator(
  create: boolean,
  stabilimento: Partial<StabilimentoDto> | null | undefined
): {
  isLoading: boolean;
  error: AxiosError<unknown, any> | null;
  saveAsync: (data: Partial<StabilimentoDto>) => Promise<any>;
} {
  const queryClient = useQueryClient();

  const createObj = useCreateStabilimenti({
    mutation: {
      onSuccess: () => {
        toast.success("Stabilimento creato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const updateObj = useUpdateStabilimenti({
    mutation: {
      onSuccess: () => {
        toast.success("Stabilimento salvato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const { isLoading, error } = create ? createObj : updateObj;

  return {
    isLoading,
    error,
    saveAsync: async (data: Partial<StabilimentoDto>) => {
      const result = create
        ? await createObj.mutateAsync({ data: data as StabilimentoCreationDto })
        : await updateObj.mutateAsync({
            id: stabilimento?.id!,
            data: data as StabilimentoUpdateDto,
          });

      if (!create) {
        await queryClient.invalidateQueries(
          getGetAziendaQueryKey(stabilimento?.id!)
        );
      }
      return result;
    },
  };
}

export default withOpeningReset(StabilimentoDialog);
