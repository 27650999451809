import Box from "@/elements/Box";
import Button from "@/elements/Button";
import { getProprietaMisurataHelper } from "@/utils/proprietaMisurataUtils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ProprietaMisurataHistoryChart from "../ProprietaMisurataHistoryChart";
import { useMemo, useState } from "react";
import {
  DispositivoDto,
  ProprietaMisurataDataInterval,
  ProprietaMisurataDto,
  useGetDispositivoHistoryData,
} from "@/api";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import { getDispositivoTypeLabel } from "@/utils/dispositivoUtils";
import moment from "moment";
import HistoryIntervalSlider from "./HistoryIntervalSlider";
import { withOpeningReset } from "@/utils/withOpeningReset";

function ProprietaMisurataHistoryDialog({
  open = false,
  onClose,
  dispositivo,
  proprietaMisurata,
}: {
  open?: boolean;
  onClose?: () => void;
  dispositivo?: DispositivoDto;
  proprietaMisurata?: ProprietaMisurataDto;
}) {
  const [intervalOrRange, setIntervalOrRange] = useState<string>(
    ProprietaMisurataDataInterval["24h"]
  );
  const [rangeZoom, setRangeZoom] = useState<[string, string] | [null, null]>([
    null,
    null,
  ]);

  const isInterval = ProprietaMisurataDataInterval[
    intervalOrRange as ProprietaMisurataDataInterval
  ]
    ? true
    : false;

  const interval = isInterval
    ? (intervalOrRange as ProprietaMisurataDataInterval)
    : undefined;
  const selectedTrasferimentoString = isInterval
    ? undefined
    : (intervalOrRange as `t-${number}`);
  const selectedTrasferimento = selectedTrasferimentoString
    ? parseInt(selectedTrasferimentoString.substring(2))
    : undefined;

  const selectedTrasferimentoObject = useMemo(() => {
    return selectedTrasferimento && dispositivo?.trasferimenti?.length
      ? dispositivo.trasferimenti.find((t) => t.id === selectedTrasferimento) ||
          null
      : null;
  }, [selectedTrasferimento, dispositivo?.trasferimenti]);

  const { data, error, isLoading } = useGetDispositivoHistoryData(
    dispositivo?.id ?? 0,
    proprietaMisurata?.id ?? 0,
    {
      interval,
      idTrasferimento: selectedTrasferimento,
    },
    {
      query: {
        enabled: Boolean(open && dispositivo && proprietaMisurata),
      },
    }
  );

  const handleChange = (event: SelectChangeEvent) => {
    setIntervalOrRange(event.target.value);
    setRangeZoom([null, null]);
  };

  const values = useMemo(() => {
    let values = data?.data;

    if (values && rangeZoom[0] && rangeZoom[1]) {
      let from = moment(rangeZoom[0]);
      let to = moment(rangeZoom[1]);
      values = values.filter((x) => {
        const m = moment(x.timestamp);
        return m.isSameOrAfter(from) && m.isSameOrBefore(to);
      });
    }

    return values;
  }, [data?.data, rangeZoom]);

  const helper =
    proprietaMisurata && getProprietaMisurataHelper(proprietaMisurata.type);
  if (!helper) {
    return null;
  }

  const label = helper.label;

  const dispositivoLabel = dispositivo
    ? `${getDispositivoTypeLabel(dispositivo.type)} ${dispositivo.matricola}`
    : "";

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>
        Storico {label} - {dispositivoLabel}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flex={1}>
            {selectedTrasferimentoObject &&
            selectedTrasferimentoObject.da &&
            selectedTrasferimentoObject.a ? (
              <HistoryIntervalSlider
                fromDate={selectedTrasferimentoObject.da}
                toDate={selectedTrasferimentoObject.a}
                value={[
                  rangeZoom[0] || selectedTrasferimentoObject.da,
                  rangeZoom[1] || selectedTrasferimentoObject.a,
                ]}
                onChange={([from, to]) => {
                  console.log(from, to);
                  setRangeZoom([from, to]);
                }}
              />
            ) : null}
          </Box>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select value={intervalOrRange} onChange={handleChange}>
                {Object.keys(ProprietaMisurataDataInterval).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  );
                })}
                {dispositivo?.trasferimenti &&
                  dispositivo.trasferimenti.length >= 1 && <Divider />}

                {(dispositivo?.trasferimenti || []).map((t) => {
                  const value = `t-${t.id}`;
                  const key = value;

                  const labelDa = t.da
                    ? `Da ${moment(t.da).format("YYYY-MM-DD")}`
                    : "";
                  const labelA = t.a
                    ? `A ${moment(t.a).format("YYYY-MM-DD")}`
                    : "";
                  const label = [labelDa, labelA].filter((x) => x).join(" - ");

                  return (
                    <MenuItem key={key} value={key}>
                      <div>
                        {label}
                        <br />
                        <i>{t.azienda?.nome || "-"}</i>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box>
          {isLoading && <Loading />}
          {!isLoading && error && <LoadingError error={error} />}
          {!isLoading && values && (
            <ProprietaMisurataHistoryChart
              height={500}
              proprietaMisurata={proprietaMisurata}
              values={values}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button size="small" color="light" onClick={handleClose}>
            Chiudi
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(ProprietaMisurataHistoryDialog);
