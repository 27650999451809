import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useForm,
  useFormState,
  useFieldArray,
  Control,
  UseFormReturn,
  FieldArrayPath,
  FieldArray,
} from "react-hook-form";
import get from "lodash/get";
import { DeepKeys } from "@tanstack/react-table";

type EntityChangeType = "added" | "updated" | "removed" | "deleted";

type EntityChange<E, TKey> = {
  changeType: EntityChangeType;
  entity: E;
  id?: TKey;
};

export const IS_NEW_KEY = "$$IS_NEW";

type FieldTypeExtension = { key: string; [IS_NEW_KEY]?: boolean };

export function useDbRelationsUpdateForm<
  D extends Record<string, any>,
  TKey extends string = "id"
  // TKey extends Extract<keyof D, string>
>(
  data: D[] | null | undefined,
  options?: {
    idAttribute?: TKey;
    deleteChangeType?: Extract<EntityChangeType, "removed" | "deleted">;
  }
) {
  const formMethods = useForm<{ data: D[] }>({
    shouldUnregister: false,
    defaultValues: {
      // @ts-ignore
      data: data || [],
    },
  });

  const { reset } = formMethods;

  const dbRelationsUpdate = useDbRelationsUpdate<
    { data: D[] },
    // it works...
    //@ts-ignore
    "data",
    TKey
  >(formMethods, "data", options);

  useEffect(() => {
    reset({ data: data || [] });
  }, [data, reset]);

  return dbRelationsUpdate;
}

export function useDbRelationsUpdate<
  FormData extends Record<string, any>,
  BasePath extends FieldArrayPath<FormData>,
  TKey extends string = "id"
  // TKey extends keyof //keyof FieldArray<FormData, BasePath> // = "id"
>(
  formMethods: UseFormReturn<FormData, any, any>,
  basePath: BasePath,
  options?: {
    idAttribute?: TKey;
    deleteChangeType?: Extract<EntityChangeType, "removed" | "deleted">;
  }
) {
  type D = FieldArray<FormData, BasePath> extends Record<string, any>
    ? FieldArray<FormData, BasePath>
    : never;

  const { idAttribute = "id" as TKey, deleteChangeType = "removed" } =
    options || {};
  const _options: Required<typeof options> = {
    idAttribute,
    deleteChangeType,
  };
  const optionsRef = useRef(_options);
  optionsRef.current = _options;

  const { control, getValues } = formMethods;

  const {
    fields,
    remove: _remove,
    append: _append,
    prepend: _prepend,
    // } = useFieldArray({
  } = useFieldArray<FormData, BasePath, "key">({
    control: formMethods.control,
    name: basePath,
    keyName: "key",
  });

  const [deleted, setDeleted] = useState<{
    [key: string]: boolean | undefined;
  }>({});
  const dirtyEntries = useDirtyEntries<FormData, BasePath>(
    basePath,
    control,
    fields
  );
  console.log("dirtyEntries", dirtyEntries);

  const dirtyEntriesRef = useRef(dirtyEntries);
  dirtyEntriesRef.current = dirtyEntries;
  const deletedRef = useRef(deleted);
  deletedRef.current = deleted;
  const fieldsRef = useRef(fields);
  fieldsRef.current = fields;

  const getEntityState = useCallback((index: number) => {
    const fields = fieldsRef.current as (D & FieldTypeExtension)[];
    const { key } = fields[index];
    const deleted = deletedRef.current;
    const dirtyEntries = dirtyEntriesRef.current;

    const isDeleted = deleted[key];
    if (isDeleted) {
      return "deleted";
    }
    const isDirty = dirtyEntries[key];
    if (isDirty) {
      return "changed";
    }
    return "unchanged";
  }, []);

  const getChangeSubmitData = useCallback(() => {
    const values = getValues();
    const data = get(values, basePath) as D[];
    const deleted = deletedRef.current;
    const dirtyEntries = dirtyEntriesRef.current;
    const fields = fieldsRef.current as (D & FieldTypeExtension)[];
    const { idAttribute, deleteChangeType } = optionsRef.current;

    return (data || [])
      .map<EntityChange<D, D[typeof idAttribute]>>((d, index) => {
        const { key } = fields[index];
        const id = d[idAttribute];
        let changeType: EntityChangeType;

        const isDeleted = deleted[key];
        if (isDeleted) {
          changeType = deleteChangeType;
        } else {
          const isDirty = dirtyEntries[key];
          if (isDirty) {
            const isAdded = fields[index][IS_NEW_KEY];
            changeType = isAdded ? "added" : "updated";
          } else {
            return null as any;
          }
        }

        const d2 = { ...d };
        delete (d2 as any)[IS_NEW_KEY];

        return {
          changeType: changeType,
          entity: d2,
          id: id,
        };
      })
      .filter((x) => x);
  }, [getValues, basePath]);

  const remove = useCallback(
    (index: number) => {
      const fields = fieldsRef.current as (D & FieldTypeExtension)[];
      const { key } = fields[index];
      setDeleted((d) => {
        return {
          ...d,
          [key]: true,
        };
      });
    },
    [setDeleted]
  );

  const restore = useCallback(
    (index: number) => {
      const fields = fieldsRef.current as (D & FieldTypeExtension)[];
      const { key } = fields[index];
      setDeleted((d) => {
        const d2 = { ...d };
        delete d2[key];
        return d2;
      });
    },
    [setDeleted]
  );

  const create = useCallback(
    (d?: Partial<D>) => {
      d = d || {};
      d = { ...d, [IS_NEW_KEY]: true };
      _append(d as FieldArray<FormData, BasePath>);
    },
    [_append]
  );

  const data2 = useMemo(() => {
    const { deleteChangeType } = optionsRef.current;
    let data2 = fields as (D & FieldTypeExtension)[];

    // if (deleteChangeType === "deleted") {
    //   data2 = data2.filter((x) => {
    //     const isDeleted = deleted[x.key];
    //     return !isDeleted;
    //   });
    // }

    return data2;
  }, [fields, deleted]);

  const getFieldName = useCallback(
    <Rest extends Extract<DeepKeys<D>, string>>(index: number, rest: Rest) => {
      const name = `${basePath}.${index}.${rest}` as const;
      return name;
    },
    [basePath]
  );

  return {
    ...formMethods,
    data: data2,
    getFieldName,
    getEntityState,
    getChangeSubmitData,
    create,
    remove,
    restore,
  };
}

// *****
// UTILS
// *****

type DataFromBasePath<
  T extends string,
  R
> = T extends `${infer First}.${infer Rest}`
  ? {
      [K in First]: DataFromBasePath<Rest, R>;
    }
  : {
      [K in T]: R;
    };

function useDirtyEntries<
  FormData extends Record<string, any>,
  BasePath extends FieldArrayPath<FormData>
>(
  basePath: BasePath,
  control: Control<FormData, any>,
  fields: Record<"key", string>[]
) {
  const { dirtyFields, isDirty } = useFormState({
    control: control,
  });
  console.log("isDirty-dirtyFields", isDirty, dirtyFields);

  const dirtyEntries: {
    [key: string]: boolean | undefined;
  } = {};
  const dirtyData = get(dirtyFields, basePath);
  if (dirtyData) {
    dirtyData.forEach((dirtyEntry: any, index: number) => {
      const someDirty = hasSomeDirty(dirtyEntry);
      if (someDirty) {
        const key = fields[index].key;
        dirtyEntries[key] = true;
      }
    });
  }
  return dirtyEntries;
}

function hasSomeDirty(obj: any) {
  if (typeof obj === "object") {
    const keys = Object.keys(obj);
    const deep: any[] = [];
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const obj2 = obj[key];
      if (typeof obj2 === "object") {
        deep.push(obj2);
      } else if (obj2 === true) {
        return true;
      }
    }
    for (let index = 0; index < deep.length; index++) {
      const obj2 = deep[index];
      if (hasSomeDirty(obj2)) {
        return true;
      }
    }
  } else {
    return obj === true;
  }
  return false;
}
