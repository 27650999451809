import {
  ComponentProps,
  ComponentType,
  FC,
  useEffect,
  useRef,
  useState,
} from "react";

export const withOpeningReset = <P,>(
  Component: ComponentType<P & { open?: boolean }>
): FC<ComponentProps<typeof Component>> => {
  const C2: FC<ComponentProps<typeof Component>> = (
    props: ComponentProps<typeof Component>
  ) => {
    const { open } = props;
    const keyRef = useRef(1);
    const [shouldRender, setShouldRender] = useState<true | false | "next">(
      false
    );
    const prevOpen = useRef(open);

    useEffect(() => {
      if (shouldRender === "next") {
        setShouldRender(true);
      }
    }, [shouldRender]);

    useEffect(() => {
      // if (open === prevOpen.current) {
      //   return;
      // }
      if (open) {
        keyRef.current++;
        setShouldRender("next");
      } else {
        const timeout = setTimeout(() => {
          setShouldRender(false);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }, [open]);

    prevOpen.current = open;

    if (shouldRender !== true) {
      return null;
    }

    const Component2 = Component as any;

    return <Component2 {...props} key={keyRef.current} />;
  };

  C2.displayName = `withOpeningReset(${
    Component.displayName || Component.name
  })`;

  return C2;
};
