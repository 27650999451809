// import { Link } from "react-router-dom";
import {
  CompleteForgotPasswordRequestDto,
  useCompleteForgotPassword,
} from "@/api";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import CoverLayout from "@/elements/CoverLayout";
import Input from "@/elements/Input";
import Typography from "@/elements/Typography";
import { FormHelperText } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addServerErrorsFactory } from "../../utils/addServerErrorsFactory";

function CompletaRecuperoPassword() {
  const { user, onLogin } = useAuth();
  const navigate = useNavigate();
  const { isLoading, mutate: doCompleteForgotPassword } =
    useCompleteForgotPassword();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const params = useParams();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
  } = useForm<CompleteForgotPasswordRequestDto>({
    defaultValues: {
      resetPermlink: params.resetPermlink,
      token: params.token,
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit: SubmitHandler<CompleteForgotPasswordRequestDto> = async (
    data
  ) => {
    doCompleteForgotPassword(
      { data },
      {
        onSuccess: (response) => {
          const user = response.data.user;
          onLogin(user);
        },
        onError: addServerErrorsFactory(setError),
      }
    );
  };

  return (
    <CoverLayout
      title="Gatti Filtrazioni Lubrificanti"
      description="Recupero password: crea una nuova password"
      showLogo
    >
      <Box component="form" role="form">
        <Box mb={2}>
          <Input
            type="password"
            placeholder="Password"
            {...register("password")}
            disabled={isLoading}
            error={!!errors.password?.message}
          />
          {errors.password?.message ? (
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          ) : null}
        </Box>

        <Box mb={2}>
          <Input
            type="password"
            placeholder="Conferma password"
            {...register("confirmPassword")}
            disabled={isLoading}
            error={!!errors.confirmPassword?.message}
          />
          {errors.confirmPassword?.message ? (
            <FormHelperText error>
              {errors.confirmPassword?.message}
            </FormHelperText>
          ) : null}
        </Box>

        <Box mt={4} mb={1}>
          <Button
            color="secondary"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Recupera password
          </Button>
          {errors.root?.message ? (
            <FormHelperText error>{errors.root?.message}</FormHelperText>
          ) : null}
        </Box>
        <Box mt={3} textAlign="center">
          <Typography variant="button" color="text" fontWeight="regular">
            <Link to={"/login"}>
              <Typography variant="button" color="white" fontWeight="bold">
                Vai al login
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </CoverLayout>
  );
}

export default CompletaRecuperoPassword;
