/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  DescrizioneBonificaDto,
  DescrizioneBonificaUpdateDtoInt32EntityChange,
} from ".././models";

export const getDescrizioniBonifica = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DescrizioneBonificaDto[]>> => {
  return axios.get(`/api/descrizioniBonifica`, options);
};

export const getGetDescrizioniBonificaQueryKey = () =>
  [`/api/descrizioniBonifica`] as const;

export const getGetDescrizioniBonificaQueryOptions = <
  TData = Awaited<ReturnType<typeof getDescrizioniBonifica>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDescrizioniBonifica>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getDescrizioniBonifica>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDescrizioniBonificaQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDescrizioniBonifica>>
  > = ({ signal }) => getDescrizioniBonifica({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDescrizioniBonificaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDescrizioniBonifica>>
>;
export type GetDescrizioniBonificaQueryError = AxiosError<unknown>;

export const useGetDescrizioniBonifica = <
  TData = Awaited<ReturnType<typeof getDescrizioniBonifica>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDescrizioniBonifica>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDescrizioniBonificaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateDescrizioniBonifica = (
  descrizioneBonificaUpdateDtoInt32EntityChange: DescrizioneBonificaUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/descrizioniBonifica`,
    descrizioneBonificaUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateDescrizioniBonificaMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDescrizioniBonifica>>,
    TError,
    { data: DescrizioneBonificaUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateDescrizioniBonifica>>,
  TError,
  { data: DescrizioneBonificaUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDescrizioniBonifica>>,
    { data: DescrizioneBonificaUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { data } = props ?? {};

    return updateDescrizioniBonifica(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateDescrizioniBonificaMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDescrizioniBonifica>>
>;
export type UpdateDescrizioniBonificaMutationBody =
  DescrizioneBonificaUpdateDtoInt32EntityChange[];
export type UpdateDescrizioniBonificaMutationError = AxiosError<unknown>;

export const useUpdateDescrizioniBonifica = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDescrizioniBonifica>>,
    TError,
    { data: DescrizioneBonificaUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateDescrizioniBonificaMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetDescrizioniBonifica = () =>
  //@ts-ignore
  useGetDescrizioniBonifica(...([] as any[]));

export const __$$useUpdateDescrizioniBonifica = () =>
  //@ts-ignore
  useUpdateDescrizioniBonifica(...([] as any[]));
