import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  AziendaCreationDto,
  AziendaDto,
  AziendaUpdateDto,
  getGetAziendeQueryKey,
  useCreateAziende,
  useUpdateAzienda,
} from "@/api";
import { UseFormSetError, useForm } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import Loading, { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";

function AziendaDialog({
  open = false,
  onClose,
  azienda,
}: {
  open?: boolean;
  onClose?: (azienda: AziendaDto | null) => void;
  azienda?: Partial<AziendaDto> | null;
}) {
  const handleClose = () => {
    onClose?.(null);
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<AziendaDto>({
    defaultValues: azienda || {},
  });

  const { saveAsync, isLoading, error } = useMutator(azienda, setError);

  const queryClient = useQueryClient();

  const onSubmit = async (data: AziendaCreationDto) => {
    console.log(data);
    try {
      const result = await saveAsync(data);
      queryClient.invalidateQueries({ queryKey: getGetAziendeQueryKey() });
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  console.log("error, ", error);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {azienda?.id ? "Modifica azienda " : "Crea azienda"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-azienda-nome">Nome azienda</InputLabel>
        <TextField
          id="crea-azienda-nome"
          autoFocus
          {...register("nome", {
            required: "Inserisci il nome dell'azienda",
            minLength: 1,
          })}
          error={!!errors.nome?.message}
          helperText={errors.nome?.message || null}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {azienda?.id ? "Salva azienda" : "Crea azienda"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(AziendaDialog);

function useMutator(
  azienda: Partial<AziendaDto> | null | undefined,
  setError: UseFormSetError<Partial<AziendaDto>>
): {
  isLoading: boolean;
  error: AxiosError<unknown, any> | null;
  saveAsync: (data: Partial<AziendaDto>) => Promise<any>;
} {
  const isNew = !azienda?.id;

  const create = useCreateAziende({
    mutation: {
      onSuccess: () => {
        toast.success("Azienda creata!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const update = useUpdateAzienda({
    mutation: {
      onSuccess: () => {
        toast.success("Azienda salvata!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const { isLoading, error } = isNew ? create : update;

  return {
    isLoading,
    error,
    saveAsync: async (data: Partial<AziendaDto>) => {
      return isNew
        ? create.mutateAsync({ data: data as AziendaCreationDto })
        : update.mutateAsync({
            id: azienda.id!,
            data: data as AziendaUpdateDto,
          });
    },
  };
}
