import Typography from "@/elements/Typography";
import Box from "@/elements/Box";
import ModificaPasswordCard from "@/components/ProfiloPage/ModificaPasswordCard";
import DatiProfiloCard from "@/components/ProfiloPage/DatiProfiloCard";

export default function ProfiloPage() {
  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Profilo
        </Typography>
      </Box>

      <Box
        mt={2}
        mb={0}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
      >
        <DatiProfiloCard />

        <ModificaPasswordCard />
      </Box>
    </Box>
  );
}
