/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  UserLoginResponseDto,
  UserLoginRequestDto,
  ForgotPasswordRequestDto,
  CompleteForgotPasswordResponseDto,
  CompleteForgotPasswordRequestDto,
} from ".././models";

export const login = (
  userLoginRequestDto: UserLoginRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserLoginResponseDto>> => {
  return axios.post(`/api/auth/login`, userLoginRequestDto, options);
};

export const getLoginMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: UserLoginRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: UserLoginRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof login>>,
    { data: UserLoginRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return login(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof login>>
>;
export type LoginMutationBody = UserLoginRequestDto;
export type LoginMutationError = AxiosError<unknown>;

export const useLogin = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: UserLoginRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const logout = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/auth/logout`, undefined, options);
};

export const getLogoutMutationOptions = <
  TError = AxiosError<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logout>>,
    TError,
    TVariables,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof logout>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logout>>,
    TVariables
  > = () => {
    return logout(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof logout>>
>;

export type LogoutMutationError = AxiosError<unknown>;

export const useLogout = <
  TError = AxiosError<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logout>>,
    TError,
    TVariables,
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const currentUser = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserLoginResponseDto>> => {
  return axios.get(`/api/auth/me`, options);
};

export const getCurrentUserQueryKey = () => [`/api/auth/me`] as const;

export const getCurrentUserQueryOptions = <
  TData = Awaited<ReturnType<typeof currentUser>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof currentUser>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<Awaited<ReturnType<typeof currentUser>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCurrentUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof currentUser>>> = ({
    signal,
  }) => currentUser({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type CurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof currentUser>>
>;
export type CurrentUserQueryError = AxiosError<unknown>;

export const useCurrentUser = <
  TData = Awaited<ReturnType<typeof currentUser>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof currentUser>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCurrentUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const forgotPassword = (
  forgotPasswordRequestDto: ForgotPasswordRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/auth/forgotPassword`,
    forgotPasswordRequestDto,
    options
  );
};

export const getForgotPasswordMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof forgotPassword>>,
  TError,
  { data: ForgotPasswordRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forgotPassword>>,
    { data: ForgotPasswordRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return forgotPassword(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof forgotPassword>>
>;
export type ForgotPasswordMutationBody = ForgotPasswordRequestDto;
export type ForgotPasswordMutationError = AxiosError<unknown>;

export const useForgotPassword = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const completeForgotPassword = (
  completeForgotPasswordRequestDto: CompleteForgotPasswordRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CompleteForgotPasswordResponseDto>> => {
  return axios.post(
    `/api/auth/completeForgotPassword`,
    completeForgotPasswordRequestDto,
    options
  );
};

export const getCompleteForgotPasswordMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeForgotPassword>>,
    TError,
    { data: CompleteForgotPasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof completeForgotPassword>>,
  TError,
  { data: CompleteForgotPasswordRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof completeForgotPassword>>,
    { data: CompleteForgotPasswordRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return completeForgotPassword(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompleteForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof completeForgotPassword>>
>;
export type CompleteForgotPasswordMutationBody =
  CompleteForgotPasswordRequestDto;
export type CompleteForgotPasswordMutationError = AxiosError<unknown>;

export const useCompleteForgotPassword = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeForgotPassword>>,
    TError,
    { data: CompleteForgotPasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCompleteForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useLogin = () =>
  //@ts-ignore
  useLogin(...([] as any[]));

export const __$$useLogout = () =>
  //@ts-ignore
  useLogout(...([] as any[]));

export const __$$useCurrentUser = () =>
  //@ts-ignore
  useCurrentUser(...([] as any[]));

export const __$$useForgotPassword = () =>
  //@ts-ignore
  useForgotPassword(...([] as any[]));

export const __$$useCompleteForgotPassword = () =>
  //@ts-ignore
  useCompleteForgotPassword(...([] as any[]));
