import { AsyncAutocomplete } from "@/elements/Autocomplete";
import { FocusEventHandler, forwardRef, useState } from "react";
import { ModelloOlioDto, useGetModelliOlio } from "@/api";
import ModelloOlioDialog from "@/components/ModelloOlioDialog";

const ModelloOlioPicker = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      canCreateModelloOlio = false,
      placeholder,
    }: {
      value?: ModelloOlioDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: ModelloOlioDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      canCreateModelloOlio?: boolean;
      placeholder?: string;
    },
    ref
  ) => {
    const [createModelloOlioDialogOptions, setCreateModelloOlioDialogOptions] =
      useState<{ open: boolean; nome?: string; marca?: string }>({
        open: false,
      });

    const onCreateModelloOlioClose = (modelloOlio: ModelloOlioDto | null) => {
      onChange?.(null, modelloOlio);
      setCreateModelloOlioDialogOptions({ open: false });
    };

    function onNewOptionSelected(text: string) {
      const [marca, ...nome] = text.split(" ");
      setCreateModelloOlioDialogOptions({
        open: true,
        marca,
        nome: nome.join(" "),
      });
    }

    return (
      <>
        <AsyncAutocomplete<ModelloOlioDto>
          ref={ref}
          onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          fetchOptions={useGetModelliOlio}
          getOptionLabel={(modelloOlio) =>
            `${modelloOlio.marca} - ${modelloOlio.nome}`
          }
          canCreateNewOption={canCreateModelloOlio}
          value={value}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
        />
        {canCreateModelloOlio && (
          <ModelloOlioDialog
            open={createModelloOlioDialogOptions.open}
            onClose={onCreateModelloOlioClose}
            modelloOlio={{
              nome: createModelloOlioDialogOptions.nome,
              marca: createModelloOlioDialogOptions.marca,
            }}
          />
        )}
      </>
    );
  }
);

export default ModelloOlioPicker;
