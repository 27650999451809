import Box from "@/elements/Box";
import Progress from "@/elements/Progress";
import Typography from "@/elements/Typography";
import { proprietaMisurataIcons } from "@/utils/proprietaMisurataIcons";
import {
  getProprietaMisurataHelper,
  isDanger,
  isOld,
} from "@/utils/proprietaMisurataUtils";
import { Icon, SvgIcon } from "@mui/material";
import { ComponentProps } from "react";
import typography from "@/theme/base/typography";
import moment from "moment";
import { ProprietaMisurataDto, ProprietaMisurataValueDto } from "@/api";

function ProprietaMisurataBar({
  proprietaMisurata,
  value,
}: {
  proprietaMisurata: ProprietaMisurataDto;
  value: ProprietaMisurataValueDto | null;
}) {
  const { size } = typography;

  const type = getProprietaMisurataHelper(proprietaMisurata.type);
  const Icon_ = proprietaMisurataIcons[proprietaMisurata.type];

  if (!type || !Icon_) {
    return null;
  }

  const valueText =
    typeof value?.value === "number" ? type.format(value.value) : "-";
  const label = type.label;
  const perc =
    typeof value?.value === "number" ? type.getPercent(value.value) : undefined;

  const danger = isDanger(value);
  const oldValue = isOld(value);

  const color: ComponentProps<typeof Typography>["color"] = danger
    ? "error"
    : "success";

  return (
    <Box width="100%" mb={3}>
      <Box display="flex" alignItems="center" mb={0.5}>
        <Box
          width="1.25rem"
          height="1.25rem"
          color="grey-400"
          fontSize={size.lg}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={1}
          variant="contained"
        >
          <SvgIcon>{Icon_()}</SvgIcon>
        </Box>
        <Typography
          variant="caption"
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
        >
          {label}
        </Typography>
      </Box>

      <Box>
        <Box display="flex" alignItems="center" width="90%">
          <Typography
            variant="h4"
            fontWeight="bold"
            color={typeof value?.value === "number" ? color : "warning"}
          >
            {valueText}
          </Typography>
          {danger && (
            <Typography variant="h4" color={color} sx={{ ml: "auto" }}>
              <Icon sx={{ mt: 1 }}>warning</Icon>
            </Typography>
          )}
        </Box>
        <Box width="90%" mt={0.5}>
          <Progress
            value={value && typeof perc === "number" ? perc : 0}
            color={color}
          />
        </Box>
      </Box>

      {oldValue && (
        <Box>
          <Typography variant="caption" fontWeight="bold" color="warning">
            {value
              ? `Ultimo rilevamento: ${moment(value.timestamp).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}`
              : "Dato non rilevato"}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ProprietaMisurataBar;
