import { AlloggiamentoFiltroDto, CentraleDto } from "@/api";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import Table from "@/elements/Table";
import AlloggiamentiFiltroDialog from "./AlloggiamentiFiltroDialog";
import Button from "@/elements/Button";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { ReactNode, useMemo, useState } from "react";
import StoricoAlloggiamentoFiltroDialog from "@/components/CentraleDetailsPage/StoricoAlloggiamentoFiltroDialog";
import { useAuth } from "@/context/useAuth";
import { Icon, Tooltip } from "@mui/material";
import { ScadenzaFiltroWarningDays } from "@/utils/scadenzeUtils";
import WarningIcon from "@/assets/warning.png";
import ErrorIcon from "@/assets/error.png";
import { getFiltroTypeHelper } from "@/utils/filtroTypeUtils";

const columnHelper = createColumnHelper<AlloggiamentoFiltroDto>();

export default function AlloggiamentiFiltroCard({
  alloggiamentiFiltro,
  centrale,
}: {
  alloggiamentiFiltro: AlloggiamentoFiltroDto[];
  centrale: CentraleDto;
}) {
  const { hasPermission } = useAuth();

  const [alloggiamentiFiltroDialogOpen, setAlloggiamentiFiltroDialogOpen] =
    useState(false);
  const [storicoFiltriDialogOptions, setStoricoFiltriDialogOptions] = useState<{
    open: boolean;
    alloggiamento?: AlloggiamentoFiltroDto;
  }>({ open: false });

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor("nome", {
          header: "Alloggiamento",
          size: 100,
          cell: ({
            row: {
              original: { filtroType, nome },
            },
          }) => {
            const typeHelper = getFiltroTypeHelper(filtroType);
            return (
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <Icon sx={{ mr: 1 }}>{typeHelper?.icon}</Icon> {nome}
              </Typography>
            );
          },
        }),
        columnHelper.accessor(
          (af) =>
            af.filtroAttuale
              ? `${af.filtroAttuale.modelloFiltro?.marca} - ${af.filtroAttuale.modelloFiltro?.nome}`
              : null,
          {
            header: "Filtro",
            size: 100,
          }
        ),
        columnHelper.accessor(
          (af) =>
            af.filtroDaSostituireDopoIl
              ? moment(af.filtroDaSostituireDopoIl).format("L")
              : null,
          {
            header: "Scadenza",
            size: 100,
            cell: ({
              row: {
                original: { filtroDaSostituireDopoIl },
              },
              getValue,
            }) => {
              let icon: ReactNode | null = null;
              if (filtroDaSostituireDopoIl) {
                const days = moment(filtroDaSostituireDopoIl).diff(
                  moment(),
                  "days",
                  true
                );
                icon =
                  days <= 0 ? (
                    <img src={ErrorIcon} width={20} alt="Da sostituire" />
                  ) : days <= ScadenzaFiltroWarningDays ? (
                    <img src={WarningIcon} width={20} alt="In scadenza" />
                  ) : null;
              }

              return (
                <Box display="flex" gap={1} alignItems="center">
                  {getValue()}
                  {icon}
                </Box>
              );
            },
          }
        ),

        columnHelper.display({
          id: "buttons",
          header: "",
          maxSize: 100,
          cell: ({ row }) => {
            return (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  setStoricoFiltriDialogOptions({
                    open: true,
                    alloggiamento: row.original,
                  });
                }}
              >
                Storico
              </Button>
            );
          },
        }),
      ].filter((x) => x),
    []
  );

  return (
    <Card
      sx={{
        display: "flex",
        alignSelf: "flex-start",
        minWidth: {
          xs: "100%",
          md: 400,
        },
        maxWidth: "100%",
        flex: 1,
      }}
    >
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%" gap={1}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={1}
          >
            <Typography variant="h5" color="text" fontWeight="medium">
              Filtri
            </Typography>

            {hasPermission("CentraleUpdateAlloggiamentiFiltro") && (
              <Tooltip title="Modifica alloggiamenti filtro">
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setAlloggiamentiFiltroDialogOpen(true);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Table
            columns={columns}
            data={alloggiamentiFiltro}
            initialSort={[{ id: "nome", desc: false }]}
            paginated={false}
            noRowsText="Nessun alloggiamento filtro mappato"
            mt={1}
          />
        </Box>
      </Box>

      <AlloggiamentiFiltroDialog
        centrale={centrale}
        open={alloggiamentiFiltroDialogOpen}
        onClose={() => {
          setAlloggiamentiFiltroDialogOpen(false);
        }}
      />
      <StoricoAlloggiamentoFiltroDialog
        open={storicoFiltriDialogOptions.open}
        centrale={centrale}
        alloggiamentoFiltro={storicoFiltriDialogOptions.alloggiamento}
        onClose={() => {
          setStoricoFiltriDialogOptions({ open: false });
        }}
        canEdit={hasPermission("CentraleUpdateStoricoFiltri")}
      />
    </Card>
  );
}
