import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import { useForm, UseFormSetError } from "react-hook-form";
import { FormHelperText, InputAdornment, InputLabel } from "@mui/material";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import {
  getGetModelliFiltroQueryKey,
  ModelloFiltroCreateDto,
  ModelloFiltroDto,
  ModelloFiltroUpdateDto,
  useCreateModelloFiltro,
  useUpdateModelloFiltro,
} from "@/api";
import NumberInput from "@/elements/Input/NumberInput";

function ModelloFiltroDialog({
  open = false,
  onClose,
  modelloFiltro,
}: {
  open?: boolean;
  onClose?: (modelloFiltro: ModelloFiltroDto | null) => void;
  modelloFiltro?: Partial<ModelloFiltroDto> | null;
}) {
  const handleClose = () => {
    onClose?.(null);
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<ModelloFiltroDto>({
    defaultValues: modelloFiltro || {},
  });

  const { saveAsync, isLoading, error } = useMutator(modelloFiltro, setError);

  const queryClient = useQueryClient();

  const onSubmit = async (data: ModelloFiltroCreateDto) => {
    try {
      const result = await saveAsync(data);
      await queryClient.invalidateQueries({
        queryKey: getGetModelliFiltroQueryKey(),
      });
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {modelloFiltro?.id ? "Modifica modello filtro " : "Crea modello filtro"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-modello-filtro-marca">Marca</InputLabel>
        <TextField
          id="crea-modello-filtro-marca"
          autoFocus
          {...register("marca", {
            required: "Inserisci la marca",
            minLength: 1,
          })}
          error={!!errors.nome?.message}
          helperText={errors.nome?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="crea-modello-filtro-nome">Nome</InputLabel>
        <TextField
          id="crea-modello-filtro-nome"
          {...register("nome", {
            required: "Inserisci il nome",
            minLength: 1,
          })}
          error={!!errors.marca?.message}
          helperText={errors.marca?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="crea-modello-filtro-durata">
          Durata di vita
        </InputLabel>
        <NumberInput
          id="crea-modello-filtro-durata"
          {...register("durataDiVitaInMesi", {
            min: 1,
          })}
          error={!!errors.durataDiVitaInMesi?.message}
          fullWidth
          endAdornment={<InputAdornment position="end">mesi</InputAdornment>}
        />
        {error && <FormHelperText error>{error?.message}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {modelloFiltro?.id ? "Salva modello filtro" : "Crea modello filtro"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(ModelloFiltroDialog);

function useMutator(
  modelloFiltro: Partial<ModelloFiltroDto> | null | undefined,
  setError: UseFormSetError<Partial<ModelloFiltroDto>>
): {
  isLoading: boolean;
  error: AxiosError<unknown, any> | null;
  saveAsync: (data: Partial<ModelloFiltroDto>) => Promise<any>;
} {
  const isNew = !modelloFiltro?.id;

  const create = useCreateModelloFiltro({
    mutation: {
      onSuccess: () => {
        toast.success("Modello filtro creato!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const update = useUpdateModelloFiltro({
    mutation: {
      onSuccess: () => {
        toast.success("Modello filtro salvata!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const { isLoading, error } = isNew ? create : update;

  return {
    isLoading,
    error,
    saveAsync: async (data: Partial<ModelloFiltroDto>) => {
      return isNew
        ? create.mutateAsync({ data: data as ModelloFiltroCreateDto })
        : update.mutateAsync({
            id: modelloFiltro.id!,
            data: data as ModelloFiltroUpdateDto,
          });
    },
  };
}
