import { forwardRef } from "react";

// Custom styles for SoftBox
import SoftBoxRoot from "@/elements/Box/SoftBoxRoot";

import { BoxProps as MuiBoxProps } from "@mui/material/Box";

interface BoxProps extends MuiBoxProps {
  variant?: "contained" | "gradient";
  bgColor?:
    | "transparent"
    | "white"
    | "black"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "text"
    | "grey-100"
    | "grey-200"
    | "grey-300"
    | "grey-400"
    | "grey-500"
    | "grey-600"
    | "grey-700"
    | "grey-800"
    | "grey-900";
  color?:
    | "transparent"
    | "white"
    | "black"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "text"
    | "grey-100"
    | "grey-200"
    | "grey-300"
    | "grey-400"
    | "grey-500"
    | "grey-600"
    | "grey-700"
    | "grey-800"
    | "grey-900";
  opacity?: number;
  borderRadius?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "section" | "none";
  shadow?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "inset" | "none";
}

const Box = forwardRef<any, BoxProps>(
  (
    {
      variant = "contained",
      bgColor = "transparent",
      color = "dark",
      opacity = 1,
      borderRadius = "none",
      shadow = "none",
      ...rest
    },
    ref
  ) => (
    <SoftBoxRoot
      {...rest}
      ref={ref}
      //@ts-ignore
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
    />
  )
);

export default Box;
