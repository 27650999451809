import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import { useForm, UseFormSetError } from "react-hook-form";
import { InputLabel } from "@mui/material";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import {
  getGetModelliOlioQueryKey,
  ModelloOlioCreateDto,
  ModelloOlioDto,
  ModelloOlioUpdateDto,
  useCreateModelloOlio,
  useUpdateModelloOlio,
} from "@/api";

function ModelloOlioDialog({
  open = false,
  onClose,
  modelloOlio,
}: {
  open?: boolean;
  onClose?: (modelloOlio: ModelloOlioDto | null) => void;
  modelloOlio?: Partial<ModelloOlioDto> | null;
}) {
  const handleClose = () => {
    onClose?.(null);
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<ModelloOlioDto>({
    defaultValues: modelloOlio || {},
  });

  const { saveAsync, isLoading, error } = useMutator(modelloOlio, setError);

  const queryClient = useQueryClient();

  const onSubmit = async (data: ModelloOlioCreateDto) => {
    try {
      const result = await saveAsync(data);
      await queryClient.invalidateQueries({
        queryKey: getGetModelliOlioQueryKey(),
      });
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {modelloOlio?.id ? "Modifica modello olio " : "Crea modello olio"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-modello-olio-marca">Marca</InputLabel>
        <TextField
          id="crea-modello-olio-marca"
          autoFocus
          {...register("marca", {
            required: "Inserisci la marca",
            minLength: 1,
          })}
          error={!!errors.nome?.message}
          helperText={errors.nome?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="crea-modello-olio-nome">Nome</InputLabel>
        <TextField
          id="crea-modello-olio-nome"
          {...register("nome", {
            required: "Inserisci il nome",
            minLength: 1,
          })}
          error={!!errors.marca?.message}
          helperText={errors.marca?.message || null}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {modelloOlio?.id ? "Salva modello olio" : "Crea modello olio"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(ModelloOlioDialog);

function useMutator(
  modelloOlio: Partial<ModelloOlioDto> | null | undefined,
  setError: UseFormSetError<Partial<ModelloOlioDto>>
): {
  isLoading: boolean;
  error: AxiosError<unknown, any> | null;
  saveAsync: (data: Partial<ModelloOlioDto>) => Promise<any>;
} {
  const isNew = !modelloOlio?.id;

  const create = useCreateModelloOlio({
    mutation: {
      onSuccess: () => {
        toast.success("Modello olio creato!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const update = useUpdateModelloOlio({
    mutation: {
      onSuccess: () => {
        toast.success("Modello olio salvata!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const { isLoading, error } = isNew ? create : update;

  return {
    isLoading,
    error,
    saveAsync: async (data: Partial<ModelloOlioDto>) => {
      return isNew
        ? create.mutateAsync({ data: data as ModelloOlioCreateDto })
        : update.mutateAsync({
            id: modelloOlio.id!,
            data: data as ModelloOlioUpdateDto,
          });
    },
  };
}
