// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _filtroTypeUtilsData = {
  "Olio": {
    "Label": "Olio",
    "Icon": "water_drop"
  },
  "Aria": {
    "Label": "Aria",
    "Icon": "air"
  }
} as const