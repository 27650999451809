import colors from "@/theme/base/colors";
import { ChartData, ChartOptions } from "chart.js";

const { gradients, dark } = colors;

function configs(
  labels: string[],
  datasets: {
    label: string;
    backgroundColors?: string[];
    data: number[];
  },
  cutout = 60
): {
  data: ChartData<"doughnut">;
  options: ChartOptions<"doughnut">;
} {
  const backgroundColors = [];

  if (datasets.backgroundColors) {
    datasets.backgroundColors.forEach((color: any) => {
      if (gradients[color as keyof typeof gradients]) {
        if (color === "info") {
          backgroundColors.push(gradients.info.main);
        } else {
          backgroundColors.push(
            gradients[color as keyof typeof gradients].state
          );
        }
      } else {
        backgroundColors.push(dark.main);
      }
    });
  } else {
    backgroundColors.push(dark.main);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          //@ts-ignore
          cutout,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: backgroundColors,
          fill: false,
          data: datasets.data,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      hover: {
        mode: undefined,
      },
      events: [],
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        // y: {
        //   grid: {
        //     // drawBorder: false,
        //     display: false,
        //     drawOnChartArea: false,
        //     drawTicks: false,
        //   },
        //   ticks: {
        //     display: false,
        //   },
        // },
        // x: {
        //   grid: {
        //     // drawBorder: false,
        //     display: false,
        //     drawOnChartArea: false,
        //     drawTicks: false,
        //   },
        //   ticks: {
        //     display: false,
        //   },
        // },
      },

      // GAUGE
      circumference: 180,
      rotation: -90,
    },
  };
}

export default configs;
