import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { _permissionsDescriptionMap } from "@/utils/rolesAndPermissionsData/permissionsDescriptionMap";
import { _permissionsGroupMap } from "@/utils/rolesAndPermissionsData/permissionsGroupMap";
import { _rolePermissionsMap } from "@/utils/rolesAndPermissionsData/rolePermissionsMap";
import {
  Checkbox,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { keys, orderBy, uniq } from "lodash";
import { Fragment } from "react";

const gruppi = orderBy(uniq(Object.values(_permissionsGroupMap)));
const ruoli = orderBy(
  uniq(keys(_rolePermissionsMap))
) as (keyof typeof _rolePermissionsMap)[];

const gruppiDaNascondere = ["SuperAdmin"];
const permessiDaNascondere = ["AziendaCreate", "CentraleCreate"];

function RuoliPermessiPage() {
  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Ruoli / Permessi
        </Typography>
      </Box>
      <TableContainer
        sx={{
          mt: 2,
          minWidth: 0,
          width: "auto",
        }}
      >
        <MuiTable>
          <Box component="thead">
            <TableRow>
              <TableCell>Permesso</TableCell>

              {ruoli.map((r) => {
                return <TableCell key={r}>{r}</TableCell>;
              })}
            </TableRow>
          </Box>

          <TableBody>
            {gruppi
              .filter((g) => !gruppiDaNascondere.includes(g))
              .map((g) => {
                const permessi = orderBy(
                  Object.entries(_permissionsGroupMap)
                    .filter(([permesso, gruop]) => gruop === g)
                    .map(([permesso, gruop]) => permesso)
                ) as (keyof typeof _permissionsGroupMap)[];
                return (
                  <Fragment key={g}>
                    <TableRow>
                      <TableCell colSpan={1 + ruoli.length}>
                        <strong>{g}</strong>
                      </TableCell>
                    </TableRow>

                    {permessi
                      .filter((p) => !permessiDaNascondere.includes(p))
                      .map((p) => {
                        return (
                          <TableRow key={p}>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <strong>{p}</strong>
                                <Typography variant="subtitle2">
                                  {_permissionsDescriptionMap[p]}
                                </Typography>
                              </Box>
                            </TableCell>

                            {ruoli.map((r) => {
                              const ruoliAbilitati = _rolePermissionsMap[
                                r
                              ] as readonly string[];
                              const hasPermission =
                                r === "Admin" || ruoliAbilitati.includes(p);
                              return (
                                <TableCell key={r}>
                                  <Checkbox readOnly checked={hasPermission} />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </Fragment>
                );
              })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Box>
  );
}

export default RuoliPermessiPage;
