import {
  AziendaDto,
  getGetAziendeQueryKey,
  useDeleteAzienda,
  useGetAziende,
} from "@/api";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Table from "@/elements/Table";
import Typography from "@/elements/Typography";
import { useConfirmationDialog } from "@/utils/useConfirmationDialog";
import { Icon, TextField, Tooltip } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AziendaDialog from "../AziendaDialog";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import SearchIcon from "@mui/icons-material/Search";

const columnHelper = createColumnHelper<AziendaDto>();

function AziendePage() {
  const { hasPermission } = useAuth();
  const { isLoading, error, data } = useGetAziende();
  const navigate = useNavigate();

  const [aziendaDialogState, setAziendaDialogState] = useState({
    open: false,
    azienda: null as AziendaDto | null,
  });

  const { askConfirmation, confirmationDialog } = useConfirmationDialog();

  const { mutateAsync } = useDeleteAzienda({
    mutation: {
      onSuccess: () => {
        toast.success("Azienda eliminata!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const deleteAzienda = useCallback(
    async (aziendaId: number) => {
      try {
        await mutateAsync({ id: aziendaId });
        queryClient.invalidateQueries({ queryKey: getGetAziendeQueryKey() });
      } catch (err) {}
    },
    [mutateAsync, queryClient]
  );

  const [filtro, setFiltro] = useState("");

  const aziende = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    const lowerCaseFiltro = filtro.toLowerCase().replace(/\./g, "");
    return data.data.filter(
      (a) =>
        a.nome.toLowerCase().replace(/\./g, "").indexOf(lowerCaseFiltro) >= 0
    );
  }, [data?.data, filtro]);

  const columns = useMemo(() => {
    const canDelete = hasPermission("AziendaDelete");
    const canEdit = false; // hasPermission("AziendaEdit");

    const columns = [
      columnHelper.accessor("nome", {
        header: "Nome",
        size: undefined,
      }),
      // columnHelper.display({
      //   header: "",
      //   size: undefined,
      //   cell: ({ row }) => {
      //     return (

      //     );
      //   },
      // }),
      // (canDelete || canEdit) &&
      columnHelper.display({
        id: "buttons",
        header: "",
        size: canDelete && canEdit ? 250 : 125,
        maxSize: canDelete && canEdit ? 250 : 125,
        cell: ({ row }) => {
          return (
            <Box display="flex" gap={1}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  navigate(`/aziende/${row.original.id}`);
                }}
              >
                Dettagli
              </Button>
              {canEdit && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<Icon>edit</Icon>}
                  onClick={() => {
                    setAziendaDialogState({
                      open: true,
                      azienda: row.original,
                    });
                  }}
                >
                  Modifica
                </Button>
              )}
              {canDelete &&
                (row.original.idElaboranet ? (
                  <Tooltip title="Per poter eliminare questa azienda devi prima eliminarla in Elaboranet">
                    <div>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        startIcon={<Icon>delete_outlined</Icon>}
                        disabled
                      >
                        Elimina
                      </Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<Icon>delete_outlined</Icon>}
                    onClick={() => {
                      askConfirmation({
                        title: "Elimina azienda",
                        message: `Sei sicuro di voler eliminare l'azienda ${row.original.nome}?`,
                        okColor: "error",
                        onConfirm: () => {
                          return deleteAzienda(row.original.id!);
                        },
                      });
                    }}
                  >
                    Elimina
                  </Button>
                ))}
            </Box>
          );
        },
      }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [setAziendaDialogState, askConfirmation, deleteAzienda, hasPermission]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Aziende
        </Typography>
        <Box display="flex" gap={1} pt={1} flexWrap="wrap">
          <TextField
            placeholder="Filtra per nome"
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
            InputProps={{ startAdornment: <SearchIcon /> }}
          />
          {/* {hasPermission("AziendaCreate") && (
            <Button
              size="small"
              color="light"
              onClick={() => {
                setAziendaDialogState({
                  open: true,
                  azienda: null,
                });
              }}
            >
              Aggiungi azienda
            </Button>
          )} */}
        </Box>
      </Box>

      <Table
        columns={columns}
        data={aziende}
        sortable={true}
        initialSort={[{ id: "nome", desc: false }]}
      />

      <AziendaDialog
        open={aziendaDialogState.open}
        azienda={aziendaDialogState.azienda}
        onClose={() => {
          setAziendaDialogState((x) => ({
            ...x,
            open: false,
          }));
        }}
      />
      {confirmationDialog}
    </Box>
  );
}

export default AziendePage;
