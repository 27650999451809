import {
  AziendaDto,
  CentraleDto,
  DispositivoDto,
  RepartoDto,
  StabilimentoDto,
} from "@/api";
import { useGetDispositivi } from "@/api/dispositivi/dispositivi";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Typography from "@/elements/Typography";
import { useMemo, useState } from "react";
import AziendaPicker from "../AziendaPicker";
import CentralePicker from "../CentralePicker";
import DispositivoCard from "../DispositivoCard";
import DispositivoDialog from "../DispositivoDialog";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import RepartoPicker from "../RepartoPicker";
import StabilimentoPicker from "../StabilimentoPicker";
import { useNavigate } from "react-router-dom";

type Filters = {
  azienda: AziendaDto | null;
  stabilimento: StabilimentoDto | null;
  reparto: RepartoDto | null;
  centrale: CentraleDto | null;
};

function DispositiviPage() {
  const navigate = useNavigate();
  const { user, hasPermission } = useAuth();
  const { isLoading, error, data } = useGetDispositivi();

  const [filters, setFilters] = useState<Filters>({
    azienda: null,
    stabilimento: null,
    reparto: null,
    centrale: null,
  });

  const changeFilter =
    (key: keyof typeof filters, resetKeys?: (keyof typeof filters)[]) =>
    (event: any, value: (typeof filters)[typeof key] | undefined) => {
      setFilters((f) => {
        const newFilters = {
          ...f,
          [key]: value || null,
        };
        resetKeys?.forEach((k) => {
          newFilters[k] = null;
        });
        return newFilters;
      });
    };

  const [openDispositiviDialog, setOpenDispositiviDialog] = useState(false);

  const dispositivi = useMemo(() => {
    return (data?.data || []).filter((d) => {
      return matchFilter(d, filters);
    });
  }, [data?.data, filters]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Macchinari/Sensori
        </Typography>

        <Box display="flex" gap={1} pt={1} flexWrap="wrap">
          {!user?.isEsterno && (
            <AziendaPicker
              value={filters.azienda}
              onChange={changeFilter("azienda", [
                "stabilimento",
                "reparto",
                "centrale",
              ])}
              placeholder="Azienda"
            />
          )}
          <StabilimentoPicker
            aziendaId={
              user?.isEsterno ? user?.azienda?.id : filters.azienda?.id
            }
            value={filters.stabilimento}
            onChange={changeFilter("stabilimento", ["reparto", "centrale"])}
            placeholder="Stabilimento"
          />
          <RepartoPicker
            stabilimentoId={filters.stabilimento?.id}
            value={filters.reparto}
            onChange={changeFilter("reparto", ["centrale"])}
            placeholder="Reparto"
          />
          <CentralePicker
            repartoId={filters.reparto?.id}
            value={filters.centrale}
            onChange={changeFilter("centrale")}
            placeholder="Centrale"
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" sx={{ pt: 0.5 }}>
        {hasPermission("DispositivoCreate") && (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setOpenDispositiviDialog(true);
            }}
          >
            Aggiungi dispositivo
          </Button>
        )}
      </Box>
      <Box
        mt={2}
        mb={0}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
      >
        {dispositivi.map((dispositivo) => {
          return (
            <DispositivoCard
              key={dispositivo.id}
              dispositivo={dispositivo}
              proprietaMisurate={dispositivo.proprietaMisurate || []}
              showPosizione
            />
          );
        })}
        {dispositivi.length === 0 && (
          <Typography variant="body2">
            Nessun dispositivo{" "}
            {hasFilters(filters) ? " per i filtri impostati" : null}
          </Typography>
        )}
      </Box>

      <DispositivoDialog
        open={openDispositiviDialog}
        onClose={(d) => {
          setOpenDispositiviDialog(false);
          if (d) {
            navigate(`/dispositivo/${d.id}`);
          }
        }}
      />
    </Box>
  );
}

export default DispositiviPage;

function matchFilter(d: DispositivoDto, filters: Filters): unknown {
  if (filters.azienda && d.posizione?.azienda?.id !== filters.azienda.id) {
    return false;
  }
  if (
    filters.stabilimento &&
    d.posizione?.stabilimento?.id !== filters.stabilimento.id
  ) {
    return false;
  }
  if (filters.reparto && d.posizione?.reparto?.id !== filters.reparto.id) {
    return false;
  }
  if (filters.centrale && d.posizione?.centrale?.id !== filters.centrale.id) {
    return false;
  }
  return true;
}

function hasFilters(filters: Filters): boolean {
  return Boolean(
    filters.azienda ||
      filters.stabilimento ||
      filters.reparto ||
      filters.centrale
  );
}
