import {
  AnalisiDto,
  CentraleDto,
  getGetCentraleQueryKey,
  useUpdateAnalisi,
} from "@/api";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import { FormHelperText, InputLabel } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DateTimeField } from "@mui/x-date-pickers";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { SmallLoading } from "../Loading";

function AnalisiDialog({
  open = false,
  onClose,
  centrale,
  analisi,
}: {
  open?: boolean;
  onClose?: () => void;
  centrale: CentraleDto;
  analisi?: Partial<AnalisiDto>;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    resetField,
    control,
  } = useForm<AnalisiDto>({
    defaultValues: analisi,
  });

  const { mutateAsync, isLoading } = useUpdateAnalisi();

  const queryClient = useQueryClient();
  const onSubmit = async (data: AnalisiDto) => {
    if (!analisi) {
      return;
    }
    try {
      await mutateAsync({
        id: centrale.id,
        data: [
          {
            id: analisi.id,
            changeType: "updated",
            entity: data,
          },
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Modifica analisi</DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-bonifica-data">Valid</InputLabel>
        <Controller
          name="data"
          control={control}
          rules={{ required: "Specifica la data" }}
          render={({ field, fieldState: { error } }) => {
            const { value, name, onBlur, onChange, ref } = field;
            const m =
              typeof value === "string"
                ? moment(value)
                : moment.isMoment(value)
                ? value
                : null;
            return (
              <>
                <DateTimeField
                  ref={ref}
                  format="DD/MM/YYYY"
                  name={name}
                  onBlur={onBlur}
                  onChange={(value) => onChange(value?.format("YYYY-MM-DD"))}
                  value={m}
                  fullWidth
                  disabled
                />
                {error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            );
          }}
        />

        <InputLabel htmlFor="crea-bonifica-scadenza">Scadenza</InputLabel>
        <Controller
          name="validaFinoA"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const { value, name, onBlur, onChange, ref } = field;
            const m =
              typeof value === "string"
                ? moment(value)
                : moment.isMoment(value)
                ? value
                : null;
            return (
              <>
                <DateTimeField
                  ref={ref}
                  format="DD/MM/YYYY"
                  name={name}
                  onBlur={onBlur}
                  onChange={(value) =>
                    onChange(value?.startOf("day")?.format("YYYY-MM-DD"))
                  }
                  value={m}
                  fullWidth
                />
                {error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salva analisi
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(AnalisiDialog);
