import {
  DispositivoDto,
  RegistroDto,
  useGetRegistriDispositivo,
  useUpdateRegistriDispositivo,
} from "@/api";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DispositivoMapper } from "./dispositivi";
import { isNil, cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { SmallLoading } from "../Loading";

function RegistriDispositivoDialog({
  open = false,
  onClose,
  dispositivo,
}: {
  open?: boolean;
  onClose?: () => void;
  dispositivo?: DispositivoDto;
}) {
  const { hasPermission } = useAuth();
  const mapper = dispositivo?.modello
    ? DispositivoMapper[dispositivo.modello]
    : undefined;

  const { data } = useGetRegistriDispositivo(dispositivo?.id ?? 0, {
    query: { enabled: Boolean(open && dispositivo?.id) },
  });

  const {
    control,
    reset,
    resetField,
    handleSubmit,
    formState: { errors },
    register,
    setError,
    clearErrors,
  } = useForm<any>({});
  const [initialData, setInitialData] = useState<any>({});

  const { mutateAsync, isLoading } = useUpdateRegistriDispositivo();

  useEffect(() => {
    if (!data) {
      return;
    }

    if (mapper) {
      const formData = mapper.registriToForm(data.data);
      reset(formData);
      setInitialData(cloneDeep(formData));
    }
  }, [data, reset, mapper]);

  const handleClose = () => {
    onClose?.();
  };

  async function onSubmit(form: any) {
    if (!dispositivo || !mapper || !data?.data) {
      return;
    }

    clearErrors();

    const newValues = mapper.formToRegistri(form);
    const differences = [];

    for (const newValue of newValues) {
      if (
        !isNil(newValue) &&
        initialData[newValue.registro] !== form[newValue.registro]
      ) {
        differences.push(newValue);
      }
    }

    if (differences.length > 0) {
      try {
        const response = await mutateAsync({
          id: dispositivo.id,
          data: differences,
        });

        const registriUpdated: RegistroDto[] = (
          response?.data?.filter(
            (r) => r.updated && r.value !== null && r.time !== null
          ) ?? []
        ).map((r) => ({
          registro: r.registro,
          value: r.value!,
          time: r.time!,
        }));
        for (const r of registriUpdated) {
          const mapped = mapper.registriToForm([r]);
          resetField(r.registro, { defaultValue: mapped[r.registro] });
        }

        const registriNotUpdated =
          response?.data?.filter((r) => !r.updated) ?? [];
        if (registriNotUpdated.length > 0) {
          if (registriNotUpdated.length === differences.length) {
            toast.warn(
              "I cambiamenti sono stati inviati al PLC, ma non è stata ricevuta alcuna risposta"
            );
          } else {
            toast.warn(
              "I cambiamenti sono stati inviati al PLC, ma alcuni registri non sono stati aggiornati"
            );
          }

          for (const r of registriNotUpdated) {
            setError(r.registro, { message: "Conferma dal PLC non ricevuta" });
          }
        } else {
          toast.success("Registri salvati con successo!");
          handleClose();
        }
      } catch (e) {
        toast.error("Errore durante il salvataggio dei registri");
      }
    } else {
      handleClose();
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Registri dispositivo</DialogTitle>
      <DialogContent>
        {mapper?.Component ? (
          <mapper.Component
            control={control}
            errors={errors}
            register={register}
            readOnly={isLoading || !hasPermission("DispositivoRegistriWrite")}
          />
        ) : (
          <Box justifyContent="center" alignItems="center" padding={2}>
            <Typography>
              Modello del dispositivo "{dispositivo?.modello}" sconosciuto.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        {hasPermission("DispositivoRegistriWrite") && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Salva
          </Button>
        )}
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(RegistriDispositivoDialog);
