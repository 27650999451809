import {
  DispositivoCreateDto,
  DispositivoDto,
  DispositivoType,
  useCreaDispositivo,
} from "@/api";
import Button from "@/elements/Button";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import {
  dispositivoTypes,
  getDispositivoTypeHelper,
} from "@/utils/dispositivoUtils";
import { modelliDispositiviByType } from "@/utils/modelliDispositivi";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  Autocomplete,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { AxiosError } from "axios";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { toast } from "react-toastify";
import { SmallLoading } from "../Loading";

function DispositivoDialog({
  open = false,
  onClose,
  dispositivo,
}: {
  open?: boolean;
  onClose?: (dispositivo: DispositivoDto | null) => void;
  dispositivo?: DispositivoDto;
}) {
  const handleClose = () => {
    onClose?.(null);
  };

  const {
    control,
    resetField,
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<DispositivoDto>({
    defaultValues: dispositivo || {},
  });

  const { saveAsync, isLoading, error } = useMutator(
    dispositivo,
    setError as any
  );

  const onSubmit = async (data: DispositivoCreateDto) => {
    try {
      const result = await saveAsync(data);
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  const tipo = watch("type");
  const modelli = useMemo(() => {
    if (!tipo) {
      return null;
    }
    resetField("modello");
    const modelli = modelliDispositiviByType[tipo];
    return orderBy(modelli);
  }, [tipo, resetField]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {dispositivo?.id ? "Modifica dispositivo " : "Crea dispositivo"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="dispositivo-matricola">Matricola</InputLabel>
        <TextField
          id="dispositivo-matricola"
          autoFocus
          {...register("matricola", {
            required: "Inserisci la matricola del dispositivo",
            minLength: 1,
          })}
          error={!!errors.matricola?.message}
          helperText={errors.matricola?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="dispositivo-type">Tipo</InputLabel>
        <Controller
          control={control}
          name="type"
          rules={{ required: "Inserisci il tipo di dispositivo" }}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <Select
                  {...field}
                  value={field.value ?? ""}
                  size="small"
                  sx={{ pt: 1 }}
                  id="dispositivo-type"
                >
                  {dispositivoTypes
                    .filter((t) => t !== DispositivoType.unknown)
                    .map((type) => {
                      return (
                        <MenuItem key={type} value={type}>
                          {getDispositivoTypeHelper(type)?.label}
                        </MenuItem>
                      );
                    })}
                </Select>
                {error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            );
          }}
        />

        {modelli && (
          <>
            <InputLabel htmlFor="dispositivo-modello">Modello</InputLabel>
            <Controller
              control={control}
              name="modello"
              rules={{ required: "Inserisci il tipo di dispositivo" }}
              render={({ field, fieldState: { error } }) => {
                console.log("fieldvalue", field.value);
                return (
                  <>
                    <Autocomplete
                      onChange={(e, v) => {
                        field.onChange(v);
                      }}
                      value={
                        field.value &&
                        modelli.includes(field.value as unknown as any)
                          ? field.value
                          : null
                      }
                      size="small"
                      options={modelli}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          sx={{ pt: 1 }}
                          id="dispositivo-modello"
                        />
                      )}
                    />

                    {error && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </>
        )}

        {error && typeof error.response?.data === "string" && (
          <FormHelperText error>{error.response?.data}</FormHelperText>
        )}
      </DialogContent>

      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {dispositivo?.id ? "Salva dispositivo" : "Crea dispositivo"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(DispositivoDialog);

function useMutator(
  dispositivo: Partial<DispositivoDto> | null | undefined,
  setError: UseFormSetError<Partial<DispositivoDto>>
): {
  isLoading: boolean;
  error: AxiosError<unknown, any> | null;
  saveAsync: (data: Partial<DispositivoDto>) => Promise<any>;
} {
  const isNew = !dispositivo?.id;
  // const queryClient = useQueryClient();

  const create = useCreaDispositivo({
    mutation: {
      onSuccess: () => {
        toast.success("Dispositivo creato!");
      },
      onError: addServerErrorsFactory(setError, (e: any) => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  //   const update = useUpdate({
  //     mutation: {
  //       onSuccess: () => {
  //         toast.success("Centrale salvata!");
  //       },
  //       onError: addServerErrorsFactory(setError, () => {
  //         toast.error("Errore nella richiesta");
  //       }),
  //     },
  //   });

  //const { isLoading, error } = isNew ? create : update;
  const { isLoading, error } = create;

  return {
    isLoading,
    error,
    saveAsync: async (data: Partial<DispositivoDto>) => {
      if (!isNew) {
        toast.error("Modifica del dispositivo non implementata");
        return;
      }

      const result = await create.mutateAsync({
        data: data as DispositivoCreateDto,
      });

      //   if (!isNew) {
      //     await queryClient.invalidateQueries(
      //       getGetCentraleQueryKey(dispositivo.id!)
      //     );
      //   }
      return result;
    },
  };
}
