import React, { ReactNode, useRef, useState } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getDownloadFileQueryKey, UploadedFileDto, useUploadFile } from "@/api";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import colors from "@/theme/base/colors";

export default function FileField({
  value,
  onChange,
  downloadUrl,
}: {
  value?: UploadedFileDto | null;
  onChange: (f: UploadedFileDto | null) => void;
  downloadUrl?: string;
}) {
  const { mutateAsync } = useUploadFile();

  const hasFile = !!value;

  const [uploadState, setUploadState] = useState<
    null | "uploading" | "success" | "error"
  >(null);
  const [uploadedFileId, setUploadedFileId] = useState<number | null>(null);

  const uploadFile = async (file: File) => {
    setUploadState("uploading");

    try {
      const result = await mutateAsync({
        data: { file },
      });

      onChange(result.data);
      setUploadedFileId(result.data.id);
      setUploadState("success");
    } catch (e: any) {
      handleUploadError(e.status);
    }
  };

  function handleUploadError(status?: number | undefined) {
    setUploadState("error");
    const msg =
      status === 413
        ? "Errore: il file è troppo grande"
        : "Errore durante il caricamento del file";
    toast.error(msg);
  }

  return (
    <Box
      style={{
        borderRadius: 8,
        border: `1px solid ${colors.inputColors.borderColor.main}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FilePicker
        style={{
          display: "flex",
          width: "100%",
        }}
        content={(openFilePicker) => {
          return (
            <div
              style={{
                flex: 1,
                display: "flex",
              }}
            >
              {hasFile ? (
                uploadState === "uploading" ? (
                  <UploadingFile />
                ) : (
                  <FileDownloadAndActions
                    downloadUrl={
                      uploadedFileId
                        ? getDownloadFileQueryKey(uploadedFileId)[0]
                        : downloadUrl
                    }
                    fileObj={value}
                    openFilePicker={openFilePicker}
                    onRemoveFile={() => {
                      onChange(null);
                    }}
                  />
                )
              ) : uploadState === "uploading" ? (
                <UploadingFile />
              ) : (
                <SelectFile openFilePicker={openFilePicker} />
              )}
            </div>
          );
        }}
        onFiles={(files) => {
          uploadFile(files[0]).then();
        }}
      />
    </Box>
  );
}

function UploadingFile() {
  return (
    <>
      <CircularProgress
        style={{
          margin: 12,
        }}
        size={24}
      />

      <div
        style={{
          flex: 1,
          alignSelf: "center",
        }}
      >
        Upload in corso ...
      </div>
    </>
  );
}

function FileDownloadAndActions({
  fileObj,
  openFilePicker,
  onRemoveFile,
  downloadUrl,
}: {
  fileObj: UploadedFileDto;
  openFilePicker: () => void;
  onRemoveFile: () => void;
  downloadUrl?: string;
}) {
  return (
    <>
      <a
        style={{
          display: "flex",
          flex: 1,
          color: "inherit",
        }}
        href={downloadUrl}
        download={fileObj.fileName}
      >
        <Tooltip title="Scarica file">
          <IconButton
            style={{
              marginRight: 6,
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>

        <div
          style={{
            flex: 1,
            alignSelf: "center",
            wordBreak: "break-all",
          }}
        >
          {fileObj.fileName}
        </div>
      </a>

      <div>
        <Tooltip title="Sostituisci file">
          <IconButton
            onClick={() => {
              openFilePicker();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Elimina file">
          <IconButton onClick={onRemoveFile}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
}

function SelectFile({ openFilePicker }: { openFilePicker: () => void }) {
  return (
    <>
      <Tooltip title="Carica file">
        <IconButton
          style={{
            marginRight: 6,
          }}
          onClick={() => {
            openFilePicker();
          }}
        >
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>

      <div
        style={{
          flex: 1,
          alignSelf: "center",
        }}
      >
        Carica file
      </div>
    </>
  );
}

function FilePicker({
  style,
  content,
  onFiles,
}: {
  style: any;
  content: (openFilePicker: () => void) => ReactNode;
  onFiles: (files: FileList) => void;
}) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  function openFilePicker() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  function handleFileFromFileInput(e: any) {
    if (fileInputRef.current!.files) {
      onFiles(fileInputRef.current!.files);
    }
    fileInputRef.current!.value = ""; // reset the input field so if you removed it you can re-add the same file
  }

  function handleFileDrop(e: any) {
    e.stopPropagation();
    e.preventDefault();

    const dt = e.dataTransfer;
    const files = dt.files;

    onFiles(files);
  }

  function handleDrag(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleFileDrop}
      style={style}
    >
      {content ? content(openFilePicker) : null}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileFromFileInput}
      />
    </div>
  );
}
