/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  UserInternoDto,
  GetUsersParams,
  UserDto,
  UserInternoCreateDto,
  UserEsternoDto,
  UserEsternoCreateDto,
  UserInternoUpdateDto,
  UserEsternoUpdateDto,
  ProfiloUpdateRequestDto,
  UpdatePasswordRequestDto,
} from ".././models";

export const getUsers = (
  params?: GetUsersParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserInternoDto[]>> => {
  return axios.get(`/api/users`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetUsersQueryKey = (params?: GetUsersParams) =>
  [`/api/users`, ...(params ? [params] : [])] as const;

export const getGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = AxiosError<unknown>
>(
  params?: GetUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsers>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
    signal,
  }) => getUsers(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = AxiosError<unknown>;

export const useGetUsers = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = AxiosError<unknown>
>(
  params?: GetUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsers>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createUser = (
  userInternoCreateDto: UserInternoCreateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserDto>> => {
  return axios.post(`/api/users`, userInternoCreateDto, options);
};

export const getCreateUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: UserInternoCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: UserInternoCreateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUser>>,
    { data: UserInternoCreateDto }
  > = (props) => {
    const { data } = props ?? {};

    return createUser(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationBody = UserInternoCreateDto;
export type CreateUserMutationError = AxiosError<unknown>;

export const useCreateUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: UserInternoCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getClienti = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserEsternoDto[]>> => {
  return axios.get(`/api/users/clienti`, options);
};

export const getGetClientiQueryKey = () => [`/api/users/clienti`] as const;

export const getGetClientiQueryOptions = <
  TData = Awaited<ReturnType<typeof getClienti>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getClienti>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<Awaited<ReturnType<typeof getClienti>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClientiQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClienti>>> = ({
    signal,
  }) => getClienti({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetClientiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClienti>>
>;
export type GetClientiQueryError = AxiosError<unknown>;

export const useGetClienti = <
  TData = Awaited<ReturnType<typeof getClienti>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getClienti>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetClientiQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createCliente = (
  userEsternoCreateDto: UserEsternoCreateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserDto>> => {
  return axios.post(`/api/users/clienti`, userEsternoCreateDto, options);
};

export const getCreateClienteMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCliente>>,
    TError,
    { data: UserEsternoCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCliente>>,
  TError,
  { data: UserEsternoCreateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCliente>>,
    { data: UserEsternoCreateDto }
  > = (props) => {
    const { data } = props ?? {};

    return createCliente(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateClienteMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCliente>>
>;
export type CreateClienteMutationBody = UserEsternoCreateDto;
export type CreateClienteMutationError = AxiosError<unknown>;

export const useCreateCliente = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCliente>>,
    TError,
    { data: UserEsternoCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateClienteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateUser = (
  id: number,
  userInternoUpdateDto: UserInternoUpdateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserDto>> => {
  return axios.put(`/api/users/${id}`, userInternoUpdateDto, options);
};

export const getUpdateUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: number; data: UserInternoUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { id: number; data: UserInternoUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { id: number; data: UserInternoUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateUser(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUser>>
>;
export type UpdateUserMutationBody = UserInternoUpdateDto;
export type UpdateUserMutationError = AxiosError<unknown>;

export const useUpdateUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: number; data: UserInternoUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteUser = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/users/${id}`, options);
};

export const getDeleteUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUser>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUser(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUser>>
>;

export type DeleteUserMutationError = AxiosError<unknown>;

export const useDeleteUser = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateCliente = (
  id: number,
  userEsternoUpdateDto: UserEsternoUpdateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserDto>> => {
  return axios.put(`/api/users/clienti/${id}`, userEsternoUpdateDto, options);
};

export const getUpdateClienteMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCliente>>,
    TError,
    { id: number; data: UserEsternoUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCliente>>,
  TError,
  { id: number; data: UserEsternoUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCliente>>,
    { id: number; data: UserEsternoUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateCliente(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateClienteMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCliente>>
>;
export type UpdateClienteMutationBody = UserEsternoUpdateDto;
export type UpdateClienteMutationError = AxiosError<unknown>;

export const useUpdateCliente = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCliente>>,
    TError,
    { id: number; data: UserEsternoUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateClienteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCliente = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/users/clienti/${id}`, options);
};

export const getDeleteClienteMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCliente>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCliente>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCliente>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCliente(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteClienteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCliente>>
>;

export type DeleteClienteMutationError = AxiosError<unknown>;

export const useDeleteCliente = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCliente>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getDeleteClienteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateProfilo = (
  profiloUpdateRequestDto: ProfiloUpdateRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserDto>> => {
  return axios.put(`/api/users/me`, profiloUpdateRequestDto, options);
};

export const getUpdateProfiloMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProfilo>>,
    TError,
    { data: ProfiloUpdateRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateProfilo>>,
  TError,
  { data: ProfiloUpdateRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateProfilo>>,
    { data: ProfiloUpdateRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateProfilo(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateProfiloMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateProfilo>>
>;
export type UpdateProfiloMutationBody = ProfiloUpdateRequestDto;
export type UpdateProfiloMutationError = AxiosError<unknown>;

export const useUpdateProfilo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProfilo>>,
    TError,
    { data: ProfiloUpdateRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateProfiloMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePassword = (
  updatePasswordRequestDto: UpdatePasswordRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/users/me/password`, updatePasswordRequestDto, options);
};

export const getUpdatePasswordMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePassword>>,
    TError,
    { data: UpdatePasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePassword>>,
  TError,
  { data: UpdatePasswordRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePassword>>,
    { data: UpdatePasswordRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return updatePassword(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePassword>>
>;
export type UpdatePasswordMutationBody = UpdatePasswordRequestDto;
export type UpdatePasswordMutationError = AxiosError<unknown>;

export const useUpdatePassword = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePassword>>,
    TError,
    { data: UpdatePasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdatePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetUsers = () =>
  //@ts-ignore
  useGetUsers(...([] as any[]));

export const __$$useCreateUser = () =>
  //@ts-ignore
  useCreateUser(...([] as any[]));

export const __$$useGetClienti = () =>
  //@ts-ignore
  useGetClienti(...([] as any[]));

export const __$$useCreateCliente = () =>
  //@ts-ignore
  useCreateCliente(...([] as any[]));

export const __$$useUpdateUser = () =>
  //@ts-ignore
  useUpdateUser(...([] as any[]));

export const __$$useDeleteUser = () =>
  //@ts-ignore
  useDeleteUser(...([] as any[]));

export const __$$useUpdateCliente = () =>
  //@ts-ignore
  useUpdateCliente(...([] as any[]));

export const __$$useDeleteCliente = () =>
  //@ts-ignore
  useDeleteCliente(...([] as any[]));

export const __$$useUpdateProfilo = () =>
  //@ts-ignore
  useUpdateProfilo(...([] as any[]));

export const __$$useUpdatePassword = () =>
  //@ts-ignore
  useUpdatePassword(...([] as any[]));
