import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@/elements/Table";
import { useAuth } from "@/context/useAuth";
import Button from "@/elements/Button";
import { Chip, Icon } from "@mui/material";
import ClienteDialog from "../ClienteDialog";
import { useCallback, useMemo, useState } from "react";
import { Role, getRoleColor } from "@/utils/rolesAndPermissions";
import { useConfirmationDialog } from "@/utils/useConfirmationDialog";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import {
  UserEsternoDto,
  getGetClientiQueryKey,
  getGetUsersQueryKey,
  useDeleteCliente,
  useGetClienti,
} from "@/api";

const columnHelper = createColumnHelper<UserEsternoDto>();

function ClientePage() {
  const { hasPermission } = useAuth();
  const { isLoading, error, data } = useGetClienti();

  const [clientiDialogState, setClientiDialogState] = useState({
    open: false,
    cliente: null as UserEsternoDto | null,
  });

  const { askConfirmation, confirmationDialog } = useConfirmationDialog();

  const { mutateAsync } = useDeleteCliente({
    mutation: {
      onSuccess: () => {
        toast.success("Cliente eliminato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const deleteCliente = useCallback(
    async (userId: number) => {
      try {
        await mutateAsync({ id: userId });
        queryClient.invalidateQueries({ queryKey: getGetUsersQueryKey() });
        queryClient.invalidateQueries({ queryKey: getGetClientiQueryKey() });
      } catch (err) {}
    },
    [mutateAsync, queryClient]
  );

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor("name", {
        header: "Nome",
        size: undefined,
      }),
      columnHelper.accessor("lastName", {
        header: "Cognome",
        size: undefined,
      }),
      columnHelper.accessor("email", {
        header: "Email",
        size: undefined,
      }),
      columnHelper.accessor("azienda.nome", {
        header: "Azienda",
        size: undefined,
      }),
      // columnHelper.accessor("roles", {
      //   header: "Ruolo",
      //   size: undefined,
      //   cell: ({ row }) => {
      //     const roles = row.original.roles || [];
      //     return (
      //       <Box display="flex" gap={0.5}>
      //         {roles.map((role) => {
      //           const [bg, fg] = getRoleColor(role as Role);
      //           return (
      //             <Chip
      //               key={role}
      //               label={role}
      //               size="small"
      //               sx={{
      //                 backgroundColor: bg,
      //                 color: fg,
      //               }}
      //             />
      //           );
      //         })}
      //       </Box>
      //     );
      //   },
      // }),
      hasPermission("UsersEsterniChange") &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 250,
          maxSize: 250,
          cell: ({ row }) => {
            return (
              <Box display="flex" gap={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<Icon>edit</Icon>}
                  onClick={() => {
                    setClientiDialogState({
                      open: true,
                      cliente: row.original,
                    });
                  }}
                >
                  Modifica
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  startIcon={<Icon>delete_outlined</Icon>}
                  onClick={() => {
                    askConfirmation({
                      title: "Elimina cliente",
                      message: `Sei sicuro di voler eliminare il cliente ${row.original.name} ${row.original.lastName} (${row.original.email})?`,
                      okColor: "error",
                      onConfirm: () => {
                        return deleteCliente(row.original.id!);
                      },
                    });
                  }}
                >
                  Elimina
                </Button>
              </Box>
            );
          },
        }),
    ].filter((x) => x);
    return columns as unknown as Exclude<(typeof columns)[number], boolean>[];
  }, [setClientiDialogState, askConfirmation, deleteCliente, hasPermission]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  const clienti = data.data || [];

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Account aziendali
        </Typography>
        <Box display="flex" gap={1} pt={1} flexWrap="wrap">
          {hasPermission("UsersEsterniChange") && (
            <Button
              size="small"
              color="light"
              onClick={() => {
                setClientiDialogState({
                  open: true,
                  cliente: null,
                });
              }}
            >
              Aggiungi account
            </Button>
          )}
        </Box>
      </Box>

      <Table
        columns={columns}
        data={clienti}
        sortable={true}
        initialSort={[{ id: "email", desc: false }]}
      />

      <ClienteDialog
        open={clientiDialogState.open}
        cliente={clientiDialogState.cliente}
        onClose={() => {
          setClientiDialogState((x) => ({
            ...x,
            open: false,
          }));
        }}
      />
      {confirmationDialog}
    </Box>
  );
}

export default ClientePage;
