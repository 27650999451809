import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import Box from "@/elements/Box";
import {
  IS_NEW_KEY,
  useDbRelationsUpdateForm,
} from "@/utils/useDbRelationsUpdate";
import Table from "@/elements/Table";
import {
  getGetModelliFiltroQueryKey,
  ModelloFiltroDto,
  useUpdateModelliFiltro,
} from "@/api";
import { useCallback, useMemo } from "react";
import { createColumnHelper, Row } from "@tanstack/react-table";
import Button from "@/elements/Button";
import {
  Autocomplete,
  FormHelperText,
  Icon,
  InputAdornment,
} from "@mui/material";
import { Controller } from "react-hook-form";
import NumberInput from "@/elements/Input/NumberInput";
import Input from "@/elements/Input";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { uniq } from "lodash";
import TextField from "@mui/material/TextField";

const columnHelper = createColumnHelper<ModelloFiltroDto>();

export default function ModelliFiltroTableCard({
  modelliFiltro,
  canEdit,
}: {
  modelliFiltro: ModelloFiltroDto[];
  canEdit: boolean;
}) {
  const { mutateAsync, isLoading: isSaving } = useUpdateModelliFiltro({
    mutation: {
      onSuccess: () => {
        toast.success("Modifiche salvate!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const {
    data,
    control,
    handleSubmit,
    getChangeSubmitData,
    create,
    remove,
    restore,
    getFieldName,
    getEntityState,
  } = useDbRelationsUpdateForm(modelliFiltro, {
    deleteChangeType: "deleted",
  });

  const editing = canEdit;

  const marche: string[] = useMemo(
    () => uniq(modelliFiltro.map((m) => m.marca)),
    [modelliFiltro]
  );

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor("marca", {
        header: "Marca",
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "marca")}
                control={control}
                rules={{ required: "Inserisci la marca" }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <Autocomplete
                        options={marche}
                        freeSolo
                        value={field.value}
                        renderInput={(props) => (
                          <TextField {...props} onChange={field.onChange} />
                        )}
                        disableClearable
                      />
                      {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),
      columnHelper.accessor("nome", {
        header: "Modello",
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "nome")}
                control={control}
                rules={{ required: "Inserisci il modello" }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <Input {...field} />
                      {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),
      columnHelper.accessor("durataDiVitaInMesi", {
        header: "Durata di vita",
        size: 80,
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "durataDiVitaInMesi")}
                control={control}
                rules={{
                  min: { value: 1, message: "Deve essere maggiore di zero" },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <NumberInput
                        {...field}
                        endAdornment={
                          <InputAdornment position="end">mesi</InputAdornment>
                        }
                      />
                      {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),
      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 125,
          maxSize: 125,
          cell: ({ row }) => {
            return (
              <>
                <Box display="flex" gap={1} flexDirection="row-reverse">
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<Icon>delete_outlined</Icon>}
                    onClick={() => {
                      remove(row.index);
                    }}
                  >
                    Elimina
                  </Button>
                </Box>
                <Box
                  display="flex"
                  gap={1}
                  flexDirection="row-reverse"
                  className="deleted-row-visible-content flex"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<Icon>restore_from_trash</Icon>}
                    onClick={() => {
                      restore(row.index);
                    }}
                  >
                    Ripristina
                  </Button>
                </Box>
              </>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [editing, marche]);

  const isRowDeleted = useCallback(
    (row: Row<ModelloFiltroDto>) => {
      return getEntityState(row.index) === "deleted";
    },
    [getEntityState]
  );

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const data = getChangeSubmitData();
    try {
      await mutateAsync({
        data,
      });
      queryClient
        .invalidateQueries({
          queryKey: getGetModelliFiltroQueryKey(),
        })
        .then();
    } catch (err) {}
  };

  return (
    <Card sx={{ width: "100%" }}>
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="text" fontWeight="medium">
              Modelli Filtro
            </Typography>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                create();
              }}
            >
              Aggiungi filtro
            </Button>
          </Box>

          <Table
            columns={columns}
            data={data}
            sortable={false}
            initialSort={[
              { id: IS_NEW_KEY, desc: false },
              { id: "marca", desc: false },
              { id: "nome", desc: false },
            ]}
            isRowDeleted={isRowDeleted}
          />

          <Box display="flex" flexDirection="row-reverse">
            <Button
              size="small"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isSaving}
            >
              Salva
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
