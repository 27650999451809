import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import Box from "@/elements/Box";
import {
  IS_NEW_KEY,
  useDbRelationsUpdateForm,
} from "@/utils/useDbRelationsUpdate";
import Table from "@/elements/Table";
import {
  DescrizioneManutenzioneDto,
  getGetDescrizioniManutenzioneQueryKey,
  useUpdateDescrizioniManutenzione,
} from "@/api";
import { useCallback, useMemo } from "react";
import { createColumnHelper, Row } from "@tanstack/react-table";
import Button from "@/elements/Button";
import { FormHelperText, Icon } from "@mui/material";
import { Controller } from "react-hook-form";
import Input from "@/elements/Input";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const columnHelper = createColumnHelper<DescrizioneManutenzioneDto>();

export default function DescrizioniManutenzioneTableCard({
  descrizioniManutenzioni,
  canEdit,
}: {
  descrizioniManutenzioni: DescrizioneManutenzioneDto[];
  canEdit: boolean;
}) {
  const { mutateAsync, isLoading: isSaving } = useUpdateDescrizioniManutenzione(
    {
      mutation: {
        onSuccess: () => {
          toast.success("Modifiche salvate!");
        },
        onError: () => {
          toast.error("Errore nella richiesta");
        },
      },
    }
  );

  const {
    data,
    control,
    handleSubmit,
    getChangeSubmitData,
    create,
    remove,
    restore,
    getFieldName,
    getEntityState,
  } = useDbRelationsUpdateForm(descrizioniManutenzioni, {
    deleteChangeType: "deleted",
  });

  const editing = canEdit;

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor("descrizione", {
        header: "Descrizione",
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "descrizione")}
                control={control}
                rules={{ required: "Inserisci la descrizione" }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <Input {...field} />
                      {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),
      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 125,
          maxSize: 125,
          cell: ({ row }) => {
            return (
              <>
                <Box display="flex" gap={1} flexDirection="row-reverse">
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<Icon>delete_outlined</Icon>}
                    onClick={() => {
                      remove(row.index);
                    }}
                  >
                    Elimina
                  </Button>
                </Box>
                <Box
                  display="flex"
                  gap={1}
                  flexDirection="row-reverse"
                  className="deleted-row-visible-content flex"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<Icon>restore_from_trash</Icon>}
                    onClick={() => {
                      restore(row.index);
                    }}
                  >
                    Ripristina
                  </Button>
                </Box>
              </>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [editing]);

  const isRowDeleted = useCallback(
    (row: Row<DescrizioneManutenzioneDto>) => {
      return getEntityState(row.index) === "deleted";
    },
    [getEntityState]
  );

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const data = getChangeSubmitData();
    try {
      await mutateAsync({
        data,
      });
      queryClient
        .invalidateQueries({
          queryKey: getGetDescrizioniManutenzioneQueryKey(),
        })
        .then();
    } catch (err) {}
  };

  return (
    <Card sx={{ width: "100%" }}>
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="text" fontWeight="medium">
              Descrizioni manutenzioni
            </Typography>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                create();
              }}
            >
              Aggiungi descrizione
            </Button>
          </Box>

          <Table
            columns={columns}
            data={data}
            sortable={false}
            initialSort={[
              { id: IS_NEW_KEY, desc: false },
              { id: "descrizione", desc: false },
            ]}
            isRowDeleted={isRowDeleted}
          />

          <Box display="flex" flexDirection="row-reverse">
            <Button
              size="small"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isSaving}
            >
              Salva
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
