import { ForgotPasswordRequestDto, useForgotPassword } from "@/api";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import CoverLayout from "@/elements/CoverLayout";
import Input from "@/elements/Input";
import Typography from "@/elements/Typography";
import { FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { addServerErrorsFactory } from "../../utils/addServerErrorsFactory";
import { toast } from "react-toastify";

function RecuperoPassword() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isLoading, mutate: doForgotPassword } = useForgotPassword();
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
  } = useForm<ForgotPasswordRequestDto>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordRequestDto> = async (data) => {
    doForgotPassword(
      { data },
      {
        onSuccess: () => {
          setEmailSent(true);
        },
        onError: addServerErrorsFactory(setError, () => {
          toast.error("Errore durante il recupero password");
        }),
      }
    );
  };

  return (
    <CoverLayout
      title="Gatti Filtrazioni Lubrificanti"
      description={
        emailSent
          ? "Controlla la tua casella email: se un account esiste riceverai presto il link per impostare una nuova password"
          : "Recupero password: inserisci la tua email"
      }
      showLogo
    >
      <Box
        component="form"
        role="form"
        sx={{
          width: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!emailSent && (
          <>
            <Box mb={2} sx={{ width: "100%", flex: 1 }}>
              <Input
                type="email"
                placeholder="Email"
                {...register("email")}
                disabled={isLoading}
                error={!!errors.email?.message}
                sx={{ width: "100%" }}
              />
              {errors.email?.message ? (
                <FormHelperText error>{errors.email?.message}</FormHelperText>
              ) : null}
            </Box>
            <Box mt={4} mb={1}>
              <Button
                color="secondary"
                fullWidth
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                Recupera password
              </Button>
              {errors.root?.message ? (
                <FormHelperText error>{errors.root?.message}</FormHelperText>
              ) : null}
            </Box>
          </>
        )}

        <Box mt={3} textAlign="center">
          <Typography variant="button" color="text" fontWeight="regular">
            <Link to={"/login"}>
              <Typography variant="button" color="white" fontWeight="bold">
                Vai al login
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </CoverLayout>
  );
}

export default RecuperoPassword;
