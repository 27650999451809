import { AnalisiDto, CentraleDto, getDownloadPdfAnalisiQueryKey } from "@/api";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Table from "@/elements/Table";
import moment from "moment/moment";
import { createColumnHelper } from "@tanstack/react-table";
import { ReactNode, useMemo, useState } from "react";
import FileDownload from "@/components/FileField/FileDownload";
import WarningIcon from "@/assets/warning.png";
import ErrorIcon from "@/assets/error.png";
import { ScadenzaAnalisiWarningDays } from "@/utils/scadenzeUtils";
import { IconButton, Tooltip } from "@mui/material";
import AnalisiDialog from "./AnalisiDialog";
import EditIcon from "@mui/icons-material/Edit";
import OkIcon from "@/assets/ok.png";

const columnHelper = createColumnHelper<AnalisiDto>();

export default function AnalisiTableCard({
  centrale,
  analisi,
  canEdit,
}: {
  centrale: CentraleDto;
  analisi: AnalisiDto[];
  canEdit: boolean;
}) {
  const [analisiDialogOptions, setAnalisiDialogOptions] = useState<{
    open: boolean;
    analisi?: AnalisiDto;
  }>({ open: false });

  const editing = canEdit;

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor("data", {
        header: "Data",
        cell: ({ row }) => moment(row.original.data).format("L"),
      }),

      columnHelper.accessor("esito", {
        header: "Esito",
        cell: ({ getValue }) => <PallinoAnalisi esito={getValue()} />,
      }),

      columnHelper.accessor("validaFinoA", {
        header: "Valida fino a",
        cell: ({ row }) => {
          let icon: ReactNode | null = null;
          if (
            centrale.ultimaAnalisi?.id === row.original.id &&
            row.original.validaFinoA
          ) {
            const days = moment(row.original.validaFinoA).diff(
              moment(),
              "days",
              true
            );
            icon =
              days <= 0 ? (
                <img src={ErrorIcon} width={20} alt="Scaduta" />
              ) : days <= ScadenzaAnalisiWarningDays ? (
                <img src={WarningIcon} width={20} alt="In scadenza" />
              ) : null;
          }

          return (
            <Box display="flex" gap={1} alignItems="center">
              {moment(row.original.validaFinoA).format("L")}
              {icon}
            </Box>
          );
        },
      }),

      columnHelper.display({
        header: "PDF",
        cell: ({ row }) => (
          <FileDownload
            file={row.original.filePdf}
            downloadUrl={
              getDownloadPdfAnalisiQueryKey(centrale.id, row.original.id)[0]
            }
          />
        ),
      }),

      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 100,
          maxSize: 100,
          cell: ({ row }) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                gap={1}
              >
                <Tooltip title="Modifica">
                  <IconButton
                    onClick={() => {
                      setAnalisiDialogOptions({
                        open: true,
                        analisi: row.original,
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [centrale.id, editing, centrale.ultimaAnalisi?.id]);

  return (
    <Box p={2} flex={1}>
      <Box display="flex" flexDirection="column" height="100%" gap={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={1}
        >
          <Typography variant="h5" color="text" fontWeight="medium">
            Storico analisi
          </Typography>
        </Box>

        <Table
          columns={columns}
          data={analisi}
          initialSort={[{ id: "data", desc: true }]}
          noRowsText="Nessuna analisi disponibile"
          mt={1}
        />
      </Box>

      <AnalisiDialog
        centrale={centrale}
        open={analisiDialogOptions.open}
        analisi={analisiDialogOptions.analisi}
        onClose={() => {
          setAnalisiDialogOptions({ open: false });
        }}
      />
    </Box>
  );
}

function PallinoAnalisi({ esito }: { esito: string | null | undefined }) {
  const icon =
    esito === "VERDE"
      ? OkIcon
      : esito === "GIALLO"
      ? WarningIcon
      : esito === "ROSSO"
      ? ErrorIcon
      : null;

  if (!icon) {
    return <Typography>{esito}</Typography>;
  }

  return <img src={icon} width={20} height={20} alt={esito ?? ""} />;
}
