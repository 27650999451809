/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  AziendaDto,
  AziendaCreationDto,
  AziendaUpdateDto,
} from ".././models";

export const getAziende = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AziendaDto[]>> => {
  return axios.get(`/api/aziende`, options);
};

export const getGetAziendeQueryKey = () => [`/api/aziende`] as const;

export const getGetAziendeQueryOptions = <
  TData = Awaited<ReturnType<typeof getAziende>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAziende>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<Awaited<ReturnType<typeof getAziende>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAziendeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAziende>>> = ({
    signal,
  }) => getAziende({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAziendeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAziende>>
>;
export type GetAziendeQueryError = AxiosError<unknown>;

export const useGetAziende = <
  TData = Awaited<ReturnType<typeof getAziende>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAziende>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAziendeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createAziende = (
  aziendaCreationDto: AziendaCreationDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AziendaDto>> => {
  return axios.post(`/api/aziende`, aziendaCreationDto, options);
};

export const getCreateAziendeMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAziende>>,
    TError,
    { data: AziendaCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAziende>>,
  TError,
  { data: AziendaCreationDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAziende>>,
    { data: AziendaCreationDto }
  > = (props) => {
    const { data } = props ?? {};

    return createAziende(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAziendeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAziende>>
>;
export type CreateAziendeMutationBody = AziendaCreationDto;
export type CreateAziendeMutationError = AxiosError<unknown>;

export const useCreateAziende = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAziende>>,
    TError,
    { data: AziendaCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateAziendeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAzienda = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AziendaDto>> => {
  return axios.get(`/api/aziende/${id}`, options);
};

export const getGetAziendaQueryKey = (id: number) =>
  [`/api/aziende/${id}`] as const;

export const getGetAziendaQueryOptions = <
  TData = Awaited<ReturnType<typeof getAzienda>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAzienda>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getAzienda>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAziendaQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAzienda>>> = ({
    signal,
  }) => getAzienda(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetAziendaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAzienda>>
>;
export type GetAziendaQueryError = AxiosError<unknown>;

export const useGetAzienda = <
  TData = Awaited<ReturnType<typeof getAzienda>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAzienda>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAziendaQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateAzienda = (
  id: number,
  aziendaUpdateDto: AziendaUpdateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AziendaDto>> => {
  return axios.put(`/api/aziende/${id}`, aziendaUpdateDto, options);
};

export const getUpdateAziendaMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAzienda>>,
    TError,
    { id: number; data: AziendaUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAzienda>>,
  TError,
  { id: number; data: AziendaUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAzienda>>,
    { id: number; data: AziendaUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAzienda(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAziendaMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAzienda>>
>;
export type UpdateAziendaMutationBody = AziendaUpdateDto;
export type UpdateAziendaMutationError = AxiosError<unknown>;

export const useUpdateAzienda = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAzienda>>,
    TError,
    { id: number; data: AziendaUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateAziendaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteAzienda = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/aziende/${id}`, options);
};

export const getDeleteAziendaMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAzienda>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAzienda>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAzienda>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteAzienda(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAziendaMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAzienda>>
>;

export type DeleteAziendaMutationError = AxiosError<unknown>;

export const useDeleteAzienda = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAzienda>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getDeleteAziendaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadFilePdfAnalisi = (
  id: number,
  idAnalisi: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/aziende/${id}/analisi/${idAnalisi}/filePdf`, options);
};

export const getDownloadFilePdfAnalisiQueryKey = (
  id: number,
  idAnalisi: number
) => [`/api/aziende/${id}/analisi/${idAnalisi}/filePdf`] as const;

export const getDownloadFilePdfAnalisiQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFilePdfAnalisi>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idAnalisi: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFilePdfAnalisi>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof downloadFilePdfAnalisi>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadFilePdfAnalisiQueryKey(id, idAnalisi);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFilePdfAnalisi>>
  > = ({ signal }) =>
    downloadFilePdfAnalisi(id, idAnalisi, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(id && idAnalisi), ...queryOptions };
};

export type DownloadFilePdfAnalisiQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFilePdfAnalisi>>
>;
export type DownloadFilePdfAnalisiQueryError = AxiosError<unknown>;

export const useDownloadFilePdfAnalisi = <
  TData = Awaited<ReturnType<typeof downloadFilePdfAnalisi>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idAnalisi: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFilePdfAnalisi>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadFilePdfAnalisiQueryOptions(
    id,
    idAnalisi,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useGetAziende = () =>
  //@ts-ignore
  useGetAziende(...([] as any[]));

export const __$$useCreateAziende = () =>
  //@ts-ignore
  useCreateAziende(...([] as any[]));

export const __$$useGetAzienda = () =>
  //@ts-ignore
  useGetAzienda(...([] as any[]));

export const __$$useUpdateAzienda = () =>
  //@ts-ignore
  useUpdateAzienda(...([] as any[]));

export const __$$useDeleteAzienda = () =>
  //@ts-ignore
  useDeleteAzienda(...([] as any[]));

export const __$$useDownloadFilePdfAnalisi = () =>
  //@ts-ignore
  useDownloadFilePdfAnalisi(...([] as any[]));
