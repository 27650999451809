import Box from "@/elements/Box";
import Button from "@/elements/Button";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Icon,
  InputAdornment,
} from "@mui/material";
import {
  CentraleDto,
  getGetCentraleQueryKey,
  OlioDto,
  useUpdateStoricoOlio,
} from "@/api";
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import Table from "@/elements/Table";
import { useCallback, useMemo } from "react";
import {
  IS_NEW_KEY,
  useDbRelationsUpdateForm,
} from "@/utils/useDbRelationsUpdate";
import { Controller } from "react-hook-form";
import { withOpeningReset } from "../../utils/withOpeningReset";
import { useAuth } from "@/context/useAuth";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import NumberInput from "@/elements/Input/NumberInput";
import ModelloOlioPicker from "@/components/ModelloOlioPicker";
import { buildDateTimeCell } from "@/elements/Table/dateTimeCell";

const columnHelper = createColumnHelper<OlioDto>();

function StoricoOlioDialog({
  open = false,
  canEdit = false,
  onClose,
  centrale,
  storicoOlio,
}: {
  open?: boolean;
  canEdit?: boolean;
  onClose?: () => void;
  centrale: CentraleDto;
  storicoOlio: OlioDto[];
}) {
  const handleClose = () => {
    onClose?.();
  };

  const editing = canEdit;

  const {
    data,
    control,
    resetField,
    watch,
    handleSubmit,
    getChangeSubmitData,
    create,
    remove,
    restore,
    getFieldName,
    getEntityState,
    setValue,
    setError,
    formState: { errors },
    getValues,
  } = useDbRelationsUpdateForm(storicoOlio);

  const { hasPermission } = useAuth();

  const columns = useMemo<ColumnDef<OlioDto, any>[]>(() => {
    const dataTimeCell = buildDateTimeCell(control, editing);

    const columns = [
      columnHelper.accessor("inseritoIl", {
        header: "Sostituito il",
        size: 175,
        maxSize: 175,
        cell: ({ getValue, row }) =>
          dataTimeCell(getValue(), getFieldName(row.index, "inseritoIl"), true),
      }),
      // columnHelper.accessor("rimossoIl", {
      //   header: "Rimosso il",
      //   size: 175,
      //   maxSize: 175,
      //   cell: ({ getValue, row }) =>
      //     dataTimeCell(getValue(), getFieldName(row.index, "rimossoIl"), false),
      // }),

      columnHelper.accessor("modelloOlio", {
        header: "Modello olio",
        size: undefined,
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "modelloOlio")}
                control={control}
                rules={{ required: "Inserisci il modello olio" }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <ModelloOlioPicker
                        {...field}
                        onChange={(e, v) => {
                          field.onChange(v);
                          setValue(
                            getFieldName(row.index, "modelloOlioId"),
                            v?.id
                          );
                        }}
                        canCreateModelloOlio={hasPermission(
                          "ModelliOlioChange"
                        )}
                      />
                      {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            )
          : ({ row: { original } }) =>
              original.modelloOlio
                ? `${original.modelloOlio.marca} - ${original.modelloOlio.nome}`
                : null,
      }),

      columnHelper.accessor("quantitaLitri", {
        header: "Quantità",
        size: 140,
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "quantitaLitri")}
                control={control}
                rules={{
                  required: "Inserisci la quantità",
                  min: { value: 1, message: "Deve essere maggiore di zero" },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <NumberInput
                        {...field}
                        endAdornment={
                          <InputAdornment position="end">litri</InputAdornment>
                        }
                      />
                      {error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),

      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 125,
          maxSize: 125,
          cell: ({ row }) => {
            return (
              <>
                <Box display="flex" gap={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<Icon>delete_outlined</Icon>}
                    onClick={() => {
                      remove(row.index);
                    }}
                  >
                    Elimina
                  </Button>
                </Box>
                <Box
                  display="flex"
                  gap={1}
                  className="deleted-row-visible-content"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<Icon>restore_from_trash</Icon>}
                    onClick={() => {
                      restore(row.index);
                    }}
                  >
                    Ripristina
                  </Button>
                </Box>
              </>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [
    editing,
    control,
    getFieldName,
    hasPermission,
    resetField,
    watch,
    remove,
    restore,
  ]);

  const isRowDeleted = useCallback(
    (row: Row<OlioDto>) => {
      return getEntityState(row.index) === "deleted";
    },
    [getEntityState]
  );

  const { mutateAsync, isLoading, error } = useUpdateStoricoOlio({
    mutation: {
      onSuccess: () => {
        toast.success("Storico olio salvato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const data = getValues();
    // if (data.data.filter((o) => !o.rimossoIl).length > 1) {
    //   setError("root", {
    //     message: "Al massimo una riga può avere il campo 'rimosso il' vuoto",
    //   });
    //   return;
    // }

    try {
      await mutateAsync({
        id: centrale.id,
        data: getChangeSubmitData(),
      });
      await queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Storico olio - {centrale?.nome}</DialogTitle>
      <DialogContent>
        {storicoOlio && (
          <Box>
            <Box>
              {editing && (
                <Button
                  size="small"
                  color="light"
                  onClick={() => {
                    create();
                  }}
                >
                  Aggiungi olio
                </Button>
              )}
            </Box>

            {errors.root?.message && (
              <Alert sx={{ mt: 1 }} severity="warning">
                {errors.root?.message}
              </Alert>
            )}

            <Table
              columns={columns}
              data={data}
              sortable={false}
              initialSort={[
                { id: IS_NEW_KEY, desc: false },
                { id: "inseritoIl", desc: true },
              ]}
              isRowDeleted={isRowDeleted}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        {editing && (
          <Button
            size="small"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Salva
          </Button>
        )}
        {onClose && (
          <Button size="small" color="light" onClick={handleClose}>
            Chiudi
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(StoricoOlioDialog);
