import { CentraleDto } from "@/api";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import { Box, DialogActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { GattiQrCode } from "./GattiQrCode";

function QRCodeDialog({
  open = false,
  onClose,
  centrale,
}: {
  open?: boolean;
  onClose?: (centrale: CentraleDto | null) => void;
  centrale?: CentraleDto;
}) {
  const handleClose = () => {
    onClose?.(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        <Box display={"grid"} gridTemplateColumns={"auto 1fr"} gap={4}>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <GattiQrCode
              value={`${window.location.origin}/qr/centrale/${centrale?.tokenCodiceQR}`}
              size={240}
              fileName={`Centrale ${centrale?.nome}`}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography variant="h5">QR Code Centrale</Typography>
            </Box>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            maxHeight={"400px"}
            overflow={"auto"}
          >
            {centrale?.alloggiamentiFiltro?.length === 0 && (
              <Typography fontStyle="italic">
                Nessun alloggiamento filtro mappato
              </Typography>
            )}

            {centrale?.alloggiamentiFiltro?.map((af, i) => {
              return (
                <Box key={i} display={"flex"} alignItems={"center"} gap={2}>
                  <GattiQrCode
                    fileName={`Filtro ${af.nome}`}
                    value={`${window.location.origin}/qr/filtro/${af?.tokenCodiceQR}`}
                    size={120}
                  />
                  <Typography variant="h5">
                    QR Code alloggiamento filtro {af.nome}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="light" onClick={handleClose}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(QRCodeDialog);
