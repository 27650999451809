import { AsyncAutocomplete } from "@/elements/Autocomplete";
import React, { FocusEventHandler, forwardRef, useState } from "react";
import { ModelloFiltroDto, useGetModelliFiltro } from "@/api";
import ModelloFiltroDialog from "@/components/ModelloFiltroDialog";

const ModelloFiltroPicker = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      canCreateModelloFiltro = false,
      placeholder,
    }: {
      value?: ModelloFiltroDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: ModelloFiltroDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      canCreateModelloFiltro?: boolean;
      placeholder?: string;
    },
    ref
  ) => {
    const [
      createModelloFiltroDialogOptions,
      setCreateModelloFiltroDialogOptions,
    ] = useState<{ open: boolean; nome?: string; marca?: string }>({
      open: false,
    });

    const onCreateModelloFiltroClose = (
      modelloFiltro: ModelloFiltroDto | null
    ) => {
      onChange?.(null, modelloFiltro);
      setCreateModelloFiltroDialogOptions({ open: false });
    };

    function onNewOptionSelected(text: string) {
      const [marca, ...nome] = text.split(" ");
      setCreateModelloFiltroDialogOptions({
        open: true,
        marca,
        nome: nome.join(" "),
      });
    }

    return (
      <>
        <AsyncAutocomplete<ModelloFiltroDto>
          ref={ref}
          onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          fetchOptions={useGetModelliFiltro}
          getOptionLabel={(modelloFiltro) =>
            `${modelloFiltro.marca} - ${modelloFiltro.nome}`
          }
          canCreateNewOption={canCreateModelloFiltro}
          value={value}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
        />
        {canCreateModelloFiltro && (
          <ModelloFiltroDialog
            open={createModelloFiltroDialogOptions.open}
            onClose={onCreateModelloFiltroClose}
            modelloFiltro={{
              nome: createModelloFiltroDialogOptions.nome,
              marca: createModelloFiltroDialogOptions.marca,
            }}
          />
        )}
      </>
    );
  }
);

export default ModelloFiltroPicker;
