import {
  DispositivoDto,
  getDownloadRapportinoBonificaQueryKey,
  getDownloadRapportinoManutenzioneQueryKey,
  getGetCentraleQueryKey,
  getGetDispositivoQueryKey,
  getGetManutenzioniQueryKey,
  ManutenzioneDto,
  useGetManutenzioni,
  useUpdateBonifiche,
  useUpdateManutenzioni,
} from "@/api";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Table from "@/elements/Table";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@/elements/Button";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownload from "@/components/FileField/FileDownload";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import ManutenzioneDialog from "../ManutenzioneDialog";
import moment from "moment";

const columnHelper = createColumnHelper<ManutenzioneDto>();

export default function ManutenzioniTableCard({
  dispositivo,
  canEdit = false,
}: {
  dispositivo: DispositivoDto;
  canEdit?: boolean;
}) {
  const [manutenzioneDialogOptions, setManutenzioneDialogOptions] = useState<{
    open: boolean;
    manutenzione?: ManutenzioneDto;
  }>({ open: false });

  const editing = canEdit;

  const queryClient = useQueryClient();
  const { mutate } = useUpdateManutenzioni();

  const { data } = useGetManutenzioni(dispositivo?.id);

  const manutenzioni = data?.data || [];

  const deleteManutenzione = useCallback(
    (manutenzione: ManutenzioneDto) => {
      const message = `Sei sicuro di voler eliminare la manutenzione?`;
      if (!window.confirm(message)) {
        return;
      }

      mutate(
        {
          id: dispositivo.id,
          data: [
            {
              id: manutenzione.id,
              changeType: "deleted",
              entity: manutenzione,
            },
          ],
        },
        {
          onSuccess: () => {
            queryClient
              .invalidateQueries(getGetManutenzioniQueryKey(dispositivo.id))
              .then(() => {
                toast.success("manutenzione eliminata");
              });
          },
          onError: () => {
            toast.error("Errore durante l'eliminazione della manutenzione");
          },
        }
      );
    },
    [queryClient, dispositivo, manutenzioni, mutate]
  );

  const columns = useMemo<ColumnDef<ManutenzioneDto, any>[]>(() => {
    const columns = [
      columnHelper.accessor("data", {
        header: "Data",
        cell: ({ row }) => moment(row.original.data).format("L"),
      }),

      columnHelper.accessor("descrizione", {
        header: "Descrizione",
        minSize: 200,
      }),

      columnHelper.display({
        header: "Rapportino",
        cell: ({ row }) => (
          <FileDownload
            file={row.original.fileRapportino}
            downloadUrl={
              getDownloadRapportinoManutenzioneQueryKey(
                dispositivo.id,
                row.original.id
              )[0]
            }
          />
        ),
      }),

      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 100,
          maxSize: 100,
          cell: ({ row }) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                gap={1}
              >
                <Tooltip title="Elimina">
                  <IconButton
                    onClick={() => {
                      deleteManutenzione(row.original);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Modifica">
                  <IconButton
                    onClick={() => {
                      setManutenzioneDialogOptions({
                        open: true,
                        manutenzione: row.original,
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [editing, dispositivo, manutenzioni, deleteManutenzione]);

  return (
    <>
      <Box flex={1}>
        <Box display="flex" flexDirection="column" height="100%" gap={1}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={1}
          >
            <Typography variant="h5" color="text" fontWeight="medium">
              Storico manutenzioni
            </Typography>

            {editing && (
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  setManutenzioneDialogOptions({ open: true });
                }}
              >
                Aggiungi
              </Button>
            )}
          </Box>

          <Table
            columns={columns}
            data={manutenzioni}
            initialSort={[{ id: "id", desc: true }]}
            noRowsText="Nessuna manutenzione"
            mt={1}
          />
        </Box>
      </Box>

      <ManutenzioneDialog
        dispositivo={dispositivo}
        manutenzione={manutenzioneDialogOptions.manutenzione}
        open={manutenzioneDialogOptions.open}
        onClose={() => {
          setManutenzioneDialogOptions({ open: false });
        }}
      />
    </>
  );
}
