import {
  AnalisiDto,
  AziendaDto,
  BonificaDto,
  CentraleDto,
  FiltrazioneDto,
  useGetAnalisi,
  useGetAziende,
  useGetBonifiche,
  useGetCentrali,
  useGetFiltrazioni,
} from "@/api";
import AnalisiIcon from "@/assets/analisi.png";
import BonificaIcon from "@/assets/bonifica.png";
import FiltroIcon from "@/assets/filtro.png";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { usePosizioneFilter } from "@/hooks/usePosizioneFilter";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Icon,
  TextField,
  Tooltip,
} from "@mui/material";
import { keyBy, merge } from "lodash";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import { Calendar, momentLocalizer, View, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import AziendaPicker from "../AziendaPicker";
import Loading from "../Loading";
import PosizioneCentrale from "../PosizioneCentrale";
import RepartoPicker from "../RepartoPicker";
import StabilimentoPicker from "../StabilimentoPicker";
import "./calendar.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const localizer = momentLocalizer(moment);

const messages = {
  date: "Data",
  time: "Ora",
  event: "Evento",
  allDay: "Tutto il giorno",
  week: "Settimana",
  work_week: "Settimana lavorativa",
  day: "Giorno",
  month: "Mese",
  previous: "Precedente",
  next: "Successiva",
  yesterday: "Ieri",
  tomorrow: "Domani",
  today: "Oggi",
  agenda: "Agenda",
  noEventsInRange: "Nessun evento in questo intervallo.",
  showMore: (total: number) =>
    total === 1 ? `+${total} altro` : `+${total} altri`,
};

const views = [Views.MONTH, Views.AGENDA];

const allDayAccessor = () => true;

type Evento = {
  date: Moment;
  azienda: AziendaDto;
} & (
  | {
      type: "analisi" | "analisi_scadenza";
      centrale: CentraleDto | null;
      analisi: AnalisiDto;
    }
  | {
      type: "filtrazione" /*| "filtrazione_scadenza"*/;
      centrale: CentraleDto;
      filtrazione: FiltrazioneDto;
    }
  | {
      type: "bonifica" | "bonifica_scadenza";
      centrale: CentraleDto;
      bonifica: BonificaDto;
    }
);

type EventoType = Evento["type"];

const colorsByEvento: {
  [type in EventoType]: {
    backgroundColor: string;
    color?: string;
    blinking?: boolean;
  };
} = {
  analisi: {
    backgroundColor: "#f8b31e",
  },
  analisi_scadenza: {
    backgroundColor: "#f8b31e",
    blinking: true,
  },
  filtrazione: {
    backgroundColor: "#877a9b",
    color: "#E0E0E0",
  },
  // filtrazione_scadenza: {
  //   backgroundColor: "#877a9b",
  //   color: "#E0E0E0",
  //   blinking: true,
  // },
  bonifica: {
    backgroundColor: "#885c56",
    color: "#E0E0E0",
  },
  bonifica_scadenza: {
    backgroundColor: "#885c56",
    color: "#E0E0E0",
    blinking: true,
  },
};

function CalendarioPage() {
  const { user, hasPermission } = useAuth();

  const { data: aziende, isLoading: aziendeLoading } = useGetAziende();
  const { data: centrali, isLoading: centraliLoading } = useGetCentrali();

  const aziendaById = useMemo(() => {
    if (!aziende?.data) {
      return {};
    }
    return keyBy(aziende.data, "id");
  }, [aziende]);

  const centraleById = useMemo(() => {
    if (!centrali?.data) {
      return {};
    }
    return keyBy(centrali.data, "id");
  }, [centrali]);

  const { data: analisi, isLoading: analisiLoading } = useGetAnalisi({
    query: {
      enabled: hasPermission("AnalisiListaRead"),
    },
  });
  const { data: filtrazioni, isLoading: filtrazioniLoading } =
    useGetFiltrazioni({
      query: {
        enabled: hasPermission("FiltrazioniListaRead"),
      },
    });
  const { data: bonifiche, isLoading: bonificheLoading } = useGetBonifiche({
    query: {
      enabled: hasPermission("BonificheListaRead"),
    },
  });

  const loading =
    aziendeLoading ||
    centraliLoading ||
    analisiLoading ||
    filtrazioniLoading ||
    bonificheLoading;

  const [filters, changeFilter] = usePosizioneFilter();
  const [filterTypes, setFilterTypes] = useState<EventoType[] | null>(null);

  const [view, setView] = useState<View>(views[0]);

  const eventsAnalisi = useMemo(() => {
    const events: Evento[] = [];

    const includiEffettuate = !filterTypes || filterTypes.includes("analisi");
    const includiScadenze =
      !filterTypes || filterTypes.includes("analisi_scadenza");
    if (analisi?.data) {
      for (const a of analisi.data) {
        const azienda = aziendaById[a.aziendaId];
        if (!azienda) {
          continue;
        } else if (filters?.azienda && azienda.id !== filters.azienda.id) {
          continue;
        }

        let centrale: CentraleDto | null;
        if (a.centraleId) {
          centrale = centraleById[a.centraleId];
          if (!centrale) {
            continue;
          } else if (
            filters?.stabilimento &&
            centrale.stabilimentoId !== filters.stabilimento.id
          ) {
            continue;
          } else if (
            filters?.reparto &&
            centrale.repartoId !== filters.reparto.id
          ) {
            continue;
          }
        } else {
          centrale = null;
        }

        if (includiEffettuate) {
          events.push({
            type: "analisi",
            date: moment(a.data, "YYYY-MM-DD").startOf("day"),
            analisi: a,
            azienda: azienda,
            centrale: centrale,
          });
        }

        if (includiScadenze) {
          events.push({
            type: "analisi_scadenza",
            date: moment(a.validaFinoA).startOf("day"),
            analisi: a,
            azienda: azienda,
            centrale: centrale,
          });
        }
      }
    }

    return events;
  }, [aziendaById, centraleById, analisi, filters, filterTypes]);

  const eventsFiltrazioni = useMemo(() => {
    const events: Evento[] = [];

    const includiEffettuate =
      !filterTypes || filterTypes.includes("filtrazione");
    // const includiScadenze =
    //   !filterTypes || filterTypes.includes("filtrazione_scadenza");
    if (filtrazioni?.data) {
      for (const f of filtrazioni.data) {
        const centrale = centraleById[f.centraleId];
        if (!centrale) {
          continue;
        } else if (
          filters?.stabilimento &&
          centrale.stabilimentoId !== filters.stabilimento.id
        ) {
          continue;
        } else if (
          filters?.reparto &&
          centrale.repartoId !== filters.reparto.id
        ) {
          continue;
        }

        const azienda = aziendaById[centrale.aziendaId];
        if (!azienda) {
          continue;
        } else if (filters?.azienda && azienda.id !== filters.azienda.id) {
          continue;
        }

        if (includiEffettuate) {
          events.push({
            type: "filtrazione",
            date: moment(f.data, "YYYY-MM-DD").startOf("day"),
            filtrazione: f,
            azienda: azienda,
            centrale: centrale,
          });
        }

        // if (includiScadenze) {
        //   events.push({
        //     type: "filtrazione_scadenza",
        //     date: moment(f.).startOf("day"),
        //     filtrazione: f,
        //     azienda: azienda,
        //     centrale: centrale,
        //   });
        // }
      }
    }

    return events;
  }, [aziendaById, centraleById, filtrazioni, filters, filterTypes]);

  const eventsBonifiche = useMemo(() => {
    const events: Evento[] = [];

    const includiEffettuate = !filterTypes || filterTypes.includes("bonifica");
    const includiScadenze =
      !filterTypes || filterTypes.includes("bonifica_scadenza");
    if (bonifiche?.data) {
      for (const b of bonifiche.data) {
        const centrale = centraleById[b.centraleId];
        if (!centrale) {
          continue;
        } else if (
          filters?.stabilimento &&
          centrale.stabilimentoId !== filters.stabilimento.id
        ) {
          continue;
        } else if (
          filters?.reparto &&
          centrale.repartoId !== filters.reparto.id
        ) {
          continue;
        }

        const azienda = aziendaById[centrale.aziendaId];
        if (!azienda) {
          continue;
        } else if (filters?.azienda && azienda.id !== filters.azienda.id) {
          continue;
        }

        if (includiEffettuate) {
          events.push({
            type: "bonifica",
            date: moment(b.data, "YYYY-MM-DD").startOf("day"),
            bonifica: b,
            azienda: azienda,
            centrale: centrale,
          });
        }

        if (includiScadenze && b.scadenza) {
          events.push({
            type: "bonifica_scadenza",
            date: moment(b.scadenza).startOf("day"),
            bonifica: b,
            azienda: azienda,
            centrale: centrale,
          });
        }
      }
    }

    return events;
  }, [aziendaById, centraleById, bonifiche, filters, filterTypes]);

  const events = useMemo(() => {
    return [...eventsAnalisi, ...eventsFiltrazioni, ...eventsBonifiche];
  }, [eventsAnalisi, eventsFiltrazioni, eventsBonifiche]);

  const renderTitle = useMemo(() => {
    const TitoloEvento = ({ evento }: { evento: Evento }) => {
      const iconSize = 24;

      let icon: string;
      let tooltip: string;
      let nome: string;
      let isScadenza: boolean;
      const { color } = colorsByEvento[evento.type];

      if (evento.type === "analisi" || evento.type === "analisi_scadenza") {
        icon = AnalisiIcon;
        tooltip =
          evento.type === "analisi" ? "Analisi svolta" : "Scadenza analisi";
        isScadenza = evento.type === "analisi_scadenza";
        nome = evento.centrale?.nome ?? "Analisi campione";
      } else if (
        evento.type === "filtrazione"
        //|| evento.type === "filtrazione_scadenza"
      ) {
        icon = FiltroIcon;
        tooltip =
          evento.type === "filtrazione"
            ? "Filtrazione svolta"
            : "Scadenza filtrazione";
        isScadenza = false; // evento.type === "filtrazione_scadenza";
        nome = evento.centrale.nome;
      } else if (
        evento.type === "bonifica" ||
        evento.type === "bonifica_scadenza"
      ) {
        icon = BonificaIcon;
        tooltip =
          evento.type === "bonifica" ? "Bonifica svolta" : "Scadenza bonifica";
        isScadenza = evento.type === "bonifica_scadenza";
        nome = evento.centrale.nome;
      } else {
        return <Box>Evento sconosciuto</Box>;
      }

      return (
        <Tooltip title={tooltip}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>
                <img src={icon} width={iconSize} alt={tooltip} />
              </Box>
              {isScadenza && <Icon sx={{ color, mr: 0.5 }}>schedule</Icon>}
              {evento.centrale && view === Views.AGENDA ? (
                <PosizioneCentrale
                  centrale={evento.centrale}
                  showAzienda={false}
                  showCentrale
                  big
                  color={color}
                />
              ) : (
                <Typography sx={{ color }}>{nome}</Typography>
              )}
            </Box>

            <Box display="flex" flexDirection="row" gap={0.5}>
              <Icon sx={{ color }}>factory</Icon>
              <Typography sx={{ color }}>{evento.azienda.nome}</Typography>
            </Box>
          </Box>
        </Tooltip>
      );
    };

    return (e: any) => (<TitoloEvento evento={e as Evento} />) as any;
  }, [view]);

  const navigate = useNavigate();

  function handleEventSelect(evento: Evento) {
    if (evento.centrale) {
      navigate(`/centrali/${evento.centrale.id}`);
    } else if (hasPermission("AziendeRead")) {
      navigate(`/aziende/${evento.azienda.id}`);
    } else if (user?.isEsterno) {
      navigate(`/home`);
    }
  }

  return (
    <Box
      p={2}
      sx={{
        height: "calc(100vh - 96px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Calendario
        </Typography>

        <Box display="flex" gap={1} pt={2} flexWrap="wrap">
          <EventoTypePicker value={filterTypes} onChange={setFilterTypes} />
          {!user?.isEsterno && (
            <AziendaPicker
              value={filters.azienda}
              onChange={changeFilter("azienda", ["stabilimento", "reparto"])}
              placeholder="Azienda"
              size="small"
            />
          )}
          <StabilimentoPicker
            aziendaId={
              user?.isEsterno ? user?.azienda?.id : filters.azienda?.id
            }
            value={filters.stabilimento}
            onChange={changeFilter("stabilimento", ["reparto"])}
            placeholder="Stabilimento"
            size="small"
          />
          <RepartoPicker
            stabilimentoId={filters.stabilimento?.id}
            value={filters.reparto}
            onChange={changeFilter("reparto")}
            placeholder="Reparto"
            size="small"
          />
        </Box>
      </Box>

      <Box
        mt={4}
        mb={0}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
        flex={1}
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            opacity: loading ? 0.25 : 1,
            "& .rbc-agenda-table .rbc-header:nth-child(2)": { display: "none" },
            "& .rbc-agenda-table .rbc-agenda-time-cell": { display: "none" },
          }}
        >
          <Calendar
            messages={messages}
            views={views}
            localizer={localizer}
            events={events}
            startAccessor="date"
            endAccessor="date"
            titleAccessor={renderTitle}
            allDayAccessor={allDayAccessor}
            eventPropGetter={eventPropGetter}
            //resourceIdAccessor={getAccessor("getId", accessors)}
            //eventPropGetter={getAccessor0_3("getEventProps", accessors)}
            popup
            view={view as View}
            onView={(v) => setView(v)}
            onSelectEvent={handleEventSelect}
          />
        </Box>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </Box>
        )}
      </Box>
    </Box>
  );
}

const eventPropGetter = (evento: Evento) => {
  const colors = colorsByEvento[evento.type];
  return {
    className: colors.blinking ? "calendar-blinking-event" : undefined,
    style: {
      backgroundColor: colors.backgroundColor,
      color: colors.color,
    },
  };
};

const eventiLabelByType: { [type in EventoType]: string } = {
  analisi: "Analisi svolte",
  analisi_scadenza: "Scadenza analisi",
  filtrazione: "Filtrazioni svolte",
  //filtrazione_scadenza: "Scadenza filtrazioni",
  bonifica: "Bonifiche svolte",
  bonifica_scadenza: "Scandeza bonifiche",
};

const eventiTypeOptions = Object.entries(eventiLabelByType).map(
  ([key, value]) => {
    return {
      value: key,
      label: value,
    };
  }
);

function EventoTypePicker({
  value,
  onChange,
}: {
  value: EventoType[] | null;
  onChange: (newValues: EventoType[] | null) => void;
}) {
  const _value = useMemo(() => {
    return (
      value?.map((v) => eventiTypeOptions.find((x) => x.value === v)!) ?? null
    );
  }, [value]);

  const allSelected = _value && _value.length === eventiTypeOptions.length;

  const textValue = useMemo(() => {
    if (!_value) {
      return "";
    } else if (allSelected) {
      return "";
    }
    const labels = eventiTypeOptions
      .filter((option) => _value.includes(option))
      .map((o) => o.label);
    return labels.join(", ");
  }, [_value, allSelected]);

  return (
    <Autocomplete
      multiple
      options={eventiTypeOptions}
      size="small"
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props as any;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...merge(params, { inputProps: { value: textValue } })}
            sx={{
              "& .MuiChip-root": { display: "none" },
            }}
            placeholder="Tipi di evento"
          />
        );
      }}
      value={_value ?? []}
      onChange={(_, newValues) => {
        const selected = newValues.map((o) => o.value) as EventoType[];
        onChange(selected.length > 0 ? selected : null);
      }}
    />
  );
}

export default CalendarioPage;

// TODO: gestione di più eventi nella stessa celletta
