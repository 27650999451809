import {
  AlloggiamentoFiltroDto,
  CentraleDto,
  getGetCentraleQueryKey,
  useUpdateAlloggiamentiFiltro,
} from "@/api";
import { SmallLoading } from "@/components/Loading";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Input from "@/elements/Input";
import Table from "@/elements/Table";
import { filtroTypes, getFiltroTypeHelper } from "@/utils/filtroTypeUtils";
import {
  IS_NEW_KEY,
  useDbRelationsUpdateForm,
} from "@/utils/useDbRelationsUpdate";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef, Row, createColumnHelper } from "@tanstack/react-table";
import { countBy } from "lodash";
import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";

const columnHelper = createColumnHelper<AlloggiamentoFiltroDto>();

function AlloggiamentiFiltroDialog({
  open = false,
  onClose,
  centrale,
}: {
  open?: boolean;
  onClose?: () => void;
  centrale: CentraleDto;
}) {
  const { alloggiamentiFiltro } = centrale;

  const handleClose = () => {
    onClose?.();
  };

  const {
    data,
    control,
    resetField,
    watch,
    handleSubmit,
    getChangeSubmitData,
    create,
    remove,
    restore,
    getFieldName,
    getEntityState,
    setValue,
    setError,
    formState: { errors },
    getValues,
  } = useDbRelationsUpdateForm(alloggiamentiFiltro);

  const columns = useMemo<ColumnDef<AlloggiamentoFiltroDto, any>[]>(() => {
    const columns = [
      columnHelper.accessor("nome", {
        header: "Nome",
        cell: ({ row }) => (
          <Controller
            name={getFieldName(row.index, "nome")}
            control={control}
            rules={{ required: "Inserisci il nome dell'alloggiamento" }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <Input fullWidth {...field} />
                  {error && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </>
              );
            }}
          />
        ),
      }),

      columnHelper.accessor("filtroType", {
        header: "Tipo",
        cell: ({ row }) => (
          <Controller
            name={getFieldName(row.index, "filtroType")}
            control={control}
            rules={{ required: "Inserisci il tipo di filtro" }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <Select {...field} size="small">
                    {filtroTypes.map((type) => {
                      return (
                        <MenuItem key={type} value={type}>
                          {getFiltroTypeHelper(type)?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  {error && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </>
              );
            }}
          />
        ),
      }),

      columnHelper.display({
        id: "buttons",
        header: "",
        size: 125,
        maxSize: 125,
        cell: ({ row }) => {
          return (
            <>
              <Box display="flex" gap={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  startIcon={<Icon>delete_outlined</Icon>}
                  onClick={() => {
                    remove(row.index);
                  }}
                >
                  Elimina
                </Button>
              </Box>
              <Box
                display="flex"
                gap={1}
                className="deleted-row-visible-content"
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<Icon>restore_from_trash</Icon>}
                  onClick={() => {
                    restore(row.index);
                  }}
                >
                  Ripristina
                </Button>
              </Box>
            </>
          );
        },
      }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [control, getFieldName, resetField, watch, remove, restore]);

  const isRowDeleted = useCallback(
    (row: Row<AlloggiamentoFiltroDto>) => {
      return getEntityState(row.index) === "deleted";
    },
    [getEntityState]
  );

  const { mutateAsync, isLoading, error } = useUpdateAlloggiamentiFiltro();

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const data = getValues();
    const occurrencesByName = countBy(data.data, "nome");
    const duplicate = Object.entries(occurrencesByName).find(
      ([, occurrences]) => occurrences > 1
    )?.[0];
    if (duplicate) {
      setError("root", {
        message: `Uno o più alloggiamenti hanno lo stesso nome "${duplicate}"`,
      });
      return;
    }

    try {
      await mutateAsync({
        id: centrale.id,
        data: getChangeSubmitData(),
      });
      await queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Alloggiamenti filtro - {centrale?.nome}</DialogTitle>
      <DialogContent>
        {alloggiamentiFiltro && (
          <Box>
            <Box>
              <Button
                size="small"
                color="light"
                onClick={() => {
                  create();
                }}
              >
                Aggiungi alloggiamento filtro
              </Button>
            </Box>

            {errors.root?.message && (
              <Alert sx={{ mt: 1 }} severity="warning">
                {errors.root?.message}
              </Alert>
            )}

            <Table
              columns={columns}
              data={data}
              sortable={false}
              initialSort={[
                { id: IS_NEW_KEY, desc: false },
                { id: "nome", desc: false },
              ]}
              isRowDeleted={isRowDeleted}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          size="small"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          Salva
        </Button>
        {onClose && (
          <Button size="small" color="light" onClick={handleClose}>
            Chiudi
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(AlloggiamentiFiltroDialog);
