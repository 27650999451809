import Typography from "@/elements/Typography";
import Box from "@/elements/Box";

export default function NotFoundPage() {
  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Pagina non trovata
        </Typography>
      </Box>
    </Box>
  );
}
