import { FocusEventHandler, forwardRef, useState } from "react";
import { CentraleDto, useGetCentrali } from "@/api";
import { AsyncAutocomplete, FetchOptions } from "../../elements/Autocomplete";

const AllCentralePicker = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      placeholder,
      extra,
      aziendaId,
    }: {
      repartoId?: number;
      value?: CentraleDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: CentraleDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      placeholder?: string;
      extra: any;
      aziendaId: number;
    },
    ref
  ) => {
    const _useGetCentrali: FetchOptions<CentraleDto> = (options) =>
      useGetCentrali({ aziendaId: aziendaId }, options);

    return (
      <>
        <AsyncAutocomplete<CentraleDto>
          ref={ref}
          onChange={onChange}
          fetchOptions={_useGetCentrali}
          getOptionLabel={(s) => s.nome}
          value={value}
          onBlur={onBlur}
          name={name}
          disabled={false}
          placeholder={placeholder}
          extra={extra}
        />
      </>
    );
  }
);

export default AllCentralePicker;
