import React from "react";

import {
  RowData,
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  Row,
} from "@tanstack/react-table";
import Box from "../Box";
import {
  TableContainer,
  Table as MuiTable,
  TableRow,
  TableCell,
  Icon,
  TableBody,
  TablePagination,
} from "@mui/material";
import colors from "@/theme/base/colors";
import typography from "@/theme/base/typography";
import borders from "@/theme/base/borders";
import classNames from "classnames";
import "./index.scss";

const { light } = colors;
const { size, fontWeightBold } = typography;
const { borderWidth } = borders;

export const SortUpIcon = (
  <Icon sx={{ fontSize: "20px !important", my: "-4px" }}>arrow_drop_up</Icon>
);
export const SortDownIcon = (
  <Icon sx={{ fontSize: "20px !important", my: "-4px" }}>arrow_drop_down</Icon>
);

function Table<TData extends RowData>({
  columns,
  data,
  paginated = true,
  sortable = true,
  globalFilter,
  fixedColumnWidth = false,
  initialSort = [],
  isRowDeleted,
  noRowsText = "Nessuna riga",
  mt = 3,
}: {
  columns: ColumnDef<TData, any>[];
  data: TData[];
  paginated?: boolean;
  sortable?: boolean;
  globalFilter?: string | null;
  fixedColumnWidth?: boolean;
  initialSort?: SortingState;
  isRowDeleted?: (row: Row<TData>) => boolean;
  noRowsText?: string;
  mt?: number;
}) {
  const [sorting, setSorting] = React.useState<SortingState>(initialSort);

  const table = useReactTable<TData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: paginated ? getPaginationRowModel() : undefined,
    filterFns: {
      // fuzzy: fuzzyFilter,
    },
    state: {
      // columnFilters,
      sorting,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    getRowId(originalRow, index, parent) {
      var id =
        typeof (originalRow as any).key !== "undefined"
          ? `key-${(originalRow as any).key}`
          : typeof (originalRow as any).id !== "undefined"
          ? `id-${(originalRow as any).id}`
          : typeof (originalRow as any)._id !== "undefined"
          ? `_id-${(originalRow as any)._id}`
          : index;
      return `${parent ? [parent.id, id].join(".") : id}`;
    },
    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: fuzzyFilter,
  });

  return (
    <div
    // sx={{
    //   "& .MuiTableRow-root:not(:last-child)": {
    //     "& td": {
    //       borderBottom: ({ borders: { borderWidth, borderColor } }: any) =>
    //         `${borderWidth[1]} solid ${borderColor}`,
    //     },
    //   },
    // }}
    >
      <TableContainer
        sx={{
          mt,
          minWidth: 0,
          width: "auto",
        }}
      >
        <MuiTable
          style={{
            width: fixedColumnWidth ? table.getCenterTotalSize() : undefined,
          }}
        >
          <Box component="thead">
            {/* <TableHead> */}
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Box
                    key={header.id}
                    component="th"
                    // colSpan={header.colSpan}
                    width={
                      fixedColumnWidth
                        ? header.getSize()
                        : header.column.columnDef.size || "auto"
                    }
                    maxWidth={header.column.columnDef.maxSize}
                    pt={1.5}
                    pb={1.25}
                    pl={2}
                    pr={2}
                    // pl={align === "left" ? pl : 3}
                    // pr={align === "right" ? pr : 3}
                    // textAlign={align}
                    fontSize={size.xs}
                    fontWeight={fontWeightBold}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borderWidth[1]} solid ${light.main}`}
                    onClick={
                      sortable
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                  >
                    {/* <TableCell
                    key={header.id}
                    style={{
                      width: fixedColumnWidth ? header.getSize() : undefined,
                    }}
                    onClick={
                      sortable
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                  > */}
                    {header.isPlaceholder ? null : (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {sortable
                          ? {
                              asc: SortUpIcon,
                              desc: SortDownIcon,
                            }[header.column.getIsSorted() as string] ?? null
                          : null}
                      </Box>
                    )}
                    {/* </TableCell> */}
                  </Box>
                ))}
              </TableRow>
            ))}
            {/* </TableHead> */}
          </Box>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className={classNames(isRowDeleted?.(row) && "deleted-row")}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} sx={{ py: 1 }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {table.getRowModel().rows.length === 0 && (
              <TableRow style={{ pointerEvents: "none" }}>
                <TableCell colSpan={table.getVisibleFlatColumns().length}>
                  {noRowsText}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {paginated && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          pt={2}
        >
          <TablePagination
            component="div"
            page={table.getState().pagination.pageIndex}
            count={table.getPrePaginationRowModel().rows.length}
            onPageChange={(event, page) => {
              console.log("onPageChange", event, page);
              table.setPageIndex(page);
            }}
            rowsPerPage={table.getState().pagination.pageSize}
            rowsPerPageOptions={[10, 25, 100]}
            onRowsPerPageChange={(e) =>
              table.setPageSize(Number(e.target.value))
            }
          />
        </Box>
      )}
    </div>
  );
}

export default Table;
