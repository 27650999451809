import { _dispositivoTypeUtilsData } from "./dispositivoTypeUtilsData";
import { DispositivoType } from "@/api";

export interface DispositivoTypeHelper {
  label: string;
}

const dispositivoTypesHelper: {
  [type in DispositivoType]: DispositivoTypeHelper;
} = {
  unknown: generateHelper(_dispositivoTypeUtilsData.Unknown),
  macchinario: generateHelper(_dispositivoTypeUtilsData.Macchinario),
  sensore: generateHelper(_dispositivoTypeUtilsData.Sensore),
} as const;

export const dispositivoTypes = Object.keys(
  dispositivoTypesHelper
) as DispositivoType[];

export function getDispositivoTypeHelper(
  type: DispositivoType
): DispositivoTypeHelper | null {
  // if (type === "unknown") {
  //   return null;
  // }
  const h = dispositivoTypesHelper[type];
  if (!h) {
    console.error("[DispositivoTypeHelper] Unknown type: " + type);
  }
  return h || null;
}

// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

function generateHelper(
  d: (typeof _dispositivoTypeUtilsData)[keyof typeof _dispositivoTypeUtilsData]
): DispositivoTypeHelper {
  return {
    label: d.label,
  };
}

export function getDispositivoTypeLabel(type: DispositivoType): string {
  return getDispositivoTypeHelper(type)!.label;
}
