import { FocusEventHandler, forwardRef, useState } from "react";
import { StabilimentoDto, useGetStabilimenti } from "@/api";
import StabilimentoDialog from "@/components/StabilimentoDialog";
import { AsyncAutocomplete, FetchOptions } from "../../elements/Autocomplete";

const StabilimentoPicker = forwardRef(
  (
    {
      aziendaId,
      value,
      onChange,
      onBlur,
      name,
      canCreateStabilimento = true,
      placeholder,
      size,
    }: {
      aziendaId?: number;
      value?: StabilimentoDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: StabilimentoDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      canCreateStabilimento?: boolean;
      placeholder?: string;
      size?: "small" | "medium";
    },
    ref
  ) => {
    const disabled = !aziendaId;

    const [
      createStabilimentoDialogOptions,
      setCreateStabilimentoDialogOptions,
    ] = useState<{
      open: boolean;
      data: StabilimentoDto | null;
    }>({
      open: false,
      data: null,
    });

    const onCreateStabilimentoClose = (
      stabilimento: StabilimentoDto | null
    ) => {
      onChange?.(null, stabilimento);
      setCreateStabilimentoDialogOptions({ open: false, data: null });
    };

    function onNewOptionSelected(nome: string) {
      setCreateStabilimentoDialogOptions({
        open: true,
        data: {
          id: createStabilimentoDialogOptions.data?.id ?? 0,
          aziendaId: aziendaId ?? 0,
          nome: nome,
        },
      });
    }

    const _useGetStabilimenti: FetchOptions<StabilimentoDto> = (options) =>
      useGetStabilimenti({ aziendaId }, options);

    return (
      <>
        <AsyncAutocomplete<StabilimentoDto>
          ref={ref}
          onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          fetchOptions={_useGetStabilimenti}
          getOptionLabel={(s) => s.nome}
          canCreateNewOption={canCreateStabilimento}
          value={value}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          size={size}
        />
        {canCreateStabilimento && aziendaId && (
          <StabilimentoDialog
            open={createStabilimentoDialogOptions.open}
            onClose={onCreateStabilimentoClose}
            initialData={{
              ...createStabilimentoDialogOptions.data,
            }}
          />
        )}
      </>
    );
  }
);

export default StabilimentoPicker;
