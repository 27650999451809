import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { proprietaMisurataIcons } from "@/utils/proprietaMisurataIcons";
import {
  getProprietaMisurataHelper,
  isDanger,
  isOld,
} from "@/utils/proprietaMisurataUtils";
import { Card, Icon, SvgIcon } from "@mui/material";
import { ComponentProps } from "react";
import typography from "@/theme/base/typography";
import moment from "moment";
import configs from "./configs";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  ArcElement,
} from "chart.js";
import Button from "@/elements/Button";
import { ProprietaMisurataDto, ProprietaMisurataValueDto } from "@/api";

ChartJS.register(
  LineController,
  LineElement,
  ArcElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale
);

function ProprietaMisurataCard({
  proprietaMisurata,
  value,
  openHistoryDialog,
}: {
  proprietaMisurata: ProprietaMisurataDto;
  value: ProprietaMisurataValueDto | null;
  openHistoryDialog?: () => void;
}) {
  const { size } = typography;

  const type = getProprietaMisurataHelper(proprietaMisurata.type);
  const Icon_ = proprietaMisurataIcons[proprietaMisurata.type];

  if (!type || !Icon_) {
    return null;
  }

  const valueText =
    typeof value?.value === "number" ? type.format(value.value) : "-";
  const label = type.label;
  const perc =
    typeof value?.value === "number" ? type.getPercent(value.value) : undefined;

  const danger = isDanger(value);
  const oldValue = isOld(value);

  const color: ComponentProps<typeof Typography>["color"] = danger
    ? "error"
    : "success";

  const { data, options } = configs([], {
    label: "a",
    data: perc ? [perc, 100 - perc] : [0, 100],
    backgroundColors: [danger ? "error" : "success", "light"],
  });

  return (
    <Card sx={{ maxWidth: 400 }}>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        flex={1}
      >
        <Typography
          variant="body2"
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
        >
          {label}
        </Typography>

        <Box height={200}>
          <Doughnut data={data} options={options} />
        </Box>

        <Box display="flex" alignItems="center" mb={0.5}>
          <Box
            width="1.25rem"
            height="1.25rem"
            color="grey-400"
            fontSize={size.lg}
            display="flex"
            justifyContent="center"
            alignItems="center"
            mr={1}
            variant="contained"
            ml={danger ? 4 : 0}
          >
            <SvgIcon>{Icon_()}</SvgIcon>
          </Box>

          <Typography
            variant="h4"
            fontWeight="bold"
            color={typeof value?.value === "number" ? color : "warning"}
          >
            {valueText}
          </Typography>

          {danger && (
            <Typography variant="h4" color={color}>
              <Icon sx={{ mt: 1, ml: 2 }}>warning</Icon>
            </Typography>
          )}
        </Box>

        {oldValue && (
          <Box>
            <Typography variant="caption" fontWeight="bold" color="warning">
              {value
                ? `Ultimo rilevamento: ${moment(value.timestamp).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}`
                : "Dato non rilevato"}
            </Typography>
          </Box>
        )}

        {value && (
          <Box width="100%" display="flex" flexDirection="row" mt="auto">
            <Button
              variant="textArrow"
              color="text"
              sx={{ ml: "auto", mr: 0, mt: 1, mb: -1 }}
              onClick={openHistoryDialog}
            >
              Vedi storico
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default ProprietaMisurataCard;
