import colors from "@/theme/base/colors";
import { Box, IconButton } from "@mui/material";
import { useRef } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

export default function ImageAdd({
  handleImageUpload,
}: {
  handleImageUpload: any;
}) {
  const fileInputRef = useRef<any>(null);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        objectFit: "contain",
        border: `1px solid ${colors.inputColors.borderColor.main}`,
        borderRadius: "8px",
        padding: "16px",
      }}
    >
      <IconButton
        onClick={() => {
          fileInputRef.current?.click();
        }}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <AddPhotoAlternateIcon
          sx={{
            width: "100%",
            height: "100%",
            color: colors.inputColors.borderColor.main,
          }}
        ></AddPhotoAlternateIcon>
      </IconButton>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => {
          handleImageUpload(e);
          fileInputRef.current.value = null;
        }}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
    </Box>
  );
}
