import {
  BonificaDto,
  BonificaImmagineDto,
  CentraleDto,
  getDownloadFileQueryKey,
  getDownloadRapportinoBonificaQueryKey,
  getGetCentraleQueryKey,
  useUpdateBonifiche,
} from "@/api";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import Table from "@/elements/Table";
import moment from "moment/moment";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import Button from "@/elements/Button";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BonificaDialog from "@/components/BonificaDialog";
import FileDownload from "@/components/FileField/FileDownload";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import Image from "../Images/Image";

const columnHelper = createColumnHelper<BonificaDto>();

export default function BonificheTableCard({
  centrale,
  bonifiche,
  canEdit = false,
}: {
  centrale: CentraleDto;
  bonifiche: BonificaDto[];
  canEdit?: boolean;
}) {
  const [bonificaDialogOptions, setBonificaDialogOptions] = useState<{
    open: boolean;
    bonifica?: BonificaDto;
  }>({ open: false });

  const editing = canEdit;

  const queryClient = useQueryClient();
  const { mutate } = useUpdateBonifiche();

  const deleteBonifica = useCallback(
    (bonifica: BonificaDto) => {
      const data = moment(bonifica.data).format("L");
      const message = `Sei sicuro di voler eliminare la bonifica eseguita il ${data} con descrizione "${bonifica.descrizione}"?`;
      if (!window.confirm(message)) {
        return;
      }

      mutate(
        {
          id: centrale.id,
          data: [
            {
              id: bonifica.id,
              changeType: "deleted",
              entity: bonifica,
            },
          ],
        },
        {
          onSuccess: () => {
            queryClient
              .invalidateQueries(getGetCentraleQueryKey(centrale.id))
              .then(() => {
                toast.success("Bonifica eliminata");
              });
          },
          onError: () => {
            toast.error("Errore durante l'eliminazione della bonifica");
          },
        }
      );
    },
    [queryClient, centrale.id, mutate]
  );

  const columns = useMemo<ColumnDef<BonificaDto, any>[]>(() => {
    const columns = [
      columnHelper.accessor("data", {
        header: "Data",
        cell: ({ row }) => moment(row.original.data).format("L"),
      }),

      columnHelper.accessor("scadenza", {
        header: "Scade il",
        cell: ({ row }) =>
          row.original.scadenza && moment(row.original.scadenza).format("L"),
      }),

      columnHelper.accessor("descrizione", {
        header: "Descrizione",
        minSize: 200,
      }),

      columnHelper.accessor("bonificaImmagini", {
        header: "Immagini",
        minSize: 200,
        cell: ({ row }) => {
          if (row.original.bonificaImmagini?.length === 0) {
            return "Nessuna immagine";
          } else {
            return (
              <Card
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 1,
                  borderRadius: 0,
                }}
              >
                {row.original.bonificaImmagini.map(
                  (immagine: BonificaImmagineDto, i: number) => (
                    <Image
                      key={immagine.id}
                      src={getDownloadFileQueryKey(immagine.uploadedFileId!)[0]}
                      alt={"immagine " + i}
                      canDelete={false}
                    />
                  )
                )}
              </Card>
            );
          }
        },
      }),

      columnHelper.display({
        header: "Rapportino",
        cell: ({ row }) => (
          <FileDownload
            file={row.original.fileRapportino}
            downloadUrl={
              getDownloadRapportinoBonificaQueryKey(
                centrale.id,
                row.original.id
              )[0]
            }
          />
        ),
      }),

      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 100,
          maxSize: 100,
          cell: ({ row }) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                gap={1}
              >
                <Tooltip title="Elimina">
                  <IconButton
                    onClick={() => {
                      deleteBonifica(row.original);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Modifica">
                  <IconButton
                    onClick={() => {
                      setBonificaDialogOptions({
                        open: true,
                        bonifica: row.original,
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [editing, centrale.id, deleteBonifica]);

  return (
    <>
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%" gap={1}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={1}
          >
            <Typography variant="h5" color="text" fontWeight="medium">
              Storico bonifiche
            </Typography>

            {editing && (
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  setBonificaDialogOptions({ open: true });
                }}
              >
                Aggiungi
              </Button>
            )}
          </Box>

          <Table
            columns={columns}
            data={bonifiche}
            initialSort={[{ id: "data", desc: true }]}
            noRowsText="Nessuna bonifica"
            mt={1}
          />
        </Box>
      </Box>

      <BonificaDialog
        centrale={centrale}
        open={bonificaDialogOptions.open}
        bonifica={bonificaDialogOptions.bonifica}
        onClose={() => {
          setBonificaDialogOptions({ open: false });
        }}
      />
    </>
  );
}
