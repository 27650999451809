import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Grid from "@mui/material/Grid";
import { CentraleDto, useGetDispositivi } from "@/api";
import DispositivoCard from "@/components/DispositivoCard";

export default function MacchinariSensoriTable({
  centrale,
}: {
  centrale: CentraleDto;
}) {
  const { isLoading, error, data } = useGetDispositivi();

  const dispositivi =
    data?.data?.filter((d) => d.posizione?.centrale?.id === centrale.id) ?? [];

  return (
    <Box p={2} flex={1}>
      <Box display="flex" flexDirection="column" height="100%" gap={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={1}
        >
          <Typography variant="h5" color="text" fontWeight="medium">
            Macchinari/Sensori
          </Typography>
        </Box>
      </Box>

      <Grid container mt={2} mb={0} spacing={2}>
        {dispositivi.map((dispositivo) => {
          return (
            <Grid item key={dispositivo.id} xs={12} md={6} lg={4}>
              <DispositivoCard
                dispositivo={dispositivo}
                proprietaMisurate={dispositivo.proprietaMisurate || []}
                showPosizione={false}
              />
            </Grid>
          );
        })}
      </Grid>

      {dispositivi.length === 0 && (
        <Typography mt={2} variant="body2">
          {isLoading
            ? "Caricamento dispositivi in corso ..."
            : "Nessun dispositivo"}
        </Typography>
      )}
    </Box>
  );
}
