import { _filtroTypeUtilsData } from "./filtroTypeUtilsData";
import { FiltroType } from "@/api";

export interface FiltroTypeHelper {
  label: string;
  icon: string;
}

const filtroTypesHelper: {
  [type in FiltroType]: FiltroTypeHelper;
} = {
  olio: generateHelper(_filtroTypeUtilsData.Olio),
  aria: generateHelper(_filtroTypeUtilsData.Aria),
} as const;

export const filtroTypes = Object.keys(filtroTypesHelper) as FiltroType[];

export function getFiltroTypeHelper(type: FiltroType): FiltroTypeHelper | null {
  // if (type === "unknown") {
  //   return null;
  // }
  const h = filtroTypesHelper[type];
  if (!h) {
    console.error("[FiltroTypeHelper] Unknown type: " + type);
  }
  return h || null;
}

// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

function generateHelper(
  d: (typeof _filtroTypeUtilsData)[keyof typeof _filtroTypeUtilsData]
): FiltroTypeHelper {
  return {
    label: d.Label,
    icon: d.Icon,
  };
}
