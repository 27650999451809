import Box from "@/elements/Box";
import Sidenav from "@/components/Sidenav";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import Navbar from "../Navbar";
import { useSidebarRoutes } from "./useSidebarRoutes";

type MainLayoutContextType = {
  miniSidenav: boolean;
  setMiniSidenav: (miniSidenav: boolean) => void;
};

const initialState: MainLayoutContextType = {
  miniSidenav: false,
  setMiniSidenav: () => {},
};

const MainLayoutContext = createContext<MainLayoutContextType>(initialState);

function MainLayoutContextProvider({ children }: { children: ReactNode }) {
  const [miniSidenav, setMiniSidenav] = useState<boolean>(false);

  const value: MainLayoutContextType = useMemo(
    () => ({ miniSidenav, setMiniSidenav }),
    [miniSidenav, setMiniSidenav]
  );

  return (
    <MainLayoutContext.Provider value={value}>
      {children}
    </MainLayoutContext.Provider>
  );
}

export function useMainLayoutContext() {
  const context = useContext(MainLayoutContext);
  return context;
}

function MainLayout({ children }: { children: ReactNode }) {
  const sidenavColor = "primary";
  const routes = useSidebarRoutes();

  return (
    <MainLayoutContextProvider>
      <Sidenav
        color={sidenavColor}
        routes={routes}
        // onMouseEnter={handleOnMouseEnter}
        // onMouseLeave={handleOnMouseLeave}
      />
      <MainLayoutContent>
        <Navbar />
        <Box sx={{ px: 2 }}>{children}</Box>
      </MainLayoutContent>
    </MainLayoutContextProvider>
  );
}

function MainLayoutContent({ children }: { children: ReactNode }) {
  const { miniSidenav } = useMainLayoutContext();

  return (
    <Box
      sx={({ breakpoints, transitions, ...rest }) => {
        const {
          functions: { pxToRem },
        } = rest as any;
        return {
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        };
      }}
    >
      {children}
    </Box>
  );
}

export default MainLayout;
