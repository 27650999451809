import Card from "@mui/material/Card";
import {
  BonificaImmagineDto,
  getDownloadFileQueryKey,
  useUploadFile,
} from "@/api";
import { useMemo } from "react";
import { toast } from "react-toastify";
import Image from "../Images/Image";
import ImageAdd from "../Images/ImageAdd";

export default function BonificaImmaginiDialog({
  value: immagini = [],
  onChange,
}: {
  value: BonificaImmagineDto[];
  onChange: (value: BonificaImmagineDto[]) => void;
}) {
  const { mutateAsync } = useUploadFile();

  const immaginiVuote = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 2 - immagini.length; i++) {
      arr.push(i);
    }
    return arr;
  }, [immagini]);

  const handleImageUpload = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        let result = await mutateAsync({
          data: { file },
        });
        onChange([...immagini, { uploadedFileId: result.data.id }]);
      } catch (error: any) {
        handleUploadError(error.status);
      }
    }
  };

  const handleImageDelete = async (immagine: BonificaImmagineDto) => {
    onChange(immagini.filter((img) => img.id !== immagine.id));
  };

  function handleUploadError(status?: number | undefined) {
    const msg =
      status === 413
        ? "Errore: il file è troppo grande"
        : "Errore durante il caricamento del file";
    toast.error(msg);
  }

  return (
    <Card
      sx={{
        minWidth: {
          xs: "100%",
        },
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        padding: 2,
        gap: 2,
      }}
    >
      {immagini.map((immagine: BonificaImmagineDto, i: number) => (
        <Image
          key={immagine.id}
          src={getDownloadFileQueryKey(immagine.uploadedFileId!)[0]}
          alt={"immagine " + i}
          handleImageDelete={() => handleImageDelete(immagine)}
        />
      ))}
      {immaginiVuote.map((_, i) => (
        <ImageAdd key={i} handleImageUpload={handleImageUpload} />
      ))}
    </Card>
  );
}
