/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  RepartoDto,
  GetRepartiParams,
  RepartoCreationDto,
} from ".././models";

export const getReparti = (
  params?: GetRepartiParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<RepartoDto[]>> => {
  return axios.get(`/api/reparti`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetRepartiQueryKey = (params?: GetRepartiParams) =>
  [`/api/reparti`, ...(params ? [params] : [])] as const;

export const getGetRepartiQueryOptions = <
  TData = Awaited<ReturnType<typeof getReparti>>,
  TError = AxiosError<unknown>
>(
  params?: GetRepartiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getReparti>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getReparti>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRepartiQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReparti>>> = ({
    signal,
  }) => getReparti(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetRepartiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReparti>>
>;
export type GetRepartiQueryError = AxiosError<unknown>;

export const useGetReparti = <
  TData = Awaited<ReturnType<typeof getReparti>>,
  TError = AxiosError<unknown>
>(
  params?: GetRepartiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getReparti>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRepartiQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createReparti = (
  repartoCreationDto: RepartoCreationDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<RepartoDto>> => {
  return axios.post(`/api/reparti`, repartoCreationDto, options);
};

export const getCreateRepartiMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReparti>>,
    TError,
    { data: RepartoCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReparti>>,
  TError,
  { data: RepartoCreationDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReparti>>,
    { data: RepartoCreationDto }
  > = (props) => {
    const { data } = props ?? {};

    return createReparti(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateRepartiMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReparti>>
>;
export type CreateRepartiMutationBody = RepartoCreationDto;
export type CreateRepartiMutationError = AxiosError<unknown>;

export const useCreateReparti = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReparti>>,
    TError,
    { data: RepartoCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateRepartiMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetReparti = () =>
  //@ts-ignore
  useGetReparti(...([] as any[]));

export const __$$useCreateReparti = () =>
  //@ts-ignore
  useCreateReparti(...([] as any[]));
