/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */

export type ProprietaMisurataDataInterval =
  (typeof ProprietaMisurataDataInterval)[keyof typeof ProprietaMisurataDataInterval];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProprietaMisurataDataInterval = {
  "1h": "1h",
  "3h": "3h",
  "6h": "6h",
  "12h": "12h",
  "24h": "24h",
  "2d": "2d",
  "7d": "7d",
  "30d": "30d",
} as const;
