/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode, forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftInput
import SoftInputRoot from "./SoftInputRoot";
import SoftInputWithIconRoot from "./SoftInputWithIconRoot";
import SoftInputIconBoxRoot from "./SoftInputIconBoxRoot";
import SoftInputIconRoot from "./SoftInputIconRoot";

import { InputProps as MuiInputProps } from "@mui/material/Input";

const direction = "ltr";

interface InputProps extends MuiInputProps {
  icon?: {
    component: ReactNode | boolean;
    direction: "left" | "right";
  } | null;
  success?: boolean;
}

const Input = forwardRef<any, InputProps>(
  ({ size, icon, error, success, disabled, ...rest }, ref) => {
    let template;
    const iconDirection = icon?.direction || "none";
    const iconComponent = icon?.component;

    if (iconComponent && icon.direction === "left") {
      template = (
        <SoftInputWithIconRoot
          ref={ref}
          //@ts-ignore
          ownerState={{ error, success, disabled }}
        >
          <SoftInputIconBoxRoot
            //@ts-ignore
            ownerState={{ size }}
          >
            <SoftInputIconRoot
              fontSize="small"
              //@ts-ignore
              ownerState={{ size }}
            >
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
          <SoftInputRoot
            {...rest}
            //@ts-ignore
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
          />
        </SoftInputWithIconRoot>
      );
    } else if (iconComponent && iconDirection === "right") {
      template = (
        <SoftInputWithIconRoot
          ref={ref}
          //@ts-ignore
          ownerState={{ error, success, disabled }}
        >
          <SoftInputRoot
            {...rest}
            //@ts-ignore
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
          />
          <SoftInputIconBoxRoot
            //@ts-ignore
            ownerState={{ size }}
          >
            <SoftInputIconRoot
              fontSize="small"
              //@ts-ignore
              ownerState={{ size }}
            >
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
        </SoftInputWithIconRoot>
      );
    } else {
      template = (
        <SoftInputRoot
          {...rest}
          ref={ref}
          //@ts-ignore
          ownerState={{ size, error, success, disabled }}
        />
      );
    }

    return template;
  }
);

export default Input;
