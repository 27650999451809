/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  ModelloOlioDto,
  ModelloOlioUpdateDtoInt32EntityChange,
  ModelloOlioCreateDto,
  ModelloOlioUpdateDto,
} from ".././models";

export const getModelliOlio = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModelloOlioDto[]>> => {
  return axios.get(`/api/modelliOlio`, options);
};

export const getGetModelliOlioQueryKey = () => [`/api/modelliOlio`] as const;

export const getGetModelliOlioQueryOptions = <
  TData = Awaited<ReturnType<typeof getModelliOlio>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getModelliOlio>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getModelliOlio>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModelliOlioQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelliOlio>>> = ({
    signal,
  }) => getModelliOlio({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetModelliOlioQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModelliOlio>>
>;
export type GetModelliOlioQueryError = AxiosError<unknown>;

export const useGetModelliOlio = <
  TData = Awaited<ReturnType<typeof getModelliOlio>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getModelliOlio>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetModelliOlioQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateModelliOlio = (
  modelloOlioUpdateDtoInt32EntityChange: ModelloOlioUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/api/modelliOlio`,
    modelloOlioUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateModelliOlioMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelliOlio>>,
    TError,
    { data: ModelloOlioUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateModelliOlio>>,
  TError,
  { data: ModelloOlioUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateModelliOlio>>,
    { data: ModelloOlioUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { data } = props ?? {};

    return updateModelliOlio(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModelliOlioMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateModelliOlio>>
>;
export type UpdateModelliOlioMutationBody =
  ModelloOlioUpdateDtoInt32EntityChange[];
export type UpdateModelliOlioMutationError = AxiosError<unknown>;

export const useUpdateModelliOlio = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelliOlio>>,
    TError,
    { data: ModelloOlioUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateModelliOlioMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createModelloOlio = (
  modelloOlioCreateDto: ModelloOlioCreateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModelloOlioDto>> => {
  return axios.post(`/api/modelliOlio`, modelloOlioCreateDto, options);
};

export const getCreateModelloOlioMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createModelloOlio>>,
    TError,
    { data: ModelloOlioCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createModelloOlio>>,
  TError,
  { data: ModelloOlioCreateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createModelloOlio>>,
    { data: ModelloOlioCreateDto }
  > = (props) => {
    const { data } = props ?? {};

    return createModelloOlio(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateModelloOlioMutationResult = NonNullable<
  Awaited<ReturnType<typeof createModelloOlio>>
>;
export type CreateModelloOlioMutationBody = ModelloOlioCreateDto;
export type CreateModelloOlioMutationError = AxiosError<unknown>;

export const useCreateModelloOlio = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createModelloOlio>>,
    TError,
    { data: ModelloOlioCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateModelloOlioMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateModelloOlio = (
  id: number,
  modelloOlioUpdateDto: ModelloOlioUpdateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModelloOlioDto>> => {
  return axios.put(`/api/modelliOlio/${id}`, modelloOlioUpdateDto, options);
};

export const getUpdateModelloOlioMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelloOlio>>,
    TError,
    { id: number; data: ModelloOlioUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateModelloOlio>>,
  TError,
  { id: number; data: ModelloOlioUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateModelloOlio>>,
    { id: number; data: ModelloOlioUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateModelloOlio(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModelloOlioMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateModelloOlio>>
>;
export type UpdateModelloOlioMutationBody = ModelloOlioUpdateDto;
export type UpdateModelloOlioMutationError = AxiosError<unknown>;

export const useUpdateModelloOlio = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelloOlio>>,
    TError,
    { id: number; data: ModelloOlioUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateModelloOlioMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetModelliOlio = () =>
  //@ts-ignore
  useGetModelliOlio(...([] as any[]));

export const __$$useUpdateModelliOlio = () =>
  //@ts-ignore
  useUpdateModelliOlio(...([] as any[]));

export const __$$useCreateModelloOlio = () =>
  //@ts-ignore
  useCreateModelloOlio(...([] as any[]));

export const __$$useUpdateModelloOlio = () =>
  //@ts-ignore
  useUpdateModelloOlio(...([] as any[]));
