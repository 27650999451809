/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import colors from "@/theme/base/colors";

// // Soft UI Dashboard React examples
// import Breadcrumbs from "examples/Breadcrumbs";
// import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "./styles";
import { useMainLayoutContext } from "../MainLayout";
import Box from "@/elements/Box";
import Input from "@/elements/Input";
import {
  Avatar,
  Divider,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import { useAuth } from "@/context/useAuth";
import { MIN_WIDTH_FIXED_SIDEBAR } from "../Sidenav";
import { useLogout } from "@/api";

const fixedNavbar = true;
const navbarType = "sticky"; //"static"

function Navbar({ absolute = false, light = false, isMini = false }) {
  const [transparentNavbar, setTransparentNavbar] = useState(true);
  const location = useLocation();
  // const route = location.pathname.split("/").slice(1);
  const { miniSidenav, setMiniSidenav } = useMainLayoutContext();

  const { user, onLogout } = useAuth();
  const { isLoading, mutate: doLogout } = useLogout();

  const logout = () => {
    doLogout();
    onLogout();
  };

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const accountMenuIsOpen = Boolean(accountMenuAnchorEl);

  const openAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const closeAccountMenu = () => {
    setAccountMenuAnchorEl(null);
  };

  useEffect(() => {
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(!miniSidenav);

  useEffect(() => {
    setMiniSidenav(window.innerWidth < MIN_WIDTH_FIXED_SIDEBAR);
  }, [location.pathname, setMiniSidenav]);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={{
        backgroundColor: colors.primary.main,
        px: 2,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        height: "96px",
      }}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            display: { xs: "none", xl: "block" },
          }}
        >
          <img src="/onde-headbar.svg" style={{ maxHeight: "90px" }} />
        </Box>

        <Box
          // color="inherit"
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <IconButton
            size="small"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon style={{ color: "white" }}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
          {/* <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          /> */}
        </Box>
        {isMini ? null : (
          <Box sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <Box pr={1}>
              <Input
                placeholder="Type here..."
                icon={{ component: "search", direction: "left" }}
              />
            </Box> */}

            <IconButton
              onClick={openAccountMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={accountMenuIsOpen ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={accountMenuIsOpen ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                <Icon>person</Icon>
              </Avatar>
            </IconButton>

            {/* <Box color={light ? "white" : "inherit"}>
              <Link to="/authentication/sign-in">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Sign in
                  </SoftTypography>
                </IconButton>
              </Link>
              
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  notifications
                </Icon>
              </IconButton>
              {renderMenu()}
            </Box> */}
          </Box>
        )}
      </Toolbar>

      <Menu
        anchorEl={accountMenuAnchorEl}
        id="account-menu"
        open={accountMenuIsOpen}
        onClose={closeAccountMenu}
        onClick={closeAccountMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 240,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {user && (
          <MenuItem
            sx={{
              mb: 1,
              pointerEvents: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography variant="subtitle2" color="textPrimary">
              {`${user.name} ${user.lastName}`}
            </Typography>
            <Typography variant="caption" color="gray">
              {`${user.email}`}
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={closeAccountMenu} component={Link} to="/profilo">
          <ListItemIcon>
            <Icon fontSize="small">manage_accounts</Icon>
          </ListItemIcon>
          Profilo
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <Icon fontSize="small">logout</Icon>
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
Navbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
Navbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default Navbar;
