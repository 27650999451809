import { _reminderTypeUtilsData } from "./reminderTypeUtilsData";
import { ReminderDto } from "@/api";

export type ReminderType = ReminderDto["reminderType"];

export interface ReminderTypeHelper {
  label: string;
}

const reminderTypesHelpers: {
  [type in Exclude<ReminderType, "unknown">]: ReminderTypeHelper;
} = {
  analisi: generateHelper(_reminderTypeUtilsData.Analisi),
  bonifica: generateHelper(_reminderTypeUtilsData.Bonifica),
  filtro: generateHelper(_reminderTypeUtilsData.Filtro),
} as const;

export const reminderTypes = Object.keys(reminderTypesHelpers);

export function getReminderTypeHelper(
  type: ReminderType
): ReminderTypeHelper | null {
  // if (type === "unknown") {
  //   return null;
  // }
  const h = reminderTypesHelpers[type];
  if (!h) {
    console.error("[ReminderTypeHelper] Unknown type: " + type);
  }
  return h || null;
}

// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

function generateHelper(
  d: (typeof _reminderTypeUtilsData)[keyof typeof _reminderTypeUtilsData]
): ReminderTypeHelper {
  return {
    label: d.label,
  };
}
