import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

export interface HistoryIntervalSliderProps {
  fromDate: string;
  toDate: string;
  value: [string, string];
  onChange: (newDateRange: [string, string]) => void;
}

function valuetext(value: number, dates: [moment.Moment, moment.Moment]) {
  const [fromDate, toDate] = dates;
  const rangeInMilliseconds = toDate.diff(fromDate);
  const offset = (rangeInMilliseconds * value) / 100;
  const selectedDate = fromDate.clone().add(offset, "milliseconds");
  const label = selectedDate.format("DD/MM/YYYY HH:mm");
  return label;
}

export default function HistoryIntervalSlider({
  fromDate: _fromDate,
  toDate: _toDate,
  value: controlledValue,
  onChange,
}: HistoryIntervalSliderProps) {
  const fromDate = useMemo(() => moment(_fromDate), [_fromDate]);
  const toDate = useMemo(() => moment(_toDate), [_toDate]);

  const [value, setValue] = useState<[number, number]>([0, 100]);

  const [controlledValue0, controlledValue1] = controlledValue;

  useEffect(() => {
    // Update the local state when the controlledValue prop changes
    const rangeInMilliseconds = toDate.diff(fromDate);
    const minValue = Math.min(
      controlledValue0 === fromDate.toISOString()
        ? 0
        : (moment(controlledValue0).diff(fromDate) * 100) / rangeInMilliseconds,
      100
    );
    const maxValue = Math.max(
      controlledValue1 === toDate.toISOString()
        ? 100
        : (moment(controlledValue1).diff(fromDate) * 100) / rangeInMilliseconds,
      0
    );
    setValue([minValue, maxValue]);
  }, [controlledValue0, controlledValue1, fromDate, toDate]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as [number, number]);
    const [min, max] = newValue as [number, number];
    const rangeInMilliseconds = toDate.diff(fromDate);
    const newFromDate = fromDate
      .clone()
      .add((rangeInMilliseconds * min) / 100, "milliseconds");
    const newToDate = fromDate
      .clone()
      .add((rangeInMilliseconds * max) / 100, "milliseconds");
    onChange([newFromDate.toISOString(), newToDate.toISOString()]);
  };

  return (
    <Box sx={{ width: 300, pt: "30px", px: "100px" }} flex={1}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => valuetext(value, [fromDate, toDate])}
      />
    </Box>
  );
}
