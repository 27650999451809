/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { BonificaDto } from ".././models";

export const getBonifiche = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<BonificaDto[]>> => {
  return axios.get(`/api/bonifiche`, options);
};

export const getGetBonificheQueryKey = () => [`/api/bonifiche`] as const;

export const getGetBonificheQueryOptions = <
  TData = Awaited<ReturnType<typeof getBonifiche>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getBonifiche>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<Awaited<ReturnType<typeof getBonifiche>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBonificheQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBonifiche>>> = ({
    signal,
  }) => getBonifiche({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBonificheQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBonifiche>>
>;
export type GetBonificheQueryError = AxiosError<unknown>;

export const useGetBonifiche = <
  TData = Awaited<ReturnType<typeof getBonifiche>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getBonifiche>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBonificheQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useGetBonifiche = () =>
  //@ts-ignore
  useGetBonifiche(...([] as any[]));
