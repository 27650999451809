import Loading from "../Loading";
import LoadingError from "../LoadingError";
import { AziendaDetails } from "../AziendaDetailsPage";
import { useGetUserAzienda } from "@/api";

function HomePage() {
  const { isLoading, error, data } = useGetUserAzienda();

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  return <AziendaDetails azienda={data.data} />;
}

export default HomePage;
