/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { AziendaDto } from ".././models";

export const getUserAzienda = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AziendaDto>> => {
  return axios.get(`/api/home`, options);
};

export const getGetUserAziendaQueryKey = () => [`/api/home`] as const;

export const getGetUserAziendaQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAzienda>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getUserAzienda>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getUserAzienda>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAziendaQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAzienda>>> = ({
    signal,
  }) => getUserAzienda({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetUserAziendaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAzienda>>
>;
export type GetUserAziendaQueryError = AxiosError<unknown>;

export const useGetUserAzienda = <
  TData = Awaited<ReturnType<typeof getUserAzienda>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getUserAzienda>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserAziendaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useGetUserAzienda = () =>
  //@ts-ignore
  useGetUserAzienda(...([] as any[]));
