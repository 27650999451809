/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  ModelloFiltroDto,
  ModelloFiltroUpdateDtoInt32EntityChange,
  ModelloFiltroCreateDto,
  ModelloFiltroUpdateDto,
} from ".././models";

export const getModelliFiltro = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModelloFiltroDto[]>> => {
  return axios.get(`/api/modelliFiltro`, options);
};

export const getGetModelliFiltroQueryKey = () =>
  [`/api/modelliFiltro`] as const;

export const getGetModelliFiltroQueryOptions = <
  TData = Awaited<ReturnType<typeof getModelliFiltro>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getModelliFiltro>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getModelliFiltro>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModelliFiltroQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getModelliFiltro>>
  > = ({ signal }) => getModelliFiltro({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetModelliFiltroQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModelliFiltro>>
>;
export type GetModelliFiltroQueryError = AxiosError<unknown>;

export const useGetModelliFiltro = <
  TData = Awaited<ReturnType<typeof getModelliFiltro>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getModelliFiltro>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetModelliFiltroQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateModelliFiltro = (
  modelloFiltroUpdateDtoInt32EntityChange: ModelloFiltroUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/api/modelliFiltro`,
    modelloFiltroUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateModelliFiltroMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelliFiltro>>,
    TError,
    { data: ModelloFiltroUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateModelliFiltro>>,
  TError,
  { data: ModelloFiltroUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateModelliFiltro>>,
    { data: ModelloFiltroUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { data } = props ?? {};

    return updateModelliFiltro(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModelliFiltroMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateModelliFiltro>>
>;
export type UpdateModelliFiltroMutationBody =
  ModelloFiltroUpdateDtoInt32EntityChange[];
export type UpdateModelliFiltroMutationError = AxiosError<unknown>;

export const useUpdateModelliFiltro = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelliFiltro>>,
    TError,
    { data: ModelloFiltroUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateModelliFiltroMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createModelloFiltro = (
  modelloFiltroCreateDto: ModelloFiltroCreateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModelloFiltroDto>> => {
  return axios.post(`/api/modelliFiltro`, modelloFiltroCreateDto, options);
};

export const getCreateModelloFiltroMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createModelloFiltro>>,
    TError,
    { data: ModelloFiltroCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createModelloFiltro>>,
  TError,
  { data: ModelloFiltroCreateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createModelloFiltro>>,
    { data: ModelloFiltroCreateDto }
  > = (props) => {
    const { data } = props ?? {};

    return createModelloFiltro(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateModelloFiltroMutationResult = NonNullable<
  Awaited<ReturnType<typeof createModelloFiltro>>
>;
export type CreateModelloFiltroMutationBody = ModelloFiltroCreateDto;
export type CreateModelloFiltroMutationError = AxiosError<unknown>;

export const useCreateModelloFiltro = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createModelloFiltro>>,
    TError,
    { data: ModelloFiltroCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateModelloFiltroMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateModelloFiltro = (
  id: number,
  modelloFiltroUpdateDto: ModelloFiltroUpdateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ModelloFiltroDto>> => {
  return axios.put(`/api/modelliFiltro/${id}`, modelloFiltroUpdateDto, options);
};

export const getUpdateModelloFiltroMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelloFiltro>>,
    TError,
    { id: number; data: ModelloFiltroUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateModelloFiltro>>,
  TError,
  { id: number; data: ModelloFiltroUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateModelloFiltro>>,
    { id: number; data: ModelloFiltroUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateModelloFiltro(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModelloFiltroMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateModelloFiltro>>
>;
export type UpdateModelloFiltroMutationBody = ModelloFiltroUpdateDto;
export type UpdateModelloFiltroMutationError = AxiosError<unknown>;

export const useUpdateModelloFiltro = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelloFiltro>>,
    TError,
    { id: number; data: ModelloFiltroUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateModelloFiltroMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetModelliFiltro = () =>
  //@ts-ignore
  useGetModelliFiltro(...([] as any[]));

export const __$$useUpdateModelliFiltro = () =>
  //@ts-ignore
  useUpdateModelliFiltro(...([] as any[]));

export const __$$useCreateModelloFiltro = () =>
  //@ts-ignore
  useCreateModelloFiltro(...([] as any[]));

export const __$$useUpdateModelloFiltro = () =>
  //@ts-ignore
  useUpdateModelloFiltro(...([] as any[]));
