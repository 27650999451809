/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import colors from "@/theme/base/colors";
import typography from "@/theme/base/typography";

import pxToRem from "@/theme/functions/pxToRem";

const { dark } = colors;
const { size, fontWeightBold } = typography;

const formLabel = {
  styleOverrides: {
    root: {
      color: dark.main,
      fontSize: size.sm,
      fontWeight: fontWeightBold,
      transform: `translateY(${pxToRem(1)})`,
    },
  },
};

export default formLabel;
