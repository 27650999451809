import Card from "@mui/material/Card";
import Typography from "@/elements/Typography";
import Box from "@/elements/Box";
import Input from "@/elements/Input";
import { FormHelperText } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { UpdatePasswordRequestDto, useUpdatePassword } from "@/api";
import Button from "@/elements/Button";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import { toast } from "react-toastify";

export default function ModificaPasswordCard() {
  const { isLoading, mutate: doUpdatePassword } = useUpdatePassword();

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    reset,
  } = useForm<UpdatePasswordRequestDto>({
    defaultValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit: SubmitHandler<UpdatePasswordRequestDto> = async (data) => {
    doUpdatePassword(
      { data },
      {
        onSuccess: () => {
          reset();
          toast.success("Password modificata con successo!");
        },
        onError: addServerErrorsFactory(setError),
      }
    );
  };

  return (
    <Card sx={{ maxWidth: 400, minWidth: 300 }}>
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box mb={1} ml={0.5}>
            <Typography variant="body2" color="text" fontWeight="medium">
              Modifica password
            </Typography>
          </Box>

          <Box mb={2}>
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Password attuale
              </Typography>
            </Box>
            <Input
              type="password"
              placeholder="Password attuale"
              {...register("currentPassword")}
              disabled={isLoading}
              error={!!errors.currentPassword?.message}
            />
            {errors.currentPassword?.message ? (
              <FormHelperText error>
                {errors.currentPassword?.message}
              </FormHelperText>
            ) : null}
          </Box>

          <Box mb={2}>
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Nuova password
              </Typography>
            </Box>
            <Input
              type="password"
              placeholder="Nuova password"
              {...register("password")}
              disabled={isLoading}
              error={!!errors.password?.message}
            />
            {errors.password?.message ? (
              <FormHelperText error>{errors.password?.message}</FormHelperText>
            ) : null}
          </Box>

          <Box mb={2}>
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Conferma nuova password
              </Typography>
            </Box>
            <Input
              type="password"
              placeholder="Conferma nuova password"
              {...register("confirmPassword")}
              disabled={isLoading}
              error={!!errors.confirmPassword?.message}
            />
            {errors.confirmPassword?.message ? (
              <FormHelperText error>
                {errors.confirmPassword?.message}
              </FormHelperText>
            ) : null}
          </Box>

          <Box mt={4} mb={1}>
            <Button
              color="secondary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || isLoading}
            >
              Modifica password
            </Button>
            {errors.root?.message ? (
              <FormHelperText error>{errors.root?.message}</FormHelperText>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
