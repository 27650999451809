/**
=========================================================
* Soft UI Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for SoftButton
import SoftButtonRoot from "@/elements/Button/SoftButtonRoot";

import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { Icon } from "@mui/material";

interface ButtonProps extends Omit<MuiButtonProps, "variant" | "color"> {
  variant?: MuiButtonProps["variant"] | "gradient" | "textArrow";
  color?: MuiButtonProps["color"] | "white" | "light" | "dark" | "text";
  circular?: boolean;
  iconOnly?: boolean;
  to?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = "white",
      variant = "contained",
      size = "medium",
      circular = false,
      iconOnly = false,
      children,
      ...rest
    },
    ref
  ) => {
    if (variant === "textArrow") {
      return (
        <SoftButtonRoot
          {...rest}
          ref={ref}
          color="primary"
          variant={"text"}
          size={size}
          //@ts-ignore
          ownerState={{ color, variant: "text", size, circular, iconOnly }}
          sx={{
            mt: "auto",
            mr: "auto",
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "medium",
            textTransform: "none",
            p: 0,
            fontSize: "0.875rem",

            "&:hover": {
              transform: "unset",
            },

            "& .material-icons-round": {
              fontSize: "0.875rem",
              transform: `translate(2px, -0.5px)`,
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover .material-icons-round, &:focus  .material-icons-round": {
              transform: `translate(6px, -0.5px)`,
            },

            ...(rest.sx || {}),
          }}
        >
          {children}
          <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
        </SoftButtonRoot>
      );
    }

    return (
      <SoftButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        //@ts-ignore
        ownerState={{ color, variant, size, circular, iconOnly }}
      >
        {children}
      </SoftButtonRoot>
    );
  }
);

export default Button;
