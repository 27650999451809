import React, {
  ComponentProps,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "./withOpeningReset";
import { SmallLoading } from "@/components/Loading";

type UseConfirmationDialogOptions = {
  title: string;
  message: string;
  okButton?: string;
  okColor?: ComponentProps<typeof Button>["color"];
  cancelButton?: string;
  onConfirm: () => Promise<any> | void;
  onCancel?: () => void;
};

interface UseConfirmationDialogReturnType {
  askConfirmation: (options: UseConfirmationDialogOptions) => void;
  confirmationDialog: ReactElement | null;
}

export const useConfirmationDialog = (): UseConfirmationDialogReturnType => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [config, setConfig] = useState<UseConfirmationDialogOptions>({
    title: "",
    message: "",
    onConfirm: () => {},
  });
  const [loading, setLoading] = useState(false);

  const askConfirmation = useCallback(
    (options: UseConfirmationDialogOptions) => {
      setConfig(options);
      setLoading(false);
      setDialogOpen(true);
    },
    []
  );

  const handleConfirm = useCallback(() => {
    let result = config.onConfirm();
    if (result && typeof result.then === "function") {
      let promise = result;
      setLoading(true);
      setTimeout(() => {
        promise
          .then(() => {
            setDialogOpen(false);
          })
          .catch(() => {
            setDialogOpen(false);
          });
      }, 1000);
      return;
    }
    setDialogOpen(false);
  }, [config]);

  const handleCancel = useCallback(() => {
    config.onCancel?.();
    setDialogOpen(false);
  }, [config]);

  const confirmationDialog = (
    <ConfirmationDialog
      open={dialogOpen}
      title={config.title}
      message={config.message}
      okButton={config.okButton}
      okColor={config.okColor}
      cancelButton={config.cancelButton}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      loading={loading}
    />
  );

  return { askConfirmation, confirmationDialog };
};

interface ConfirmationDialogProps {
  open: boolean;
  onClose?: () => void;
  title: string;
  message: string;
  okButton?: string;
  okColor?: ComponentProps<typeof Button>["color"];
  cancelButton?: string;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
}

const _ConfirmationDialog = ({
  open,
  onClose,
  title,
  message,
  okButton = "Ok",
  okColor = "primary",
  cancelButton = "Annulla",
  onConfirm,
  onCancel,
  loading,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        {loading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          onClick={onConfirm}
          color={okColor}
          size="small"
          disabled={loading}
        >
          {okButton}
        </Button>
        <Button
          onClick={onCancel}
          variant="outlined"
          color="dark"
          size="small"
          disabled={loading}
        >
          {cancelButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationDialog = withOpeningReset(_ConfirmationDialog);
