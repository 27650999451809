import Login from "@/components/Login";
import { useAuth } from "@/context/useAuth";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loading from "./components/Loading";
import DispositiviPage from "./components/DispositiviPage";
import { DispositivoDetailsPage } from "./components/DispositivoDetails";
import MainLayout from "./components/MainLayout";
import UtentiPage from "./components/UtentiPage";
import ClientePage from "./components/ClientePage";
import RuoliPermessiPage from "./components/RuoliPermessiPage";
import AziendePage from "./components/AziendePage";
import AziendaDetailsPage from "./components/AziendaDetailsPage";
import RecuperoPassword from "./components/RecuperoPassword";
import CompletaRecuperoPassword from "@/components/CompletaRecuperoPassword";
import ProfiloPage from "@/components/ProfiloPage";
import NotFoundPage from "@/components/NotFoundPage";
import CoverLayout from "@/elements/CoverLayout";
import ConfigurazionePage from "@/components/ConfigurazionePage";
import {
  CentraleDetailsPageQRCode,
  CentraleDetailsPage,
} from "@/components/CentraleDetailsPage";
import AlloggiamentoFiltroPage from "./components/AlloggiamentoFiltroPage";
import CentraliPage from "@/components/CentraliPage";
import HomePage from "./components/Home";
import CalendarioPage from "./components/CalendarioPage";

export default function App() {
  let { isLoading, user, hasPermission } = useAuth();

  if (isLoading) {
    return (
      <div
        style={{
          marginTop: "calc(50vh - 50px)",
        }}
      >
        <Loading withBranding />
      </div>
    );
  }

  return (
    <Routes>
      {/* LOGIN */}
      <Route path="/login" element={<Login />} />
      <Route path="/recupero-password" element={<RecuperoPassword />} />
      <Route
        path="/completa-recupero-password/:resetPermlink/:token"
        element={<CompletaRecuperoPassword />}
      />

      {/* DASHBOARD PAGES*/}

      <Route
        path="/dispositivi"
        element={
          <RequireAuth>
            <MainLayout>
              <DispositiviPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/profilo"
        element={
          <RequireAuth>
            <MainLayout>
              <ProfiloPage />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/aziende"
        element={
          <RequireAuth>
            <MainLayout>
              <AziendePage />
            </MainLayout>
          </RequireAuth>
        }
      />

      {user?.isEsterno && (
        <Route
          path="/home"
          element={
            <RequireAuth>
              <MainLayout>
                <HomePage />
              </MainLayout>
            </RequireAuth>
          }
        />
      )}

      <Route
        path="/aziende/:id"
        element={
          <RequireAuth>
            <MainLayout>
              <AziendaDetailsPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/utenti"
        element={
          <RequireAuth>
            <MainLayout>
              <UtentiPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/account_aziende"
        element={
          <RequireAuth>
            <MainLayout>
              <ClientePage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/ruoli_permessi"
        element={
          <RequireAuth>
            <MainLayout>
              <RuoliPermessiPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/configurazione"
        element={
          <RequireAuth>
            <MainLayout>
              <ConfigurazionePage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/dispositivo/:id"
        element={
          <RequireAuth>
            <MainLayout>
              <DispositivoDetailsPage />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/lista_centrali/:idAzienda?/:idStabilimento?/:idReparto?"
        element={
          <RequireAuth>
            <MainLayout>
              <CentraliPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/centrali/:id"
        element={
          <RequireAuth>
            <MainLayout>
              <CentraleDetailsPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/qr/centrale/:id"
        element={
          <RequireAuth>
            <MainLayout>
              <CentraleDetailsPageQRCode />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/calendario"
        element={
          <RequireAuth>
            <MainLayout>
              <CalendarioPage />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/qr/filtro/:id"
        element={
          user ? (
            <MainLayout>
              <AlloggiamentoFiltroPage />
            </MainLayout>
          ) : (
            <CoverLayout title="Gatti Future - QR Code" bigContent>
              <AlloggiamentoFiltroPage />
            </CoverLayout>
          )
        }
      />
      <Route
        path="/"
        element={
          user ? (
            user.isEsterno ? (
              <Navigate to="/home" />
            ) : hasPermission("DispositiviRead") ? (
              <Navigate to="/dispositivi" />
            ) : hasPermission("CentraliRead") ? (
              <Navigate to="/centrali" />
            ) : (
              <Navigate to="/not-found" />
            )
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      <Route
        path="*"
        element={
          user ? (
            <MainLayout>
              <NotFoundPage />
            </MainLayout>
          ) : (
            <CoverLayout
              title="Gatti Filtrazioni Lubrificanti"
              description="Pagina non trovata"
              showLogo
            />
          )
        }
      />
    </Routes>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return (
      <Navigate
        to={`/login?redirect=${location.pathname}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}
