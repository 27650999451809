/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { UploadedFileDto, UploadFileBody } from ".././models";

export const uploadFile = (
  uploadFileBody: UploadFileBody,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UploadedFileDto>> => {
  const formData = new FormData();
  if (uploadFileBody.file !== undefined) {
    formData.append("file", uploadFileBody.file);
  }

  return axios.post(`/api/file`, formData, options);
};

export const getUploadFileMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFile>>,
    TError,
    { data: UploadFileBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadFile>>,
  TError,
  { data: UploadFileBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadFile>>,
    { data: UploadFileBody }
  > = (props) => {
    const { data } = props ?? {};

    return uploadFile(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadFile>>
>;
export type UploadFileMutationBody = UploadFileBody;
export type UploadFileMutationError = AxiosError<unknown>;

export const useUploadFile = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFile>>,
    TError,
    { data: UploadFileBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadFile = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/file/${id}`, options);
};

export const getDownloadFileQueryKey = (id: number) =>
  [`/api/file/${id}`] as const;

export const getDownloadFileQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFile>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFile>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<Awaited<ReturnType<typeof downloadFile>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadFileQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFile>>> = ({
    signal,
  }) => downloadFile(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type DownloadFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFile>>
>;
export type DownloadFileQueryError = AxiosError<unknown>;

export const useDownloadFile = <
  TData = Awaited<ReturnType<typeof downloadFile>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFile>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadFileQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useUploadFile = () =>
  //@ts-ignore
  useUploadFile(...([] as any[]));

export const __$$useDownloadFile = () =>
  //@ts-ignore
  useDownloadFile(...([] as any[]));
