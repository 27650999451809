import { TrasferimentoDto } from "@/api";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { Icon } from "@mui/material";
import moment from "moment";
import { Fragment } from "react";

function PosizioneDispositivo({
  posizione,
  showDate = false,
}: {
  posizione: TrasferimentoDto;
  showDate?: boolean;
}) {
  const dateString = getDateString(posizione);

  const parts = [
    posizione.azienda?.nome,
    posizione.stabilimento?.nome,
    posizione.reparto?.nome,
    posizione.centrale?.nome,
  ].filter((x) => x) as string[];

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="caption"
        color="text"
        fontWeight={showDate ? "medium" : "regular"}
        sx={{ display: "inline-flex", flexWrap: "wrap" }}
      >
        {parts.map((part, index) => {
          return (
            <Fragment key={index}>
              {index > 0 && <Separator />}
              {part}
            </Fragment>
          );
        })}
      </Typography>
      {showDate && dateString && (
        <Typography variant="caption" color="text" sx={{ mt: 0.5 }}>
          {dateString}
        </Typography>
      )}
    </Box>
  );
}

export default PosizioneDispositivo;

function Separator() {
  return <Icon sx={{ mx: 0.5 }}>arrow_forward</Icon>;
}

function getDateString(posizione: TrasferimentoDto): string | null {
  const daString = posizione.da
    ? moment(posizione.da).format("DD/MM/YYYY")
    : null;
  const aString = posizione.a ? moment(posizione.a).format("DD/MM/YYYY") : null;

  if (daString && aString) {
    return `dal ${daString} al ${aString}`;
  } else if (daString) {
    return `dal ${daString}`;
  } else if (aString) {
    return `fino al ${aString}`;
  }
  return null;
}
