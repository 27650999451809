/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */

export type DispositivoType =
  (typeof DispositivoType)[keyof typeof DispositivoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DispositivoType = {
  unknown: "unknown",
  sensore: "sensore",
  macchinario: "macchinario",
} as const;
