import { forwardRef } from "react";

// Custom styles for SoftTypography
import SoftTypographyRoot from "@/elements/Typography/SoftTypographyRoot";

import { TypographyProps as MuiTypographyProps } from "@mui/material/Typography";

interface TypographyProps
  extends Omit<
    MuiTypographyProps,
    "color" | "fontWeight" | "textTransform" | "verticalAlign"
  > {
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "text"
    | "white";
  fontWeight?: false | "light" | "regular" | "medium" | "bold";
  textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
  verticalAlign?:
    | "unset"
    | "baseline"
    | "sub"
    | "super"
    | "text-top"
    | "text-bottom"
    | "middle"
    | "top"
    | "bottom";
  textGradient?: boolean;
  opacity?: number;
  component?: any;
}

const Typography = forwardRef<any, TypographyProps>(
  (
    {
      color = "dark",
      fontWeight = false,
      textTransform = "none",
      verticalAlign = "unset",
      textGradient = false,
      opacity = 1,
      children,
      ...rest
    },
    ref
  ) => (
    <SoftTypographyRoot
      {...rest}
      ref={ref}
      //@ts-ignore
      ownerState={{
        color,
        textTransform,
        verticalAlign,
        fontWeight,
        opacity,
        textGradient,
      }}
    >
      {children}
    </SoftTypographyRoot>
  )
);

export default Typography;
