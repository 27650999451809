import colors from "@/theme/base/colors";
import { Box, IconButton, Modal, Backdrop, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export default function Image({
  src,
  alt,
  handleImageDelete,
  canDelete = true,
}: {
  src: string;
  alt: string;
  handleImageDelete?: any;
  canDelete?: boolean;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onImageClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Box
        className={"centrale-immagine"}
        sx={{
          position: "relative",
          width: "100%",
          aspectRatio: "1/1",
          border: `1px solid ${colors.inputColors.borderColor.main}`,
          borderRadius: "8px",
          lineHeight: "0px",
          "&:hover .close": { opacity: 1 },
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={onImageClick}
        />
        {canDelete && (
          <IconButton
            className="close"
            sx={{
              position: "absolute",
              padding: "0px",
              right: "-12px",
              top: "-12px",
              border: "1px solid red",
              backgroundColor: "white !important",
              transition: "all 0.12s linear",
              opacity: 0,
              "&:hover": { opacity: 1 },
            }}
            onClick={handleImageDelete}
          >
            <CloseIcon
              sx={{
                color: "red",
              }}
            />
          </IconButton>
        )}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#ffffff",
              boxShadow: 24,
              height: "80%",
              borderRadius: "8px",
              outline: "none",
            }}
          >
            <img
              src={src}
              alt={alt}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
