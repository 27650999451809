import Box from "@/elements/Box";
import brand from "@/assets/logo_big.png";
import Typography from "@/elements/Typography";
import { CircularProgress, CircularProgressProps } from "@mui/material";
import { brandName } from "../Sidenav";

export default function Loading({
  withBranding = false,
}: {
  withBranding?: boolean;
}) {
  const imgProps = {
    src: brand,
  } as any;
  return (
    <Box py={3} px={3} textAlign="center">
      {withBranding && (
        <Box
          component="img"
          {...imgProps}
          alt={brandName}
          //width="2rem"
          height={60}
          pb={2}
        />
      )}
      {!withBranding && <CircularProgress color="secondary" sx={{ mb: 2 }} />}
      <Typography variant="h6" color="text">
        Caricamento...
      </Typography>
    </Box>
  );
}

export function SmallLoading({ sx }: { sx?: CircularProgressProps["sx"] }) {
  return <CircularProgress color="secondary" size={18} sx={sx} />;
}
