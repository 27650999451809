import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  AziendaDto,
  CentraleCreationDto,
  CentraleDto,
  getGetCentraliQueryKey,
  RepartoDto,
  StabilimentoDto,
  useCreateCentrali,
} from "@/api";
import { Controller, useForm } from "react-hook-form";
import { FormHelperText, InputLabel } from "@mui/material";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import Box from "@/elements/Box";
import AziendaPicker from "@/components/AziendaPicker";
import StabilimentoPicker from "@/components/StabilimentoPicker";
import RepartoPicker from "@/components/RepartoPicker";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@/context/useAuth";
import UserPicker from "@/components/UserPicker";

function CentraleCreateDialog({
  open = false,
  onClose,
  azienda,
  stabilimento,
  reparto,
}: {
  open?: boolean;
  onClose?: (centrale: CentraleDto | null) => void;
  azienda?: AziendaDto;
  stabilimento?: StabilimentoDto;
  reparto?: RepartoDto;
}) {
  const handleClose = () => {
    onClose?.(null);
  };

  const { hasPermission } = useAuth();

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { errors, isValid },
  } = useForm<CentraleDto>({
    defaultValues: {
      nome: "",
      azienda,
      stabilimento,
      reparto,
    },
  });

  const { isLoading, error, mutateAsync } = useCreateCentrali({
    mutation: {
      onSuccess: () => {
        toast.success("Centrale creato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const onSubmit = async (data: CentraleCreationDto) => {
    try {
      const result = await mutateAsync({
        data: data,
      });
      await queryClient.invalidateQueries(getGetCentraliQueryKey());
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Crea centrale</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1} pt={2}>
          <Controller
            control={control}
            name="azienda"
            render={({ field: { value, onChange } }) => {
              return (
                <Box>
                  <InputLabel>Azienda</InputLabel>
                  <AziendaPicker
                    value={value}
                    onChange={(e, value) => {
                      onChange(value);
                      setValue("stabilimento", null);
                      setValue("reparto", null);
                    }}
                    placeholder="Azienda"
                    canCreateAzienda={false /*hasPermission("AziendaCreate")*/}
                  />
                </Box>
              );
            }}
          />

          <Controller
            control={control}
            name="stabilimento"
            render={({ field: { value, onChange } }) => (
              <Box>
                <InputLabel>Stabilimento</InputLabel>
                <StabilimentoPicker
                  aziendaId={watch("azienda")?.id}
                  value={value}
                  onChange={(e, value) => {
                    onChange(value);
                    setValue("reparto", null);
                  }}
                  placeholder="Stabilimento"
                  canCreateStabilimento={
                    false /*hasPermission("StabilimentoCreate")*/
                  }
                />
              </Box>
            )}
          />

          <Controller
            control={control}
            name="reparto"
            rules={{ required: "Inserisci il reparto" }}
            render={({ field: { value, onChange } }) => (
              <Box>
                <InputLabel>Reparto</InputLabel>
                <RepartoPicker
                  stabilimentoId={watch("stabilimento")?.id}
                  value={value}
                  onChange={(e, v) => {
                    onChange(v);
                    if (v) {
                      setValue("repartoId", v.id);
                    } else {
                      resetField("repartoId");
                    }
                  }}
                  placeholder="Reparto"
                  canCreateReparto={false /*hasPermission("RepartoCreate")*/}
                />
              </Box>
            )}
          />

          <Box>
            <InputLabel htmlFor="crea-centrale-nome">Nome centrale</InputLabel>
            <TextField
              id="crea-centrale-nome"
              autoFocus
              {...register("nome", {
                required: "Inserisci il nome dell'centrale",
                minLength: 1,
              })}
              error={!!errors.nome?.message}
              helperText={errors.nome?.message || null}
              fullWidth
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Crea centrale
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(CentraleCreateDialog);
