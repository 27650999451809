import { _permissions } from "./rolesAndPermissionsData/permissions";
import { _rolePermissionsMap } from "./rolesAndPermissionsData/rolePermissionsMap";

export type Permission = (typeof _permissions)[number];
export const permissions = _permissions as readonly Permission[];

export type Role = keyof typeof _rolePermissionsMap;
export const roles = Object.keys(_rolePermissionsMap) as readonly Role[];

export const ROLE_CLIENTE: Role = "Cliente";

export const rolePermissionsMap = _rolePermissionsMap as {
  [role in Role]: readonly Permission[];
};

export function getPermissionsForRole(roleName: Role): readonly Permission[] {
  const permissions = rolePermissionsMap[roleName];
  return permissions || [];
}

export function roleHasPermission(role: Role, permission: Permission): boolean {
  const permissions = getPermissionsForRole(role);
  return (
    permissions.some((p) => p === permission) ||
    permissions.some((p) => p === "AccessAll")
  );
}

export function rolesHavePermission(
  roles: Role[],
  permission: Permission
): boolean {
  for (const role of roles) {
    if (roleHasPermission(role, permission)) {
      return true;
    }
  }
  return false;
}

const _roleColorsMap: { [role in Role]: [string, string] } = {
  Admin: ["#007acc", "#FFFFFF"],
  Cliente: ["#4CAF50", "#000000"],
  Tecnico: ["#607D8B", "#FFFFFF"],
  Laboratorio: ["#607D8B", "#FFFFFF"],
  Commerciale: ["#607D8B", "#FFFFFF"],
  "Area Manager": ["#607D8B", "#FFFFFF"],
};

/**
 * Get the background and foreground colors associated with a role.
 *
 * @param {Role} role - The role for which to retrieve colors.
 * @returns {[string, string]} - A tuple containing the background and foreground colors.
 *                             - Index 0: Background color
 *                             - Index 1: Foreground (text) color
 */
export function getRoleColor(role: Role) {
  return _roleColorsMap[role] || ["#CCCCCC", "#000000"];
}
