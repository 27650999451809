import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  DispositivoDto,
  getGetDispositivoQueryKey,
  getGetManutenzioniQueryKey,
  ManutenzioneDto,
  useUpdateManutenzioni,
} from "@/api";
import { Controller, useForm } from "react-hook-form";
import { FormHelperText, InputLabel } from "@mui/material";
import { SmallLoading } from "../Loading";
import { useQueryClient } from "@tanstack/react-query";
import DescrizioneBonificaPicker from "@/components/DescrizioneBonificaPicker";
import FileField from "@/components/FileField";
import DescrizioneManutenzionePicker from "../DescrizioneManutenzionePicker";
import { toast } from "react-toastify";
import moment from "moment";
import { DateTimeField } from "@mui/x-date-pickers";

function ManutenzioneDialog({
  open = false,
  onClose,
  dispositivo,
  manutenzione,
}: {
  open?: boolean;
  onClose?: () => void;
  dispositivo: DispositivoDto;
  manutenzione?: Partial<ManutenzioneDto> | null;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    resetField,
    control,
  } = useForm<ManutenzioneDto>({
    defaultValues: manutenzione || {},
  });

  const { mutateAsync, isLoading } = useUpdateManutenzioni();

  const queryClient = useQueryClient();

  const onSubmit = async (data: ManutenzioneDto) => {
    try {
      await mutateAsync({
        id: dispositivo.id,
        data: [
          {
            id: manutenzione?.id,
            changeType: manutenzione?.id ? "updated" : "added",
            entity: data,
          },
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: getGetManutenzioniQueryKey(dispositivo.id),
      });
      toast.success("manutenzione aggiunta");
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {manutenzione?.id ? "Modifica manutenzione" : "Crea manutenzione"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-filtrazione-data">Data</InputLabel>
        <Controller
          name="data"
          control={control}
          rules={{ required: "Specifica la data" }}
          render={({ field, fieldState: { error } }) => {
            const { value, name, onBlur, onChange, ref } = field;
            const m =
              typeof value === "string"
                ? moment(value)
                : moment.isMoment(value)
                ? value
                : null;
            return (
              <>
                <DateTimeField
                  ref={ref}
                  format="DD/MM/YYYY"
                  name={name}
                  onBlur={onBlur}
                  onChange={(value) => onChange(value?.format("YYYY-MM-DD"))}
                  value={m}
                  fullWidth
                />
                {error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            );
          }}
        />

        <InputLabel htmlFor="crea-manutenzione-descrizione">
          Descrizione
        </InputLabel>
        <Controller
          name="descrizione"
          control={control}
          rules={{ required: "Seleziona una descrizione" }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <DescrizioneManutenzionePicker
                value={value}
                onChange={(e, v) => onChange(v)}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          )}
        />

        <InputLabel htmlFor="crea-manutenzione-rapportino">
          Rapportino
        </InputLabel>
        <Controller
          name="fileRapportino"
          control={control}
          rules={{ required: "Seleziona un file" }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <FileField
                value={value}
                onChange={(f) => {
                  onChange(f);
                  if (f?.id) {
                    setValue("fileRapportinoId", f?.id);
                  } else {
                    resetField("fileRapportinoId");
                  }
                }}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {manutenzione?.id ? "Salva manutenzione" : "Crea manutenzione"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(ManutenzioneDialog);
