import { DispositivoType } from "@/api";

const modelliMacchinario = [
  "DISOLEATORE GFLD 3000",
  "GFL - BANCO DI FLUSSAGGIO",
  "GFL - CENTRALE STOCCAGGIO OLIO",
  "GFL - CENTRALINA PER EMULSIONE",
  "GFL D 1000",
  "GFL D 3000",
  "GFL DF 12000",
  "GFL HC 400",
  "GFL HCF 100",
  "GFL HCF 200",
  "GFL HCF 300",
  "GFL HCFT 200",
  "GFL HCFT 400",
  "GFL HCFTA 200",
  "GFL TFL 500",
  "GFL TFL 700",
  "GFL TFLC 500",
  "GFL TFLD 500",
  "GFL TFLD 550",
  "GFL TFLE 500",
  "GFL TFLEA",
  "GFL TFLG 700",
  "GFL TFLP 500",
  "GFL TFLV 300",
  "GFL TH 2000",
  "GFL TH 4000",
  "GFL ZFM 100",
] as const;

const modelliSensori = [
  "GFL SENSOR PT",
  "GFL SENSOR WT",
  "GFL MONITORING WT",
  "GFL MONITORING WTP",
  "GFL PARTICLE_MONITORING",
] as const;

export const modelliDispositiviByType: {
  [tipo in DispositivoType]:
    | []
    | typeof modelliMacchinario
    | typeof modelliSensori;
} = {
  unknown: [],
  macchinario: modelliMacchinario,
  sensore: modelliSensori,
};

export const immaginiByModello: {
  [modello in
    | (typeof modelliMacchinario)[number]
    | (typeof modelliSensori)[number]]: string;
} = {
  // "GFL - CENTRALE STOCCAGGIO OLIO": "/immagini-dispositivi/1.png",
  // "GFL - BANCO DI FLUSSAGGIO": "/immagini-dispositivi/1.png",
  "GFL DF 12000": "/immagini-dispositivi/GFL DF 12000.png",
  "GFL TFLV 300": "/immagini-dispositivi/GFLTFLV 300.png",
  "GFL SENSOR WT": "/immagini-dispositivi/GFL SENSOR WT.png",
  "GFL TFL 700":
    "/immagini-dispositivi/GFLTFL 700_ GFLTFLG 700_GFLTFL 500_GFLTFLC500_ GFLTFLE 500_GFLTFLP 500.png",
  "GFL TFLG 700":
    "/immagini-dispositivi/GFLTFL 700_ GFLTFLG 700_GFLTFL 500_GFLTFLC500_ GFLTFLE 500_GFLTFLP 500.png",
  "GFL TFL 500":
    "/immagini-dispositivi/GFLTFL 700_ GFLTFLG 700_GFLTFL 500_GFLTFLC500_ GFLTFLE 500_GFLTFLP 500.png",
  "GFL TFLC 500":
    "/immagini-dispositivi/GFLTFL 700_ GFLTFLG 700_GFLTFL 500_GFLTFLC500_ GFLTFLE 500_GFLTFLP 500.png",
  "GFL TFLE 500":
    "/immagini-dispositivi/GFLTFL 700_ GFLTFLG 700_GFLTFL 500_GFLTFLC500_ GFLTFLE 500_GFLTFLP 500.png",
  "GFL TFLP 500":
    "/immagini-dispositivi/GFLTFL 700_ GFLTFLG 700_GFLTFL 500_GFLTFLC500_ GFLTFLE 500_GFLTFLP 500.png",
  "GFL D 3000": "/immagini-dispositivi/GFL D3000_GFL D1000.png",
  "GFL D 1000": "/immagini-dispositivi/GFL D3000_GFL D1000.png",
  "GFL TFLD 550": "/immagini-dispositivi/GFLTFLD 550_ GFL TFLD500.png",
  "GFL TFLD 500": "/immagini-dispositivi/GFLTFLD 550_ GFL TFLD500.png",
  "GFL TH 4000": "/immagini-dispositivi/GFLTH 4000_GFLTH2000.png",
  "GFL SENSOR PT": "/immagini-dispositivi/GFL SENSOR PT.png",
  "GFL MONITORING WT":
    "/immagini-dispositivi/GFL MONITORING WTP_ GFL MONITORING WT.png",
  "GFL MONITORING WTP":
    "/immagini-dispositivi/GFL MONITORING WTP_ GFL MONITORING WT.png",
  "GFL PARTICLE_MONITORING":
    "/immagini-dispositivi/GFL PARTICLE MONITORING.png",
  "GFL - BANCO DI FLUSSAGGIO":
    "/immagini-dispositivi/GFL BANCO DI FLUSSAGGIO.png",

  // senza immagine
  "GFL TFLEA": "/immagini-dispositivi/default.png",
  "DISOLEATORE GFLD 3000": "/immagini-dispositivi/default.png",
  "GFL - CENTRALE STOCCAGGIO OLIO": "/immagini-dispositivi/default.png",
  "GFL - CENTRALINA PER EMULSIONE": "/immagini-dispositivi/default.png",
  "GFL HC 400": "/immagini-dispositivi/default.png",
  "GFL HCF 100": "/immagini-dispositivi/default.png",
  "GFL HCF 200": "/immagini-dispositivi/default.png",
  "GFL HCF 300": "/immagini-dispositivi/default.png",
  "GFL HCFT 200": "/immagini-dispositivi/default.png",
  "GFL HCFT 400": "/immagini-dispositivi/default.png",
  "GFL HCFTA 200": "/immagini-dispositivi/default.png",
  "GFL TH 2000": "/immagini-dispositivi/default.png",
  "GFL ZFM 100": "/immagini-dispositivi/default.png",
};
