const formHelperText = {
  styleOverrides: {
    root: {
      // addServerErrorsFactory usa '\n' per andare a capo
      whiteSpace: "pre-line",
    },
  },
};

export default formHelperText;
