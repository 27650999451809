import { Control, Controller } from "react-hook-form";
import moment from "moment/moment";
import { DateTimeField } from "@mui/x-date-pickers";
import { FormHelperText } from "@mui/material";
import { useDbRelationsUpdateForm } from "@/utils/useDbRelationsUpdate";

export function buildDateTimeCell<T extends Record<string, any>>(
  control: Control<{ data: T[] }>,
  editing: boolean
) {
  const editingDataTimeCell = (
    _: string | null | undefined,
    name: ReturnType<
      ReturnType<typeof useDbRelationsUpdateForm<T>>["getFieldName"]
    >,
    required: boolean,
    format = "DD/MM/YYYY HH:mm",
    roundStartOfDay = false
  ) => {
    return (
      <Controller
        name={name as any}
        control={control}
        rules={{ required: required && "Inserisci la data" }}
        render={({ field, fieldState: { error } }) => {
          const { value, name, onBlur, onChange, ref } = field;
          const m =
            typeof value === "string"
              ? moment(value)
              : moment.isMoment(value)
              ? value
              : null;
          return (
            <>
              <DateTimeField
                ref={ref}
                format={format}
                name={name}
                onBlur={onBlur}
                onChange={(_value) => {
                  let value = _value;
                  if (value && roundStartOfDay) {
                    value = value?.startOf("day");
                  }
                  onChange(value?.toISOString());
                }}
                value={m}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          );
        }}
      />
    );
  };

  const readonlyDataTimeCell = (value: string | null | undefined) => {
    const m = value ? moment(value) : null;
    return m ? m.format("L LT") : "";
  };

  return editing ? editingDataTimeCell : readonlyDataTimeCell;
}
