export function isMutedForever(muted: string | null | undefined) {
  if (typeof muted === "string" && muted > "2990-") {
    return true;
  }
  return false;
}

export function getMutedForeverDate() {
  return "2999-01-01T10:00:00Z";
}
