import { FocusEventHandler, forwardRef, useState } from "react";
import { CentraleDto, useGetCentrali } from "@/api";
import CentraleCreateDialog from "src/components/CentraleDialog";
import { AsyncAutocomplete, FetchOptions } from "../../elements/Autocomplete";

const CentralePicker = forwardRef(
  (
    {
      repartoId,
      value,
      onChange,
      onBlur,
      name,
      canCreateCentrale = false,
      placeholder,
    }: {
      repartoId?: number;
      value?: CentraleDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: CentraleDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      canCreateCentrale?: boolean;
      placeholder?: string;
    },
    ref
  ) => {
    const disabled = !repartoId;

    const [createCentraleDialogOptions, setCreateCentraleDialogOptions] =
      useState<{ open: boolean; nome: string }>({ open: false, nome: "" });

    const onCreateCentraleClose = (centrale: CentraleDto | null) => {
      onChange?.(null, centrale);
      setCreateCentraleDialogOptions({ open: false, nome: "" });
    };

    function onNewOptionSelected(nome: string) {
      setCreateCentraleDialogOptions({ open: true, nome: nome });
    }

    const _useGetCentrali: FetchOptions<CentraleDto> = (options) =>
      useGetCentrali({ repartoId }, options);

    return (
      <>
        <AsyncAutocomplete<CentraleDto>
          ref={ref}
          onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          fetchOptions={_useGetCentrali}
          getOptionLabel={(s) => s.nome}
          canCreateNewOption={canCreateCentrale}
          value={value}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
        />
        {canCreateCentrale && repartoId && (
          <CentraleCreateDialog
            open={createCentraleDialogOptions.open}
            onClose={onCreateCentraleClose}
            initialData={{ nome: createCentraleDialogOptions.nome, repartoId }}
          />
        )}
      </>
    );
  }
);

export default CentralePicker;
