import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  FiltrazioneDto,
  CentraleDto,
  getGetCentraleQueryKey,
  useUpdateFiltrazioni,
} from "@/api";
import { Controller, useForm } from "react-hook-form";
import { FormHelperText, InputLabel } from "@mui/material";
import { SmallLoading } from "../Loading";
import { useQueryClient } from "@tanstack/react-query";
import { DateTimeField } from "@mui/x-date-pickers";
import moment from "moment/moment";
import FileField from "../FileField";

function FiltrazioneDialog({
  open = false,
  onClose,
  centrale,
  filtrazione,
}: {
  open?: boolean;
  onClose?: () => void;
  centrale: CentraleDto;
  filtrazione?: Partial<FiltrazioneDto> | null;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
    resetField,
  } = useForm<FiltrazioneDto>({
    defaultValues: filtrazione || {},
  });

  const { mutateAsync, isLoading } = useUpdateFiltrazioni();

  const queryClient = useQueryClient();

  const onSubmit = async (data: FiltrazioneDto) => {
    try {
      await mutateAsync({
        id: centrale.id,
        data: [
          {
            id: filtrazione?.id,
            changeType: filtrazione?.id ? "updated" : "added",
            entity: data,
          },
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {filtrazione?.id ? "Modifica filtrazione" : "Crea filtrazione"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-filtrazione-data">Data</InputLabel>
        <Controller
          name="data"
          control={control}
          rules={{ required: "Specifica la data" }}
          render={({ field, fieldState: { error } }) => {
            const { value, name, onBlur, onChange, ref } = field;
            const m =
              typeof value === "string"
                ? moment(value)
                : moment.isMoment(value)
                ? value
                : null;
            return (
              <>
                <DateTimeField
                  ref={ref}
                  format="DD/MM/YYYY"
                  name={name}
                  onBlur={onBlur}
                  onChange={(value) => onChange(value?.format("YYYY-MM-DD"))}
                  value={m}
                  fullWidth
                />
                {error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            );
          }}
        />

        <InputLabel>Rapportino</InputLabel>
        <Controller
          name="fileRapportino"
          control={control}
          rules={{ required: "Seleziona un file" }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <FileField
                value={value}
                onChange={(f) => {
                  onChange(f);
                  if (f?.id) {
                    setValue("fileRapportinoId", f?.id);
                  } else {
                    resetField("fileRapportinoId");
                  }
                }}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {filtrazione?.id ? "Salva filtrazione" : "Crea filtrazione"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(FiltrazioneDialog);
