import { UseFormSetError } from "react-hook-form";

export function addServerErrorsFactory<
  T extends import("react-hook-form").FieldValues
>(
  setError: UseFormSetError<T>,
  onError?: <
    TError extends import("axios").AxiosError<unknown, any> = any,
    TVariables = void,
    TContext = unknown
  >(
    error: TError,
    variables: TVariables,
    context: TContext | undefined
  ) => void
) {
  return (
    error: import("axios").AxiosError<unknown, any>,
    variables: { data: T },
    context: unknown
  ) => {
    const errors = (error.response?.data as any)?.errors || {};
    console.log("aaa", error, errors);
    if (errors) {
      for (const key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          const errorArray = errors[key] as string[] | undefined;
          const error = Array.isArray(errorArray) ? errorArray.join("\n") : "";
          if (error) {
            setError(key as any, { message: error });
          }
        }
      }
    }
    onError?.(error, variables, context);
  };
}
