// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _reminderTypeUtilsData = {
  "Analisi": {
    "label": "Scadenza analisi",
    "EntityName": "Analisi"
  },
  "Bonifica": {
    "label": "Bonifica",
    "EntityName": "Bonifica"
  },
  "Filtro": {
    "label": "Filtro da sostituire",
    "EntityName": "Filtro"
  }
} as const