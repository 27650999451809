import * as React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import Input from ".";
import { InputProps } from "@mui/material";
// import Input from "@mui/material/Input";

interface CustomProps {
  onChange: (event: { target: { name: string; value: number | null } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue ?? null,
            },
          });
        }}
        decimalSeparator=","
        thousandSeparator="."
        // valueIsNumericString
        // prefix="$"
      />
    );
  }
);

type NumberInputProps = InputProps & {
  numericInputProps?: NumericFormatProps;
};

export default React.forwardRef<any, NumberInputProps>(function NumberInput(
  props,
  ref
) {
  const { numericInputProps, ...otherProps } = props;
  return (
    <Input
      ref={ref}
      {...otherProps}
      inputComponent={NumericFormatCustom as any}
      inputProps={numericInputProps as any}
    />
  );
});
