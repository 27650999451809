// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _dispositivoTypeUtilsData = {
  "Macchinario": {
    "label": "Macchinario",
    "EntityName": "Macchinario"
  },
  "Unknown": {
    "label": "Sconosciuto",
    "EntityName": "Unknown"
  },
  "Sensore": {
    "label": "Sensore",
    "EntityName": "Sensore"
  }
} as const