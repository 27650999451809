import Box from "@/elements/Box";
import Typography from "@/elements/Typography";

export default function LoadingError({ error }: { error?: Error }) {
  return (
    <Box py={1} px={3} textAlign="center">
      <Typography variant="h6" color="text">
        Errore durante il caricamento
      </Typography>
      <pre>{error?.message}</pre>
    </Box>
  );
}
