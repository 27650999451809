// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _permissionsGroupMap = {
  Home: "Home",
  DispositiviRead: "Dispositivi",
  DispositiviReadAll: "Dispositivi",
  DispositiviUpdateTrasferimenti: "Dispositivi",
  DispositivoCreate: "Dispositivi",
  DispositivoDelete: "Dispositivi",
  DispositiviReadManutenzioni: "Manutenzioni",
  DispositiviUpdateManutenzioni: "Manutenzioni",
  DispositivoRegistriRead: "Dispositivi - Registri",
  DispositivoRegistriWrite: "Dispositivi - Registri",
  DispositiviDatiStoriciRead: "Dispositivi - Dati",
  DispositiviDatiStoriciReadAll: "Dispositivi - Dati",
  DispositiviAlertValuesEdit: "Dispositivi - Alerts",
  DispositiviAlertInternalUserOptionsEdit: "Dispositivi - Alerts",
  AziendeReadAll: "Aziende",
  AziendeRead: "Aziende",
  AziendaRead: "Aziende",
  AziendaCreate: "Aziende",
  AziendaEdit: "Aziende",
  AziendaDelete: "Aziende",
  StabilimentiReadAll: "Stabilimenti",
  StabilimentoCreate: "Stabilimenti",
  StabilimentoUpdate: "Stabilimenti",
  RepartiReadAll: "Reparti",
  RepartoCreate: "Reparti",
  CentraliRead: "Centrali",
  CentraliReadAll: "Centrali",
  CentraleCreate: "Centrali",
  CentraleUpdate: "Centrali",
  CentraleUpdateAlloggiamentiFiltro: "Centrali",
  CentraleUpdateStoricoFiltri: "Centrali",
  CentraleUpdateStoricoOlio: "Centrali",
  CentraleUpdateReminders: "Centrali",
  CentraleUpdateBonifica: "Centrali",
  CentraleUpdateAnalisi: "Centrali",
  CentraleUpdateFiltrazioni: "Centrali",
  CentraleUpdateImmagini: "Centrali",
  CentraleDelete: "Centrali",
  TrasferimentiChange: "Trasferimenti",
  UsersInterniRead: "Users",
  UsersEsterniRead: "Users",
  UsersInterniChange: "Users",
  UsersEsterniChange: "Users",
  ModelliFiltroChange: "Dizionari",
  ModelliOlioChange: "Dizionari",
  DescrizioniBonificaChange: "Dizionari",
  DescrizioniManutenzioneChange: "Dizionari",
  FileUpload: "File",
  AnalisiListaReadAll: "Analisi",
  AnalisiListaRead: "Analisi",
  FiltrazioniListaReadAll: "Filtrazioni",
  FiltrazioniListaRead: "Filtrazioni",
  BonificheListaReadAll: "Bonifiche",
  BonificheListaRead: "Bonifiche",
  CalendarioRead: "Calendario",
  AccessAll: "SuperAdmin",
} as const;
